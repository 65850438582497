/**
 * Rulex Project Manager
 *
 * --ProcessTabs
 * 
 * This file contains all the necessary to use the tabs for the processes.
 * - 4 tab component definitions (tab contents)
 * - 1 logArea component (used to represent the log view)
 * - 1 main-parent component that organize the contents of the tabs inside.
 * 
 * 
 * @summary Define the components used for the tabs.
 * @author Riccardo Poli, Lorenzo Biasotti
 *
 */

import { Button, Col, Divider, Input, List, Row, Space, Table, Tabs, Tag} from "antd";
import Highlighter from 'react-highlight-words';
import { RefObject, useContext, useEffect, useReducer, useRef, useState } from "react";
import cronstrue from 'cronstrue';
import $ from 'jquery';
import * as cronjsMatcher from "@datasert/cronjs-matcher";
import { AccountContext } from "contexts/AccountContext";
import { CheckCircleOutlined, ClockCircleOutlined, CloseCircleOutlined, DownloadOutlined, ExclamationCircleOutlined, SearchOutlined, SyncOutlined } from "@ant-design/icons";
import { compareDateTime, displayLoadingMsg, formatDateTime, saveAndZipByteArray } from "scripts/functions";
import RPMEnvManager from "classes/RPMEnvManager";
import { openLocalNotification } from "components/Notifications/Notification";

/**
 * Interface to define the props
 *
 * @interface IProps
 */
interface IProps {
    process:any; 
    tabsRef:RefObject<HTMLDivElement>;
    onUpdateTabs:any;
    onRefreshTabs:any;
}
interface IPropsChild {
    tabName:string;
    get: (field) => any;
    user:string;
    updateOnTabs:any;
    refreshOnTabs:any;
}
interface IPropsLogs {
    log:string;
    tableLoading:boolean;
}

// Tab 1
const Tab1 = (props:IPropsChild) => {

    const STATUS = {
        "":            {"color": "default",     "text": "IDLE",     "icon": false},
        "idle":        {"color": "default",     "text": "IDLE",     "icon": false},
        "loading":     {"color": "default",     "text": "LOADING",  "icon": <ClockCircleOutlined />},
        "pending":     {"color": "warning",     "text": "PENDING",  "icon": <ClockCircleOutlined />},
        "running":     {"color": "processing",  "text": "RUNNING",  "icon": <SyncOutlined spin />},
        "warning":     {"color": "warning",     "text": "WARNING",  "icon": <ExclamationCircleOutlined />},
        "finished":    {"color": "success",     "text": "FINISHED", "icon": <CheckCircleOutlined />},
        "error":       {"color": "error",       "text": "ERROR",    "icon": <CloseCircleOutlined />},
    }

    // Return
    return (
        <Row className="tab-body" justify="center" align="top">
            <Col span={24}>
                <Row className="mt-3 mb-2" justify="center" align="top">
                    <Col>
                        <h1>{props.tabName}</h1>
                    </Col>
                </Row>
                <Row hidden={props.get("process") !== ""} className="mt-6" justify="center" align="top" style={{color: "black", fontWeight: 500, fontSize: 20}}>Select a process</Row>
                <Row hidden={props.get("process") === ""} >
                    <Col span={24}>
                        <Row className="mt-5" justify="center" align="top">
                            <Col span={20} xl={11} >
                                <Row justify="start">
                                    <Col className="tab-inner-content">
                                        <p>Name: <span>{props.get("process")}</span></p>
                                    </Col>
                                </Row>  
                                <Row justify="start">
                                    <Col className="tab-inner-content">
                                        <p>Description: <span>{props.get("description")}</span></p>
                                    </Col>
                                </Row> 
                            </Col>
                            <Col span={20} xl={2}/>
                            <Col span={20} xl={7}>
                                <Row justify="start">
                                    <Col className="tab-inner-content">
                                        <p>Rulex Version: <span>{props.get("rulex_version")}</span></p>
                                    </Col>
                                </Row>
                                <Row justify="start">
                                    <Col className="tab-inner-content">
                                        <p>Project: <span>{props.get("project")}</span></p>
                                    </Col>
                                </Row>  
                                <Row justify="start">
                                    <Col className="tab-inner-content">
                                        <p>Owner: <span>{props.get("owner")}</span></p>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row className="mt-2" justify="center" align="top">
                            <Col span={20} xl={13}>
                                <Row justify="start">
                                    <Col className="tab-inner-content">
                                        <p>Deployed: <span>{props.get("deployed")}</span></p>
                                    </Col>
                                </Row>
                                <Row justify="start" hidden={props.get("deployed") === "no"}>
                                    <Col span={2}>
                                    </Col>
                                    <Col span={10}>
                                        <Row justify="start">
                                            <Col className="tab-inner-content">
                                                <p>On: <span style={{textDecoration: "underline"}}>{props.get("type")}</span></p>
                                            </Col>
                                        </Row>
                                        <Row justify="start">
                                            <Col className="tab-inner-content">
                                                <p>By: <span>{props.get("deployed_by")}</span></p>
                                            </Col>
                                        </Row>
                                        <Row justify="start">
                                            <Col className="tab-inner-content">
                                                <p>At: <span>{formatDateTime(props.get("deployed_time"))}</span></p>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col span={8}>
                                        <Row justify="start">
                                            <Col className="tab-inner-content">
                                                <p>Memory: <span>{props.get("memory") + " MB"}</span></p>
                                            </Col>
                                        </Row>
                                        <Row justify="start">
                                            <Col className="tab-inner-content">
                                                <p>Cpu: <span>{props.get("cpu")/1024 + " vCPU"}</span></p>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                            <Col span={20} xl={7} hidden={props.get("deployed") === "no"}>  
                                <Row justify="start">
                                    <Col className="tab-inner-content">
                                        <p><span></span></p>
                                    </Col>
                                </Row>   
                                <Row justify="start">
                                    <Col className="tab-inner-content">
                                        <p>Last execution: <span>{formatDateTime(props.get("last_execution"))}</span></p>
                                    </Col>
                                </Row>   
                                <Row justify="start">
                                    <Col className="tab-inner-content">
                                            <p> Status:
                                                <Tag style={{marginLeft: 20, padding: 5, fontSize: 15}} icon={STATUS[props.get("status")]["icon"]} color={STATUS[props.get("status")]["color"]}>{STATUS[props.get("status")]["text"]}</Tag>
                                            </p>
                                    </Col>
                                </Row>  
                                <Row justify="start">
                                    <Col className="tab-inner-content">
                                        <p>Scheduled: <span>{props.get("scheduled")}</span></p>
                                    </Col>
                                </Row>   
                                <Row justify="start" hidden={props.get("scheduled") === "no"}>
                                    <Col className="tab-inner-content">
                                        <p>Cron: <span>{props.get("schedule_expression")}</span></p>
                                    </Col>
                                </Row>   
                            </Col>
                            <Col span={20} xl={7} hidden={props.get("deployed") !== "no"}>
                            </Col>
                        </Row>
                    </Col>
                </Row>    
            </Col>
        </Row>
    );
}

// Log viewer
const LogsArea = (props:IPropsLogs) => {

    const [tableData, setTableData] = useState([]);
    const [searchText, setSearchText] = useState("");
    const [searchedColumns, setSearchedColumns] = useState("");
    //const [errorList, setErrorList] = useState([]);
    const searchInput = useRef(null);

    // On changed log
    useEffect(() => {
        if (props.log !== "") {
            setTable(props.log);
        }
    }, [props.log]);

    // Handle search on log table
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumns(dataIndex);
    };
    
    // Handle reset on log table filters
    const handleReset = clearFilters => {
        clearFilters();
        setSearchText("");
    };

    // set search bar
    const getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                ref={ searchInput }
                placeholder={`Search ${dataIndex}`}
                value={selectedKeys[0]}
                onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                <Button
                    type="primary"
                    onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    icon={<SearchOutlined />}
                    size="small"
                    style={{ width: 90 }}
                >
                    Search
                </Button>
                <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                    Reset
                </Button>
                <Button
                    type="link"
                    size="small"
                    onClick={() => {
                        confirm({ closeDropdown: false });
                        setSearchText(selectedKeys[0]);
                        setSearchedColumns(dataIndex);
                    }}
                >
                    Filter
                </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            formatDateTime(record[dataIndex])
                ? (formatDateTime(record[dataIndex].toString()).toLowerCase().includes(value.toLowerCase()))
                : '',
        onFilterDropdownVisibleChange: visible => {
            if (visible) {    
                setTimeout(() => searchInput.current.select());   
            }
        },
        render: text =>
            searchedColumns === dataIndex ? (
                <Highlighter
                highlightStyle={{ backgroundColor: '#b8d46e', padding: 0 }}
                searchWords={[searchText]}
                autoEscape
                textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    // Setup the columns
    const columns = [
        {
          "title": 'Timestamp',
          "dataIndex": 'exec',
          "width": '200px',
          ...getColumnSearchProps('exec'),
          render: (value:string) => formatDateTime(value),
          sorter: (a, b) => compareDateTime(a["exec"],b["exec"]),
        },
        {
          "title": 'Task category',
          "dataIndex": 'task_category',
          "width": '180px',
          ...getColumnSearchProps('task_category'),
          
        },
        {
          "title": 'Task name',
          "dataIndex": 'task_name',
          "width": '180px',
          ...getColumnSearchProps('task_name'),
        },
        {
          "title": 'Message',
          "dataIndex": 'message',
          ...getColumnSearchProps('message'),
        },
    ];
      
    // Setup the table data
    const setTable = (log:string) => {

        let logArray = JSON.parse(log);
        let data = [];

        logArray.forEach((line, index) => {
            let d = line["timestamp"].replace(" ","T") + "Z" //new Date(line["timestamp"] + "Z");
            let exeData = d //("0" + d.getDate()).slice(-2) + "-" + ("0"+(d.getMonth()+1)).slice(-2) + "-" + d.getFullYear() + " " + ("0" + d.getHours()).slice(-2) + ":" + ("0" + d.getMinutes()).slice(-2) + ":" + ("0" + d.getSeconds()).slice(-2) + "." + ("0" + d.getMilliseconds()).slice(-3);
            data.push({
                "key":            index,
                "exec":           exeData,
                "task_category":  line["task_category"],
                "task_name":      line["task_name"],
                "message":        line["message"],
            });
        });

        setTableData(data);

    }

    // Handle the changes of the table
    function onChange(pagination, filters, sorter, extra) {
        //console.log('params', pagination, filters, sorter, extra);
    }

    // define RowColor
    function rowColor(record, index) {

        let className = "";
    
        if((record["message"].includes("error description:") && record["message"].includes("None") === false)) {
            if (index % 2 === 0) {
                className = "table-row-error-light";
            }
            else {
                className = "table-row-error-dark";
            }
        }
        else if (index % 2 === 0) {
            className = "table-row-light";
        }
        else {
            className = "table-row-dark";
        }

        return className;
    }

    // Return
    return (
        <>
            <Row>
                <Col span={24} className="log-col">
                    <Table 
                        columns={columns} 
                        size={"small"} 
                        dataSource={tableData} 
                        onChange={onChange} 
                        showHeader={true}
                        scroll={{ y: 500, x: 950 }}
                        pagination={false}
                        loading={props.tableLoading} 
                        rowClassName={(record, index) => rowColor(record, index)}
                    />
                </Col>
            </Row>
        </>
    );

}

// Tab 2
const Tab2 = (props:IPropsChild) => {

    // Session
    const { getSession } = useContext(AccountContext);

    // State var
    let log = "";
    const [logDown, setLogDown] = useState("");
    const [logName, setLogName] = useState("");
    //const [disabled, setDisabled] = useState(false);
    const [selected, setSelected] = useState("");
    const [visibleLog, setVisibleLog] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [tableExecLoading, setTableExecLoading] = useState(false);
    const [tableLogLoading, setTableLogLoading] = useState(false);
    const [logArea, setLogArea] = useState("");
    const [noLogMessage, setNoLogMessage] = useState(false);
    const [noPermissionMessage, setNoPermissionMessage] = useState(false);
    const [executions, setExecutions] = useState({});

    // Define the status variables
    const STATUS = {
        undefined:     {"color": "default",     "text": "-",                   "icon": false},
        "warning":     {"color": "warning",     "text": "WARNING",             "icon": <ExclamationCircleOutlined />},
        "finished":    {"color": "success",     "text": "SUCCESS",             "icon": <CheckCircleOutlined />},
        "error":       {"color": "error",       "text": "ERROR",               "icon": <CloseCircleOutlined />},
        "no_log":      {"color": "error",       "text": "NO LOG FOUND",        "icon": <ExclamationCircleOutlined />},
    }

    // On data updated
    useEffect(() => {
        
    }, [tableData])   // eslint-disable-line  

    // On data updated
    useEffect(() => {
        setTable(); 
        setVisibleLog(false);
        setNoLogMessage(false);
        setNoPermissionMessage(false);
        setLogArea("");
        setLogName("");
        setSelected("");
    }, [executions])   // eslint-disable-line  

    // On update parent
    useEffect(() => {
        setTableExecLoading(true);
        //console.log("update tab 2");
        if (props.get("project") !== "" && props.get("process") !== "") {
            getSession().then((session: { accessToken: any; }) => {
                const token = session.accessToken.jwtToken;
                let processNameWithStage =  props.get("process")
                if (props.get("type") === "prod") {
                    processNameWithStage += "-prod"
                }
                getExecDataDB({"projectName": props.get("project"), "processName": processNameWithStage}, token);
            });
        }
    }, [props.updateOnTabs]);  // eslint-disable-line    

    // On refresh parent
    useEffect(() => {
        setTableExecLoading(true);
        if (props.get("status") === "finished" || props.get("status") === "error") {
            if (props.get("project") !== "" && props.get("process") !== "") {
                getSession().then((session: { accessToken: any; }) => {
                    const token = session.accessToken.jwtToken;
                    let processNameWithStage =  props.get("process")
                    if (props.get("type") === "prod") {
                        processNameWithStage += "-prod"
                    }
                    getExecDataDB({"projectName": props.get("project"), "processName": processNameWithStage}, token);
                });
            }
        }
        else {
            setTable(); 
            setVisibleLog(false);
            setNoLogMessage(false);
            setNoPermissionMessage(false);
            setLogArea("");
            setLogName("");
            setSelected("");
        }
    }, [props.refreshOnTabs]);  // eslint-disable-line    

    // On click button downloads
    const onClickButton = (exe:any, type:string) => {
        getSession().then((session: { accessToken: any; }) => {
            const token = session.accessToken.jwtToken;
            if(type === "log") {
                displayLoadingMsg("Downloading log file..");
                if(logArea === "" || exe !== logDown){
                    downloadLogs(token, exe).then(() => downloadLogFile(exe, log));
                }
                else{
                    downloadLogFile(exe, logArea);
                }
            }
            else if(type === "output") {
                displayLoadingMsg("Downloading outputs..");
                downloadOutputs(token, exe);
            }
        });
    }

    // download log file
    function downloadLogFile(filename, log) { 
        var text = "";

        let jsonLog = JSON.parse(log);

        jsonLog.forEach(line => {
            let d = line["timestamp"].replace(" ","T") + "Z" //new Date(line["timestamp"] + "Z");
            let exeDate = d //("0" + d.getDate()).slice(-2) + "-" + ("0"+(d.getMonth()+1)).slice(-2) + "-" + d.getFullYear() + " " + ("0" + d.getHours()).slice(-2) + ":" + ("0" + d.getMinutes()).slice(-2) + ":" + ("0" + d.getSeconds()).slice(-2) + "." + ("0" + d.getMilliseconds()).slice(-3);

            text += exeDate + "\t" + line["task_category"] + "\t" + line["task_name"] + "\t" + line["message"] + "\n";
        });
        
        let stringDate = filename.substring(1,20);
        let d = stringDate.replace(" ","T") + "Z" //new Date(stringDate + "Z");
        let fileDate = d //("0" + d.getDate()).slice(-2) + "-" + ("0"+(d.getMonth()+1)).slice(-2) + "-" + d.getFullYear() + " " + ("0" + d.getHours()).slice(-2) + ":" + ("0" + d.getMinutes()).slice(-2) + ":" + ("0" + d.getSeconds()).slice(-2);

        var element = document.createElement('a');
        element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
        element.setAttribute('download', filename.replace(stringDate, formatDateTime(fileDate)));
        element.style.display = 'none';document.body.appendChild(element);
        element.click();document.body.removeChild(element);
    }

    // Download outputs from s3
    const downloadOutputs = (token:string, exec:string) => {
          
        let authorization = 'Bearer ' + token;
        let api = RPMEnvManager.getApiLambda("downloadOutputs");

        // TODO: get executionId from db.
        let data = {
            "projectName":  props.get("project"),
            "processName":  props.get("process"),
            "executionId":  exec,
            "inProduction": props.get("type") !== "staging"
        };
        let dataToSend = JSON.stringify(data);

        $.ajax({
            
            url: api,
            type: "POST",
            headers: {
                'Authorization': authorization
            },
            contentType: 'application/json',
            data: dataToSend,
            processData: false,
            dataType: 'text',
            success: function(response1) {
                
                response1 = JSON.parse(response1)
                
                let fileArray = {}

                for (let key in response1) {
                    
                    let fileUrl = response1[key]

                    $.ajax({
                    
                        url: fileUrl,
                        type: "GET",
                        xhrFields: {
                            // make sure the response knows we're expecting a binary type in return.
                            // this is important, without it the excel file is marked corrupted.
                            responseType: 'arraybuffer'
                        },
                        beforeSend:function(xhr){
                            xhr['fileName']=key;
                        },
                        success: function(response2,status,xhr) {
                            // console.log("downloaded: ->  " + xhr['fileName'])
                            fileArray[xhr['fileName']] = response2
                            if(Object.keys(fileArray).length === Object.keys(response1).length) {
                                let zipFileName = "out_" + props.get("process");
                                saveAndZipByteArray(zipFileName, fileArray, false)
                            }
                        },
                        statusCode: {
                            401: function() {
                                //console.log("Invalid Token.");
                                openLocalNotification("error","download_project","401");
                            },
                            409: function() {
                                //console.log("Invalid Token.");
                                openLocalNotification("error","download_project","409")
                            },
                            500: function() {
                                //console.log(response.responseText);
                                openLocalNotification("error","download_project","500");
                            }
                        }
                    })
                }
            },
            statusCode: {
                401: function() {
                    openLocalNotification("error","download_outputs","401");
                },
                404: function( response ) {
                    //message.warning('This task has no output files');
                    openLocalNotification("error","download_outputs","409");
                },
                500: function( response ) {
                    //console.log(response.responseText)
                    openLocalNotification("error","download_outputs","500");
                },
            }
        });   
    };

     // Download logs from s3
    const downloadLogs = async (token:string, exec:string) => {
        return await new Promise((resolve, reject) => {  
            let authorization = 'Bearer ' + token;
            let api = RPMEnvManager.getApiLambda("downloadLogs");

            // TODO: get executionId from db.
            let data = {
                "projectName":  props.get("project"),
                "processName":  props.get("process"),
                "executionId":  exec,
                "inProduction": props.get("type") !== "staging"
            };
            let dataToSend = JSON.stringify(data);
            
            $.ajax({
                
                url: api,
                type: "POST",
                headers: {
                    'Authorization': authorization
                },
                contentType: 'application/json',
                data: dataToSend,
                processData: false,
                dataType: 'text',
                success: function(response) {
                    //console.log("download logs ok");
                    setTableLogLoading(false);
                    log = response.toString();
                    setLogArea(response.toString());
                    setLogDown(exec);
                    resolve(true);
                },
                statusCode: {
                    401: function() {
                        //console.log("Invalid Token.");
                        openLocalNotification("error","download_logs","401");
                        reject();
                    },
                    409: function() {
                        openLocalNotification("error","download_logs","409");
                        reject();
                    },
                    500: function( response ) {
                        //console.log(response.responseText);
                        openLocalNotification("error","download_logs","500");
                        reject();
                    }
                }
            });  
        });
    };

    // On select row
    const onRowSelected = (selectedRow) => {
        setSelected(selectedRow["key"]);
        if((props.get("owner") !== props.user && !props.get("collaborators").includes(props.user))){
            setLogName(formatDateTime(selectedRow["exec"]));
            setNoPermissionMessage(true);
            setNoLogMessage(false);
            setVisibleLog(false);
        }
        else if(selectedRow["exec_folder"].toString().split("]-")[1] !== "empty") {
            setTableLogLoading(true);
            getSession().then((session: { accessToken: any; }) => {
                const token = session.accessToken.jwtToken;
                setLogName(formatDateTime(selectedRow["exec"]));
                downloadLogs(token, selectedRow["exec_folder"]);
            });
            setNoLogMessage(false);
            setNoPermissionMessage(false);
            setVisibleLog(true);
        }
        else{
            setLogName(formatDateTime(selectedRow["exec"]));
            setNoLogMessage(true);
            setNoPermissionMessage(false);
            setVisibleLog(false);
        }
    };

    // Setup columns
    const columns = [
        {
          "title": 'Execution Time',
          "dataIndex": 'exec',
          "render": (value:string) => formatDateTime(value),
          "sorter": (a, b) => compareDateTime(a["exec"],b["exec"]),
          "defaultSortOrder": "descend" as "descend",
        },
        {
          "title": 'Result',
          "dataIndex": 'result',
          "align": 'center' as "center",
          "render": (result) => (
            <>
              < Tag icon={STATUS[result]["icon"]} color={STATUS[result]["color"]}>{STATUS[result]["text"]}</Tag>
            </>
          ),
        },
        {
          "title": 'Log',
          "dataIndex": 'log',
          "align": 'center' as "center",
          "render": (exe) => (
            <>
                <Button 
                    type="primary"
                    disabled={(props.get("owner") !== props.user && !props.get("collaborators").includes(props.user)) || (exe.split("]-")[1] === "empty" ? true : false)}
                    className="btn-primary-small" onClick={() => onClickButton(exe, "log")}
                    shape="round"
                    icon={<DownloadOutlined />} size={"middle"}>
                </Button>
            </>
          )
        },
        {
          title: 'Outputs',
          dataIndex: 'outputs',
          align: 'center' as "center",
          render: (exe) => (
            <>
                <Button
                    type="primary"
                    disabled={(props.get("owner") !== props.user && !props.get("collaborators").includes(props.user)) || (exe.split("]-")[1] === "empty" ? true : false) || !(executions === undefined || executions[exe] === undefined || executions[exe]["outputFound"])}
                    className="btn-primary-small"
                    onClick={() => onClickButton(exe, "output")}
                    shape="round"
                    icon={<DownloadOutlined />}
                    size={"middle"}>
                </Button>
            </>
          )
        },
      ];
      
    // Getting table data from db
    const getExecDataDB = (keys:{}, token:string) => {
        
        let authorization = 'Bearer ' + token;
        let api = RPMEnvManager.getApiLambda("getItems");
        
        $.ajax({
        url: api,
        type: "POST",
        headers: {
            'Authorization': authorization
        },
        contentType: 'application/json',
        data: JSON.stringify(keys),
        success: function( response ) {
                if( response !== "" || response !== "[]") {
                if (response.length !== 0) {
                    setExecutions(response[0]["executions"]);
                }
                else {
                    setExecutions({});
                }
            }  
        },
        statusCode: {
            401: function() {
                openLocalNotification("error","db_data","401",0);
            },
            500: function() {
                openLocalNotification("error","db_data","500",0);
            }
        }
        });
    } 

    // Setup table data
    const setTable = () => {

        let data = [];

        let i=0;

        //console.log(executions)
        for(let key in executions) {
            let d = key.toString().substring(1,20).replace(" ","T") + "Z" //new Date(key.toString().substring(1,20) + "Z");
            let exeDate = d //("0" + d.getDate()).slice(-2) + "-" + ("0"+(d.getMonth()+1)).slice(-2) + "-" + d.getFullYear() + " " + ("0" + d.getHours()).slice(-2) + ":" + ("0" + d.getMinutes()).slice(-2);
            
            let result = key.toString().split("]-")[1] === "empty" ? "no_log" : executions[key.toString()]["result"];
            data.push({
                "key":            i.toString(),
                "exec":           exeDate,
                "result":         result,
                "log":            key.toString(),
                "outputs":        key.toString(),
                "exec_folder":    key.toString(),
            });

            i++;
        }

        setTableData(data);
        setTableExecLoading(false);
    }

    // On change table data
    function onChange(pagination, filters, sorter, extra) {
        //console.log('params', pagination, filters, sorter, extra);
    }

    // Return
    return (
        <Row className="tab-body-ul" justify="center" align="top">
            <Col span={24}>
                <Row className="mt-3 mb-2" justify="center" align="top">
                    <Col>
                        <h1>{props.tabName}</h1>
                    </Col>
                </Row>
                <Row hidden={props.get("process") !== ""} className="mt-6" justify="center" align="top" style={{color: "black", fontWeight: 500, fontSize: 20}}>Select a process</Row>
                <Row hidden={props.get("process") === "" || tableData.length < 1} justify="center" align="top">
                    <Col span={22}>
                        <Row className="mt-5" justify="center" align="top">
                            <Col span={22} className="exec-col">
                                <Table 
                                    columns={columns} 
                                    size={"middle"} 
                                    rowClassName={(record, index) => selected === record["key"] ? "table-row-selected" : "table-row-light"}
                                    dataSource={tableData} 
                                    pagination={{ pageSize: 4 }}
                                    loading={tableExecLoading}
                                    onChange={onChange} 
                                    rowSelection={{
                                        type: "radio",
                                        selectedRowKeys: [selected],
                                        onSelect: (row) => onRowSelected(row),
                                      }} 
                                    onRow={(record) => {
                                        return {
                                          onClick: () => {onRowSelected(record)},              // click row
                                          onDoubleClick: () => {},                             // double click row
                                          onContextMenu: () => {},                             // right button click row
                                          onMouseEnter: () => {},                              // mouse enter row
                                          onMouseLeave: () => {},                              // mouse leave row
                                        }
                                      }}
                                />
                            </Col>
                        </Row>
                        <Divider>{(visibleLog === true || noLogMessage || noPermissionMessage) ? logName : "Select an execution to view the log"}</Divider>
                        <Row className="mt-5 mb-5" hidden={!noLogMessage} justify="center" align="top" style={{fontStyle: "italic", fontSize: "18px"}}>No Log Found</Row>
                        <Row className="mt-5 mb-5" hidden={!noPermissionMessage} justify="center" align="top" style={{fontStyle: "italic", fontSize: "18px"}}>You do not have permission to view the logs</Row>
                        <Row className="mt-3 mb-5" hidden={!visibleLog} justify="center" align="top" style={{border: "2px rgb(232 232 232) solid"}}>
                            <Col span={24}>
                                <LogsArea log={logArea} tableLoading={tableLogLoading}></LogsArea>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row hidden={props.get("process") === "" || tableData.length > 0} justify="center" align="top">
                    <Col span={22}>
                        <Row className="mt-5" justify="center" align="top" >
                            <Col span={20} style={{textAlign: "center"}}>
                                <p style={{color: "black", fontWeight: 500}}>This process has no executions</p>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
}

// Tab 3
const Tab3 = (props:IPropsChild) => {

    // Clear cron
    const clearCron = (cron:string) : any => {

        if(cron !== "" && cron !== undefined && cron !== null) {
            let value = cron.split("(")[1].split(")")[0];
            return (value !== "" ? value : false);
        }
        else {
            return false;
        }    
    }

    // Ger meaning of cron
    const getMeaning = (cron:string) : string => {

        let value = clearCron(cron);

        if(value !== false ) {
            return (cronstrue.toString(value));
        }
        else {
            return "";
        }            
    }

    // get Schedule of cron
    const getSchedule = (cron:string, local:boolean) : string[] => {

        let value = clearCron(cron);

        if(value !== false) {

            let dates = cronjsMatcher.getFutureMatches(value, {matchCount: 5});
            let datesUTC = [];
            let d : any;
            let dateString = "";
            let dateStringUTC = "";
            
            for(let i = 0; i < dates.length; i++){
                d =  new Date(dates[i]);
                dateString = ("0" + d.getDate()).slice(-2) + "-" + ("0"+(d.getMonth()+1)).slice(-2) + "-" + d.getFullYear() + " " + ("0" + d.getHours()).slice(-2) + ":" + ("0" + d.getMinutes()).slice(-2);
                dates[i] = dateString;
                dateStringUTC = ("0" + d.getUTCDate()).slice(-2) + "-" + ("0"+(d.getUTCMonth()+1)).slice(-2) + "-" + d.getUTCFullYear() + " " + ("0" + d.getUTCHours()).slice(-2) + ":" + ("0" + d.getUTCMinutes()).slice(-2);
                datesUTC.push(dateStringUTC);
            }

            for(let i = dates.length; i < 5; i++){
                dates.push("---");
                datesUTC.push("---");
            }

            return (local === true ? dates : datesUTC); 
        }
        else {
            return [];
        }
    }

    // Return
    return (
        <Row className="tab-body tab-body-ul" justify="center" align="top">
            <Col span={24}>
                <Row className="mt-3 mb-2" justify="center" align="top">
                    <Col>
                        <h1>{props.tabName}</h1>
                    </Col>
                </Row>
                <Row hidden={props.get("process") !== ""} className="mt-6" justify="center" align="top" style={{color: "black", fontWeight: 500, fontSize: 20}}>Select a process</Row>
                <Row hidden={props.get("process") === ""} >
                    <Col span={24}>
                        <Row className="mt-4" justify="center" align="top" hidden={getMeaning(props.get("schedule_expression")) === ""}>
                            <Col span={20} style={{textAlign: "center"}}>
                                <p style={{color: "black", fontWeight: 500}}>{props.get("schedule_expression")}</p>
                                <p style={{color: "black", fontWeight: 400, fontStyle: "italic"}}>{getMeaning(props.get("schedule_expression"))}</p>
                            </Col>
                        </Row>
                        <Row className="mt-4 mb-4" justify="center" align="top" hidden={getMeaning(props.get("schedule_expression")) === ""}>
                            <Col span={10} style={{textAlign: "center"}}>
                                <List
                                size="small"
                                header={<div style={{color: "black", fontWeight: 500}}>Next Runs: (UTC)</div>}
                                bordered
                                dataSource={getSchedule(props.get("schedule_expression"), false)}
                                renderItem={item => <List.Item>{item}</List.Item>}
                                />
                            </Col>
                            <Col span={1} style={{textAlign: "center"}}>
                            </Col>
                            <Col span={10} style={{textAlign: "center"}}>
                                <List
                                size="small"
                                style={{textAlign: "center"}}
                                header={<div style={{color: "black", fontWeight: 500}}>Next Runs: (LOCAL TIME)</div>}
                                bordered
                                dataSource={getSchedule(props.get("schedule_expression"), true)}
                                renderItem={item => <List.Item>{item}</List.Item>}
                                />
                            </Col>
                        </Row>
                        <Row className="mt-5" justify="center" align="top" hidden={getMeaning(props.get("schedule_expression")) !== ""}>
                            <Col span={20} style={{textAlign: "center"}}>
                                <p style={{color: "black", fontWeight: 500}}>This process has no schedules</p>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
}

// Tab 4
const Tab4 = (props:IPropsChild) => {

    // Return
    return (
        <Row className="tab-body" justify="center" align="top">
            <Col span={24}>
                <Row className="mt-3 mb-2" justify="center" align="top">
                    <Col>
                        <h1>{props.tabName}</h1>
                    </Col>
                </Row>
                <Row hidden={props.get("process") !== ""} className="mt-6" justify="center" align="top" style={{color: "black", fontWeight: 500, fontSize: 20}}>Select a process</Row>
                <Row hidden={props.get("process") === ""} >
                    <Col span={24}>
                        <Row className="mt-5" justify="center" align="top">
                            <Col>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
}


/**
 * Process Tabs
 * 
 * @param props
 * @returns 
 */
const ProcessTabs = (props:IProps) => {  

    const { TabPane } = Tabs;
    const TAB_LIST = ["Process", "Executions", "Schedule", "Stats"];

    const [activeTab, setActiveTab] = useState("0"); //eslint-disable-line
    const [updateOnTabs, updateTabs] = useReducer(x => x + 1, 0);   //eslint-disable-line
    const [refreshOnTabs, refreshTabs] = useReducer(x => x + 1, 0);   //eslint-disable-line

    const [user, setUser] = useState("");
    const { getSession } = useContext(AccountContext);  

    // On process changed
    useEffect(() => {
        getSession().then((session: { idToken: any;}) => {
            setUser(session.idToken.payload.email);
        });
    }, [props.process]);    // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(()=> {
        updateTabs();
    }, [props.onUpdateTabs])

    useEffect(()=> {
        refreshTabs();
    }, [props.onRefreshTabs])

    // get value from data
    const getData = (field:string) :any => {

        if(props.process === null || props.process === undefined) {
            return "";
        }
        else {
            return props.process[field];
        }
    } 

    // Return
    return (
        <>
        <div ref={props.tabsRef} className="card-container tab-process tab-rpm">
            <Tabs type="card" onChange={(key) => setActiveTab(key)}>
                <TabPane tab={TAB_LIST[0]} key="0">
                    <Tab1 updateOnTabs={updateOnTabs} refreshOnTabs={refreshOnTabs} get={getData} user={user} tabName={TAB_LIST[0]}></Tab1>  
                </TabPane>
                <TabPane tab={TAB_LIST[1]} key="1">
                    <Tab2 updateOnTabs={updateOnTabs} refreshOnTabs={refreshOnTabs} get={getData} user={user} tabName={TAB_LIST[1]}></Tab2>  
                </TabPane>
                <TabPane tab={TAB_LIST[2]} key="2">
                    <Tab3 updateOnTabs={updateOnTabs} refreshOnTabs={refreshOnTabs} get={getData} user={user} tabName={TAB_LIST[2]}></Tab3>  
                </TabPane>
                <TabPane disabled tab={TAB_LIST[3]} key="3">
                    <Tab4 updateOnTabs={updateOnTabs} refreshOnTabs={refreshOnTabs} get={getData} user={user} tabName={TAB_LIST[3]}></Tab4>  
                </TabPane>
            </Tabs> 
        </div> 
        </>
    );

}

export default ProcessTabs;