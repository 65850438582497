/**
 * Rulex Project Manager
 *
 * RPMSpecsReader
 * 
 * @summary Contains the class RPMSpecsReader to read and parse task config specifications.
 * @author Riccardo Poli, Lorenzo Biasotti
 *
 */

import data from '../files/aws-ecs-specs.json'

/**
 * Class to read specs from file and create structures.
 * 
 * @class RPMSpecsReader
 */
class RPMSpecsReader {
 
  private cpu: Array<number>;
  private mem: Array<number>;
  private couples: {}[];

  constructor() {
    
    this.cpu = this.readSpecFormat(data.cpu);
    this.mem = this.readSpecFormat(data.mem);
    this.couples = data.couples;
  }

  // Read spec format to get values of the ranges
  private readSpecFormat (dataArray:any) : Array<number> {

    let result = new Array<number>();

    dataArray.forEach(element => {
      
      if(typeof element === 'number') {
        result.push(element);
      }  
      else if(typeof element === 'string') {
        var el = element as string;
        var kel = el.split(":");
        
        for(let i=Number(kel[0]); i<=Number(kel[2]); i=i+Number(kel[1])) {
          result.push(Number(i));
        }
      }
    });

    return result;
  }

  // Get cpu values
  getCpuValues():Array<number> {

    return this.cpu;
  }

  // Get mem values
  getMemValues():Array<number> {

    return this.mem;
  }

  // Get cpu -> mem associations
  getCpuMap() : Map<number,Array<number>> {
    
    let cpuMap = new Map<number, Array<number>>();
    
    this.couples.forEach(element => {   
      
      let values = new Array<number>();
      
      this.mem.forEach(memV => {

        if(memV <= Number(element["max_mem"]) && memV >= Number(element["min_mem"])) {
          values.push(memV);
        }
      });
      
      cpuMap.set(element["cpu"], values);
    });
    
    return cpuMap;
  }

    // Get mem -> cpu associations
  getMemMap() : Map<number,Array<number>> {

    let memMap = new Map<number, Array<number>>();

    this.mem.forEach(memV => {
      
      let values = new Array<number>();

      this.couples.forEach(element => {

        if(memV <= Number(element["max_mem"]) && memV >= Number(element["min_mem"])) {
          values.push(element["cpu"]);
        }
      });

      memMap.set(memV, values);
    });

    return memMap;
  }

}
 
export default RPMSpecsReader;