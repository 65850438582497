/**
 * Rulex Project Manager
 *
 * --functions
 * 
 * @summary This file contains various scripts/functions used inside the app.
 * @author Riccardo Poli, Lorenzo Biasotti
 *
 */

import { message } from "antd";
import RPMEnvManager from "classes/RPMEnvManager";
import { openLocalNotification } from "components/Notifications/Notification";
import JSZip from "jszip";
import $ from "jquery"


/**
 * Sleep Function
 *
 * @param {number} ms   (time in ms)
 * @return {*} 
 */
const sleep = (ms:number) => {
    return new Promise(resolve => setTimeout(resolve, ms));
}

/**
 * base64 to array buffer
 *
 * @param {*} base64    (file in base64 format)
 * @return {*} bytesArray of the file
 */
const base64ToArrayBuffer = (base64) => {
    var binaryString = window.atob(base64);
    var binaryLen = binaryString.length;
    var bytes = new Uint8Array(binaryLen);
    for (var i = 0; i < binaryLen; i++) {
       var ascii = binaryString.charCodeAt(i);
       bytes[i] = ascii;
    }
    return bytes;
}

/**
 * create and download file
 *
 * @param {*} reportName    (name to give to the file)
 * @param {*} byte          (ArrayBuffer file)
 */
const saveByteArray = (reportName, byte) => {
    var blob = new Blob([byte], {type: "application/zip"});
    var link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    var fileName = reportName;
    link.download = fileName;
    link.click();
};

/**
 * create, zip, and download multiple files
 *
 * @param {*} reportName    (name to give to the zip-file)
 * @param {*} byteArray     (array of files)
 * @param {*} fileStructure (Object with project structure)
 */
 const saveAndZipByteArray = (reportName, byteArray, fileStructure) => {
    // TODO: leggi cartelle del progetto da file-structure.json
    
    var zip = new JSZip();
    
    if (fileStructure === false) {

        let folder = zip.folder(reportName);

        for (let filename in byteArray) {

            let newFile = new Blob([byteArray[filename]], {type: "application/octet-stream"});
            folder.file(filename, newFile);
        } 
    }
    else {

        let stage = undefined;

        for (let filename in byteArray) {

            let nameOfFile = filename;

            if (nameOfFile.includes("||prod")) {
                stage = zip.folder("production")
                nameOfFile = nameOfFile.replaceAll('||prod','');
            }
            else {
                stage = zip.folder("staging")
            }

            let folder = stage.folder("modules");
            let newFile = new Blob([byteArray[nameOfFile]], {type: "application/octet-stream"});
            
            if (fileStructure["processFiles"].includes(nameOfFile)) {
                stage.file(nameOfFile, newFile);
            }
            else {
                folder.file(nameOfFile, newFile);
            }
        } 
    }

    zip.generateAsync({type:"blob"})
    .then(function(content) {
        // see FileSaver.js
        var link = document.createElement('a');
        link.href = window.URL.createObjectURL(content);
        var fileName = reportName;
        link.download = fileName;
        link.click();
    });
    
};

/**
 * display loading msg
 *
 * @param {string} text text of the msg
 */
const displayLoadingMsg = (text:string) => {
    const hide = message.loading(text, 0);
    // Dismiss manually and asynchronously
    setTimeout(hide, 3000);
}


/**
 * download project folder
 *
 * @param {*} authorization
 * @param {string} projectName
 * @param {*} processFiles
 * @param {*} subProcessFiles
 * @param {*} inputFiles
 */
const downloadProjectFolder = async (authorization:any, projectName:string, processFiles:any, subProcessFiles:any, inputFiles:any) => {

    return await new Promise((resolve, reject) => {
        
        let api = RPMEnvManager.getApiLambda("downloadProject");
        
        let data = {"projectName": projectName, "processFiles": processFiles, "subProcessFiles": subProcessFiles, "inputFiles": inputFiles};
        let dataToSend = JSON.stringify(data);
        displayLoadingMsg("Downloading project " + projectName + " ..");
        $.ajax({
            url: api,
            type: "POST",
            headers: {
                'Authorization': authorization
            },
            contentType: 'application/json',
            data: dataToSend,
            processData: false,
            dataType: 'text',
            success: function(response1) {
                //console.log("download ok");
                response1 = JSON.parse(response1)
                
                let fileArray = {}
                
                for (let key in response1) {
                    
                    let fileUrl = response1[key]
                    
                    $.ajax({
                    
                        url: fileUrl,
                        type: "GET",
                        xhrFields: {
                            // make sure the response knows we're expecting a binary type in return.
                            // this is important, without it the excel file is marked corrupted.
                            responseType: 'arraybuffer'
                        },
                        beforeSend:function(xhr){
                            xhr['fileName']=key;
                        },
                        success: function(response2,status,xhr) {
                            // console.log("downloaded: ->  " + xhr['fileName'])
                            fileArray[xhr['fileName']] = response2
                            
                            if(Object.keys(fileArray).length === Object.keys(response1).length) {
                                saveAndZipByteArray(data["projectName"], fileArray, data)
                                resolve(true);
                            }
                        },
                        statusCode: {
                            401: function() {
                                //console.log("Invalid Token.");
                                openLocalNotification("error","download_project","401");
                            },
                            409: function() {
                                //console.log("Invalid Token.");
                                openLocalNotification("error","download_project","409")
                            },
                            500: function() {
                                //console.log(response.responseText);
                                openLocalNotification("error","download_project","500");
                            }
                        }
                    })
                }
            },
            statusCode: {
                401: function() {
                    //console.log("Invalid Token.");
                    openLocalNotification("error","download_project","401");
                },
                409: function() {
                    //console.log("Invalid Token.");
                    openLocalNotification("error","download_project","409")
                },
                500: function() {
                    //console.log(response.responseText);
                    openLocalNotification("error","download_project","500");
                }
            }
        });
    });
}

function isValidDate(d) {
    return !isNaN(d) && d instanceof Date;
}

/**
 * format datetime
 *
 * @param {string} stringValue str of the datetime or text
 */
const formatDateTime = (stringValue: string) :any => {

    // if(datetimeString === "--") {
    //     return datetimeString;
    // }
    let datetime = new Date(stringValue);
    if (isValidDate(datetime) === false) {
        return stringValue;
    }
    return datetime.toLocaleString(undefined, { 
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "numeric",
        minute: "numeric",
        second: "numeric"
    });
};

/**
 * compare 2 datetime
 *
 * @param {string} datetimeNameA str of the datetime
 * @param {string} datetimeNameB str of the datetime
 */
const compareDateTime = (datetimeStringA:any, datetimeStringB:any) :number => {
    if(datetimeStringA === "--") {
        return -1;
    }
    if(datetimeStringB === "--") {
        return 1;
    }
    let datetimeA = new Date(datetimeStringA);
    let datetimeB = new Date(datetimeStringB);
    return (datetimeA.getTime() - datetimeB.getTime()) 
}

export {sleep, base64ToArrayBuffer, saveByteArray, saveAndZipByteArray, displayLoadingMsg, downloadProjectFolder, formatDateTime, compareDateTime};