/**
 * Rulex Project Manager
 *
 * --index
 * 
 * @summary Main page of the react enviroment (index of all the pages)
 * @author Creative Tim (www.creative-tim.com)
 * @edited_by Riccardo Poli, Lorenzo Biasotti
 *
 */

import ReactDOM from "react-dom";
import { HashRouter, Route, Switch, Redirect/*, BrowserRouter*/ } from "react-router-dom";

import { WebSocketComponent } from "./contexts/WebSocketContext";
import { EventComponent } from "./contexts/EventContext";
import { Account } from "./contexts/AccountContext";

import "assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/css/argon-dashboard-react.css";
import "./styles/style-spacing.css";
import "./styles/style-elements.css";
import "./styles/my-style.css";

import AdminLayout from "layouts/Admin";
//import AuthLayout from "layouts/Auth";
import { SettingsComponent } from "contexts/SettingsContext";

ReactDOM.render(
  <HashRouter>
    <Switch>
      <Route path="/admin" render={(props) => <Account><SettingsComponent><EventComponent><WebSocketComponent>
                                                <AdminLayout {...props} />
                                              </WebSocketComponent></EventComponent></SettingsComponent></Account>}/>
      {/*<Route path="/auth" render={(props) => <Account><AuthLayout {...props} /></Account>} />*/}
      <Redirect from="/" to="/admin/index" />
      {/*<Redirect from="/" to={{ pathname: '/admin/index', search: {UserPage} }} />*/}
      {/*<Redirect from="/" to={`/admin/index/&${window.location.href.split('http://localhost:3000/#')[1]}`} />*/}
    </Switch>
  </HashRouter>,
  document.getElementById("root")
);

 