/**
 * Rulex Project Manager
 *
 * --RPMFile
 * 
 * @summary Contains the class RPMFile to manage project files
 * @author Riccardo Poli, Lorenzo Biasotti
 *
 */

import { RPMFileType } from "types/RPMFileType";
import FILE_STRUCTURE from '../files/file-structure.json'

/**
 * Class to manage rulex files.
 * Show informations about the files (name, size, ext, type, path).
 * Follow FILE_STRUCTURE rules to be responsive to changes.
 * 
 * @constructor @param file: the file to consider
 * @class RPMFile
 */
class RPMFile {

    private file:any;
    private fromDirectory:boolean;

    private name:string;
    private size:number;
    private ext:string;
    private type:RPMFileType;
    private path:string;

    constructor(file:any) {

        if(file.originFileObj === undefined || file.originFileObj === null) {
            this.file = file;
        }
        else {
            this.file = file.originFileObj as any;
        }
       
        this.name = this.file.name;

        var { webkitRelativePath = "" } = this.file;

        if(webkitRelativePath === "") {
            this.fromDirectory = false;
            webkitRelativePath = this.name;
        }
        else {
            this.fromDirectory = true;
        }

        this.size = this.file.size;
        this.ext = this.name.split('.').pop();
        this.type = RPMFileType.UNDEFINED;
        this.path = webkitRelativePath;
    }

    /**
     * Check the type of the file
     *
     * @private
     * @memberof RPMFile
     */
    private checkType = () => {
         

        // Process
        const processPath = FILE_STRUCTURE.processes.path;
        const processExt  = FILE_STRUCTURE.processes.ext;
        // Modules
        const modulePath = FILE_STRUCTURE.modules.path;
        const moduleExt  = FILE_STRUCTURE.modules.ext;
        // Input Files
        const inputFilesPath = FILE_STRUCTURE.input_files.path;
        const inputFilesExt  = FILE_STRUCTURE.input_files.ext;

        if(this.fromDirectory) {

            // File path components
            var pathComponents = this.path.split('/');
            pathComponents[0] = "";

            // Properties
            var hasProcessExt = (this.checkExt(processExt)) as boolean;
            var hasModuleExt = (this.checkExt(moduleExt)) as boolean;
            var hasInputFileExt = (this.checkExt(inputFilesExt)) as boolean;
            var hasProcessSize = (pathComponents.length === processPath.split("/").length) as boolean;
            var hasModuleSize = (pathComponents.length === modulePath.split("/").length) as boolean;
            var hasInputFileSize = (pathComponents.length === inputFilesPath.split("/").length) as boolean;
            var isProcess:boolean;
            var isModule:boolean;
            var isInputFile:boolean;

            if(hasProcessSize && hasProcessExt) {
                isProcess = true;
                for(let i=0; i<pathComponents.length -1; i++) {
                    if (pathComponents[i] !== processPath.split("/")[i]) {
                        isProcess = false;
                        break;
                    }
                }
            }
            else if(hasModuleSize && hasModuleExt) {
                isModule = true;
                for(let i=0; i<pathComponents.length -1; i++) {
                    if (pathComponents[i] !== modulePath.split("/")[i]) {
                        isModule = false;
                        break;
                    }
                }
            }
            else if(hasInputFileSize && hasInputFileExt) {
                isInputFile = true;
                for(let i=0; i<pathComponents.length -1; i++) {
                    if (pathComponents[i] !== inputFilesPath.split("/")[i]) {
                        isInputFile = false;
                        break;
                    }
                }
            }

            // Check if the file is a process
            if (isProcess) {
                this.type = RPMFileType.PROCESS;
            }
            else if (isModule) {
                this.type = RPMFileType.MODULE;
            }
            else if (isInputFile) {
                this.type = RPMFileType.INPUT_FILE;
            }
        }
        else {
            
            if(this.checkExt(inputFilesExt)) {
                this.type = RPMFileType.INPUT_FILE;
            }
        }
    }


    /**
     * @returns the file
     *
     * @memberof RPMFile
     */
     getFile = ():RPMFileType => {
        return this.file;
    }

    /**
     * @returns the name of the file
     *
     * @memberof RPMFile
     */
    getName = ():string => {
        return this.name;
    }

    /**
     * @returns the size of the file
     *
     * @memberof RPMFile
     */
     getSize = ():number => {
        return this.size;
    }

    /**
     * @returns the ext of the file
     *
     * @memberof RPMFile
     */
     getExtension = ():string => {
        return this.ext;
    }

    /**
     * @returns the type of the file
     *
     * @memberof RPMFile
     */
    getType = ():RPMFileType => {

        if(this.type === RPMFileType.UNDEFINED) {
            this.checkType();
        }

        return this.type;
    }

    /**
     * Check file size
     *
     * @memberof RPMFile
     */
    checkSize = () :boolean => {
        
        const MAX_FILE_SIZE = 500000000;

        if(this.size > MAX_FILE_SIZE) {
            return false;
        }
        else { 
            return true; 
        }
    }

    /**
     * Check file ext
     *
     * @param {*} extList
     * @memberof RPMFile
     */
     checkExt = (extList:any) :boolean => {
        
        if(extList.includes(this.ext)) {
            return true;
        }
        else { 
            return false; 
        }
    }

    /**
     * Check if empty file
     *
     * @param {*} files
     * @memberof RPMFile
     */
     checkIfEmptySize = () :boolean => {
        
        if(this.size === 0) {
            return true;
        }
        else { 
            return false; 
        }
    }
}
 
export default RPMFile;