/**
 * Rulex Project Manager
 *
 * --Admin
 * 
 * @summary Contains the definition of the admin pages (with session confirmed) (project page, process page,...)
 * @author Creative Tim (www.creative-tim.com)
 * @edited_by Riccardo Poli, Lorenzo Biasotti
 *
 */

import React, { useContext, useEffect, useState } from "react";
import { useLocation, Route, Switch, Redirect } from "react-router-dom";
import { Container } from "reactstrap";
import AdminNavbar from "components/Navbars/AdminNavbar";
import AdminFooter from "components/Footers/AdminFooter";
import Sidebar from "components/Sidebars/Sidebar";
import {Notification} from "components/Notifications/Notification";
import routes from "variables/routes";
import LoadingScreen from "components/Loader/LoadingScreen";
import { AccountContext } from "contexts/AccountContext";

const Admin = (props) => {


  const mainContent = React.useRef<HTMLDivElement>(null);
  const location = useLocation();

  const { getSession } = useContext(AccountContext); 
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getSession().then(() => {
      setLoading(false);
    });
  }, [loading]); //eslint-disable-line

  // On changed screen location
  React.useEffect(() => {
     
    if (document.scrollingElement != null && mainContent.current != null) {

        document.documentElement.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;
        mainContent.current.scrollTop = 0;
    }
    
  }, [location]);

  // Get routes
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/admin") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  // Get text
  const getBrandText = (path) => {
    for (let i = 0; i < routes.length; i++) {
      if (
        props.location.pathname.indexOf(routes[i].layout + routes[i].path) !==
        -1
      ) {
        return routes[i].title;
      }
    }
    return "Brand";
  };

  // Get text
  const getBrandPath = (path) => {
    for (let i = 0; i < routes.length; i++) {
      if (
        props.location.pathname.indexOf(routes[i].layout + routes[i].path) !==
        -1
      ) {
        return routes[i].path;
      }
    }
    return "Brand";
  };

  // Return
  return (
    <>
     {loading === false ? (
      <><Sidebar
          {...props}
          routes={routes}
          logo={{
            innerLink: "/admin/index",
            imgSrc: require("../assets/img/brand/argon-react.png").default,
            imgAlt: "...",
          }} /><div className="main-content" ref={mainContent}>
            <AdminNavbar
              {...props}
              brandText={getBrandText(props.location.pathname)}
              brandPath={getBrandPath(props.location.pathname)} />
            <Switch>
              {getRoutes(routes)}
              <Redirect from="*" to="/admin/index" />
            </Switch>
            <Notification>
            </Notification>
            <Container fluid>
              <AdminFooter />
            </Container>
          </div></>
    ) : (
      <LoadingScreen/>
    )}
    </>
  );
};

export default Admin;

/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/