/**
 * Rulex Project Manager
 *
 * RPMMsgParser
 * 
 * @summary Contains the class RPMMsgParser to read and parse websocket msgs.
 * @author Riccardo Poli, Lorenzo Biasotti
 *
 */

/** 
 * RPMMsgParser
 * Read and parse websocket msgs
 * 
 * @param msgType -> the type of the msg to be tracked. 
 * 
*/
class RPMMsgParser {

    // Function to get MsgData from the msg (parse)
    public getMsgData(msg:string){};

    // Constructor
    constructor (msgsType:msgsType) {

        switch(msgsType) {

            // Assign the right procedure to the msgsType
            case "deploy_process":
                this.getMsgData = this.getMsgDeployProcess;
                break;  
            case "edit_process":
                this.getMsgData = this.getMsgEditProcess;
                break;
            case "clone_process":
                this.getMsgData = this.getMsgCloneProcess;
                break;
            case "run_task":
                this.getMsgData = this.getMsgRunTask;
                break;
        }
    }

    // Get msg (Deploy Process)
    private getMsgDeployProcess = (message:string):{} => {

        const msg_source = message.split("|")[3].split("::")[2];
        const msg_completed = message.includes("CREATE_COMPLETE");
        const msg_error = message.includes("CREATE_FAILED");
        const msg_finish = message.includes("DEPLOY COMPLETED");
        const process_name = message.split("|")[2];

        if(msg_completed) {

            switch (msg_source) {

            case "LogGroup":
                return ({"index":3, "progress": 1, "status": true, "process": process_name, "ignore": false});
            case "Role": 
                return ({"index":4, "progress": 1, "status": true, "process": process_name, "ignore": false});
            case "TaskDefinition":
                return ({"index":5, "progress": 1, "status": true, "process": process_name, "ignore": false});
            case "Rule":
                return ({"index":6, "progress": 1, "status": true, "process": process_name, "ignore": false});
            default:
                return ({"ignore": true});
            }
        }
        else if(msg_finish) {

            return ({"index": 7, "progress": 0, "status": true, "process": process_name, "ignore": false})
        }
        else if(msg_error) {

            return ({"index": -1, "progress": 0, "status": false, "process": process_name, "ignore": false});
        }
        else {
            return ({"ignore": true});
        }
    }

    // Get msg (Edit Process)
    private getMsgEditProcess = (message:string):{} => {

        const msg_source = message.split("|")[3].split("::")[2];
        const msg_updating = message.includes("UPDATE_IN_PROGRESS");
        const msg_updated = message.includes("UPDATE_COMPLETE");
        //const msg_deleting = message.includes("DELETE_IN_PROGRESS");
        //const msg_deleted = message.includes("DELETE_COMPLETE");
        const msg_error = message.includes("CREATE_FAILED");
        const msg_finish = message.includes("EDIT COMPLETED");
        const process_name = message.split("|")[2];
    
        if(msg_updating) {
            return({"msg": msg_source, "finished": false, "process": process_name, "ignore": false});
        }
        else if(msg_updated && msg_source === "TaskDefinition") {
            return({"msg": msg_source, "finished": false, "process": process_name, "ignore": false});
        }
        else if(msg_finish) {
            return({"msg": "success", "finished": true, "process": process_name, "ignore": false});
        }
        else if(msg_error) {
            return({"msg": "error", "finished": true, "process": process_name, "ignore": false});
        }
        else {
            return ({"msg": msg_source, "finished": false, "process": process_name, "ignore": false});
        }
    }

    // Get msg (Clone Process)
    private getMsgCloneProcess = (message:string):{} => {

        //const msg_source = message.split("|")[3].split("::")[2];
        //const msg_completed = message.includes("CREATE_COMPLETE");
        const msg_error = message.includes("CREATE_FAILED");
        const msg_finish = message.includes("DEPLOY COMPLETED");
        const process_name = message.split("|")[2];

        if(msg_finish) {
            return ({"index": 7, "progress": 0, "status": true, "process": process_name, "ignore": false})
        }
        else if(msg_error) {

            return ({"index": -1, "progress": 0, "status": false, "process": process_name, "ignore": false});
        }
        else {
            return ({"ignore": true});
        }

    }

    // Get msg (Run Task)
    private getMsgRunTask = (message:string):{} => {

        let [msgType, project, process, type, status, executionDateTime] = message.split("|"); // eslint-disable-line
        return({"project": project, "process": process, "type": type, "status": status, "executionDateTime": executionDateTime, "ignore": false});
    }

}

export default RPMMsgParser;