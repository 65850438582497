/**
 * Rulex Project Manager
 *
 * --SettingsContext
 * 
 * @summary Context used to manage Client app Settings
 * @author Riccardo Poli, Lorenzo Biasotti
 *
 */

import React, { useState } from "react";

/**
 * Context default values 
 * @type {*} 
 * */
const contextDefaultValues: SettingsContextState = {
  getSettings: () => {},
  addUserProcess(processList: []){},
  saveNewTableView(view: string){}
};

/*
*   THIS CONTEXT MANAGE SETTINGS VAR.
*   
*/

// defining the context 
const SettingsContext = React.createContext<SettingsContextState>(contextDefaultValues);

// This context provider is passed to any component requiring the context
const SettingsComponent = (props) => {

  // State var
  const [userProcess, setUserProcess] = useState([]);
  const [tableView, setTableView] = useState("ONLY_USER_PROJECTS");

  const addUserProcess = (processList: []) => {
    setUserProcess(processList);
  }

  const saveNewTableView = (view: tableViewKeys) => {
    setTableView(view);
  }

  const getSettings = () : {} => {
    let settings = {
      "user_process": userProcess,
      "table_view": tableView,
    }
    return settings
  }

  return (
    <SettingsContext.Provider
      value={{ getSettings, addUserProcess, saveNewTableView }}
    >
      {props.children}
    </SettingsContext.Provider>
  );

};

export { SettingsComponent, SettingsContext };