/**
 * Rulex Project Manager
 *
 * Header
 * 
 * @summary Contains the header of the admin pages.
 * @author Creative Tim (www.creative-tim.com)
 * @edited_by Riccardo Poli, Lorenzo Biasotti
 *
 */

/**
 * Header of the Admin pages
 *
 * @return {*} 
 */
const Header = () => {
  return (
    <>
      <div className="header bg-gradient-info pb-4 pt-5 pt-md-5">
      </div>
    </>
  );
};

export default Header;

/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/