/**
 * Rulex Project Manager
 *
 * --ProjectTable
 * 
 * 
 * @summary Define the component for the project table.
 * @author Riccardo Poli, Lorenzo Biasotti
 *
 */

import { Button, Checkbox, Col, Input, List, Modal, Radio, Row, Space, Spin, Table} from "antd";
import { useContext, useEffect, useReducer, useRef, useState } from "react";
import { Card, CardFooter, CardHeader, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown} from "reactstrap"
import Highlighter from 'react-highlight-words';
import { Link } from "react-router-dom";
import $ from "jquery"
import {AccountContext} from "../../contexts/AccountContext"
import { EventContext } from "contexts/EventContext";
import { ArrowsAltOutlined, CloseOutlined, LoadingOutlined, ReloadOutlined, SearchOutlined, ShrinkOutlined } from "@ant-design/icons/lib/icons";
import "../../styles/my-style.css";
import { RPMEventType } from "types/RPMEventType";
import NewProjectWizard from "components/Wizards/ProjectWizard";
import { compareDateTime, displayLoadingMsg, downloadProjectFolder, formatDateTime, sleep } from "scripts/functions";
import RPMEnvManager from "classes/RPMEnvManager";
import { SettingsContext } from "contexts/SettingsContext";
import {openLocalNotification} from "../Notifications/Notification";

/**
 * Interface to define the form props
 *
 * @interface IProps
 */
interface IProps {
  selectedProject:any
  setSelectedProject(sProject:any):void;
  focusTabs():void;
  updateTabs():void;
  refreshTabs():void;
}

/**
 * Project Table
 * 
 * @param props
 * @returns 
 */
const ProjectTable = (props:IProps) => {  
  
  // Columns
  const FIELDS = ["project", "description", "rulex_version", "owner", "last_update"];
  // Finite filters on columns
  const FILTERS = {};

  // State Vars
  const [dataSource, setDataSource] = useState([]);  
  const [dataBackup, setDataBackup] = useState([]);    // eslint-disable-line
  const [columns, setColumns] = useState([]);  
  const [expandedContent, setExpandedContent] = useState({});
  const [searchText, setSearchText] = useState("");
  const [searchedColumns, setSearchedColumns] = useState("");
  const [selectedRow, setSelectedRow] = useState(["-1"]); //put here default highlighted.
  const [tableLoading, setTableLoading] = useState(true);
  const [editData, setEditData] = useState({});
  const [visibleNewProjectWizard, setVisibleNewProjectWizard] = useState(false);
  const [isModalDeleteProjectVisible, setIsModalDeleteProjectVisible] = useState(false);
  const [isModalUnlockProjectVisible, setIsModalUnlockProjectVisible] = useState(false);
  const [user, setUser] = useState();
  const [checkedDownloadProject, setCheckedDownloadProject] = useState(false);
  const [closable, setClosable] = useState(true);
  const [tempRecord, setTempRecord] = useState();
  const [loadingButton, setLoadingButton] = useState(false);
  // Updater
  const [ignored, forceUpdate] = useReducer(x => x + 1, 0);   //eslint-disable-line
  // Ref
  const searchInput = useRef(null);
  // Context
  const { getSession } = useContext(AccountContext);   //eslint-disable-line
  const { addUserProcess, saveNewTableView, getSettings } = useContext(SettingsContext);
  const { onEvent, getEvent, newEvent } = useContext(EventContext);

  //Spin
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  let loading = false;
  let loadingProject = "";
  let selectedTableView = getSettings()['table_view'];

  useEffect(() => {
    //openLocalNotification();
  },[])

  // Effect on data set
  useEffect(() => {
    setTableLoading(false);
    setLoadingButton(false); 
  }, [dataSource]); //eslint-disable-line

  // Effect on data set
  useEffect(() => {
    updateSelectedRow();
    getSession().then((session: { idToken: any; }) => {
      let email = session.idToken.payload.email;
      let processOfUser = []
      dataSource.forEach(row => {
        if(row["owner"] === email || row["collaborators"].includes(email)) {
          let prcs = row["process_list"];
          for(let prc in prcs) {
            processOfUser.push(row["project"] + "||" + prc);
          }
        }
      });
      addUserProcess(processOfUser);
    });
  }, [dataBackup]); //eslint-disable-line

  // Effect on event
  useEffect(() => {
    
    if(onEvent !== RPMEventType.IDLE) {
      if( getEvent() === RPMEventType.CLOSE_PROJECT_WIZARD            || 
          getEvent() === RPMEventType.CLOSE_PROCESS_WIZARD            ||
          getEvent() === RPMEventType.END_BUILD_PROJECT               ||
          getEvent() === RPMEventType.END_EDIT_PROJECT                ||
          getEvent() === RPMEventType.END_DEPLOY_PROCESS              ||
          getEvent() === RPMEventType.END_EDIT_PROCESS                ||
          getEvent() === RPMEventType.END_CLONE_PRODUCTION            ||
          getEvent() === RPMEventType.END_FAST_CLONE_PRODUCTION       ||
          getEvent() === RPMEventType.END_DELETE_PROJECT              ||
          getEvent() >= 400) {
        updateTableData();
        setTableLoading(true);
      }
    }
  }, [onEvent]); // eslint-disable-line

  // Effect on the first render
  useEffect(() => {
    if(user === undefined || user === null){
      setCurrUser();
    }
    else{
    setTableLoading(true);
    getColumns();
    getExpandedContent();
    updateTableData();
    }
  }, [user]); // eslint-disable-line react-hooks/exhaustive-deps


  const setCurrUser = () => {
    getSession().then((session: { idToken: any; accessToken: any;}) => {
      setUser(session.idToken.payload.email);
    });
  }

  const updateTableData = () => {
    setTableLoading(true);
    getSession().then((session: { idToken: any; accessToken: any;}) => {
      getTableDataDB(session.accessToken.jwtToken);
    });
  }

  // Event on change table
  function onChangeTable(pagination, filters, sorter, extra) {
    //console.log('params', pagination, filters, sorter, extra);
  }

  // On select row
  const onRowSelected = (selectedRowData, move) => {
    setSelectedRow([selectedRowData.key]);
    props.setSelectedProject(selectedRowData);
    if(move) {
      props.focusTabs();
    }
    props.updateTabs();
  };

  // Update child tabs
  const updateSelectedRow = () => {
    props.setSelectedProject(dataBackup[Number(selectedRow)]);
    props.refreshTabs();
  }

  // Setup of the columns of the table
  const getColumns = () => {
    
    let col = [];

    FIELDS.forEach(field => {

      if (field === "owner" && selectedTableView === "1") {
        col.push({

          "title":            field.replace("_", " "),
          "dataIndex":        field,
          "key":              ("key" + FIELDS.indexOf(field)),
        });
      }
      else if(field in FILTERS) { // with filter tool

        col.push({

          "title":            field.replace("_", " "),
          "dataIndex":        field,
          "key":              ("key" + FIELDS.indexOf(field)),
          "sorter": {
            compare:        (a, b) => a[field].localeCompare(b[field]),
          },
          "filters": [
                        {
                          text: FILTERS[field][0],
                          value: FILTERS[field][0],
                        },
                        {
                          text: FILTERS[field][1],
                          value: FILTERS[field][1],
                        }
                      ],
          "onFilter":  (value, record) => record[field].indexOf(value) === 0
        });
      }
      else {  // with search tool
        col.push({

          "title":            field.replace("_", " "),
          "dataIndex":        field,
          "key":              ("key" + FIELDS.indexOf(field)),
          "sorter": {
              compare:        (a, b) => a[field].localeCompare(b[field]),
          },
          ...getColumnSearchProps(field),
        });
      }

      if(field === "project") {
        col[col.length-1]["fixed"] = "left";
      }

      if(field === "last_update" || field === "rulex_version") {
        col[col.length-1]["align"] = "center";
      }

      if(field === "last_update") {
        col[col.length-1]["render"] = (value:string) => formatDateTime(value);
        col[col.length-1]["sorter"] = (a, b) => compareDateTime(a[field], b[field]);
      }

    });

    // Add menu columns
    col.push({

      "title":          '',
      "key":            'action',
      "width":          '60px',
      "render":         (text, record) => (
                          <UncontrolledDropdown disabled={record.owner !== user && !(record.collaborators.includes(user))}> 
                            <DropdownToggle
                              className="btn-icon-only text-black"
                              role="button"
                              size="sm"
                              color=""
                              onClick={(e) => e.preventDefault()}
                              hidden={loading && record.project === loadingProject}
                            >
                              <i className="fas fa-ellipsis-v" />
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-arrow drop-menu" right>
                              <DropdownItem
                                onClick={
                                  () => clickEditProject(record)
                                }
                              >
                                Edit
                              </DropdownItem>
                              <DropdownItem
                                onClick={
                                  () => showModalConfirmDeleteProject(record)
                                }
                              >
                                Delete
                              </DropdownItem>
                            </DropdownMenu>
                            <Spin spinning={loading && record.project === loadingProject} indicator={antIcon} />
                          </UncontrolledDropdown>
                        )
    });
    
    setColumns(col); 
  }

  // handle click on expanded item
  const handleClickOnExpandedItem = () => {
    //console.log("click")
  }
  
  // Setup of the columns of the table
  const getExpandedContent = () => {

    let content = {
      expandedRowRender:  record => {

        var text = [];

        for (var process in record["process_list"]) {
          text.push(process);
        }
        
        return (
          <>
            <List
            style={{width: "100%"}}
            size="large"
            bordered
            dataSource={text}
            renderItem={item => <Link to={{pathname: "/admin/processes", query: {"skip": false, "active": true, "project": record['project'], "process": item}}}><List.Item className="ant-list-item-1" onClick={handleClickOnExpandedItem}>{item}</List.Item></Link>}
            />
          </>);
      },
      expandIcon: ({ expanded, onExpand, record }) =>
      expanded ? (
        <ShrinkOutlined style={{ fontSize: '25px', color: "#0F4A6A"}} onClick={e => onExpand(record, e)} />
      ) : (
        <ArrowsAltOutlined style={{ fontSize: '25px', color: "#0F4A6A"}} onClick={e => onExpand(record, e)} /> 
      )
      // rowExpandable:   record => record.name !== 'Not Expandable',    set as private project ?
    };

    setExpandedContent(content); 
  }

  // Getting table data from db
  const getTableDataDB = (token: string) => {
    
    let authorization = 'Bearer ' + token;
    let api = RPMEnvManager.getApiLambda("getItems")

    $.ajax({
      url: api,
      type: "POST",
      headers: {
          'Authorization': authorization
      },
      contentType: 'text/plan',
      data: selectedTableView,
      success: function( response ) {
        if( response !== "" || response !== "[]") {
          let data = [];
          let key = 0;
          for (let i = 0; i < response.length; i++) {
            let iData = response[i];
            if(Object.keys(iData).length < 14)
              continue
            let lastUpdate = "--";
            if(iData.projectLastUpdate !== undefined){
              let d = iData.projectLastUpdate.replace(" ","T") + 'Z' //new Date(iData.projectLastUpdate + 'Z');
              lastUpdate = d //("0" + d.getDate()).slice(-2) + "-" + ("0"+(d.getMonth()+1)).slice(-2) + "-" + d.getFullYear() + " " + ("0" + d.getHours()).slice(-2) + ":" + ("0" + d.getMinutes()).slice(-2);
            }

            let creationDate = "--";
            if(iData.projectCreationDate !== undefined){
              let d = iData.projectCreationDate.replace(" ","T") + 'Z' //new Date(iData.projectCreationDate + 'Z');
              creationDate = d //("0" + d.getDate()).slice(-2) + "-" + ("0"+(d.getMonth()+1)).slice(-2) + "-" + d.getFullYear() + " " + ("0" + d.getHours()).slice(-2) + ":" + ("0" + d.getMinutes()).slice(-2);
            }

            data.push({
              "key":                  (key++).toString(),
              "project":              iData.projectName,
              "description":          iData.projectDescription,
              "rulex_version":        iData.projectRulexVersion,
              "owner":                iData.projectOwner,
              "collaborators":        iData.projectCollaborators,
              "last_update":          lastUpdate,
              "last_updated_by":      iData.projectLastUpdateBy,
              "creation":             creationDate,
              "process_list":         iData.processList,
              "process_files":        iData.processFileNames,
              "sub_process_files":    iData.subProcessFileNames,
              "input_files":          iData.inputsFileNames
            });
          }
          setDataSource(data); 
          setDataBackup(data);
          forceUpdate();
        }
      },
      statusCode: {
        401: function() {
          openLocalNotification("error","db_data","401",0);
        },
        500: function() {
          openLocalNotification("error","db_data","500",0);
        }
      }
    });
  } 

  // Event on click run task
  const deleteProject = (record: any) => {

    getSession().then((session: { accessToken: any; }) => {
      let authorization = 'Bearer ' + session.accessToken.jwtToken;
      let api = RPMEnvManager.getApiLambda("deleteProject");
      
      let data = {"projectName": record.project};
      let dataToSend = JSON.stringify(data);

      displayLoadingMsg("Deleting project " + record.project + " ..");

      $.ajax({
        url: api,
        type: "POST",
        headers: {
            'Authorization': authorization
        },
        contentType: false,
        data: dataToSend,
        processData: false,
        success: function( response ) {
            //console.log("Delete ok");
            newEvent(RPMEventType.END_DELETE_PROJECT);
            openLocalNotification("success","delete_project");
        },
        statusCode: {
            401: function() {
              //console.log("Invalid Token.");
              newEvent(RPMEventType.UNAUTH_DELETE);
              openLocalNotification("error","delete_project","401");
            },
            409: function() {
              newEvent(RPMEventType.CONCURRENCY_DELETE);
              openLocalNotification("error","delete_project","409");
            },
            500: function(response) {
              //console.log(response.responseText);
              newEvent(RPMEventType.ERROR_DELETE);
              openLocalNotification("error","delete_project","500");
            }
        }
      });
    });
  }

  const setLoading = (status:boolean, projectName:string) => {
    loading = status;
    loadingProject = projectName;
    getColumns();
  }

  // Event on click edit process
  const clickEditProject = (record: any) => {
    setLoading(true, record["project"]);
    checkConcurrencyBeforeUpdate(record);
  }

  // edit project
  const editProject = (lock: {}, record: any) => {

    if(lock["onLock"] === false) {
      record["edit_mode"] = true;
      setEditData(record);
      setVisibleNewProjectWizard(true);
    }
    else if(lock["onLock"]) {

      const deltaMin = 30;

      let lt = lock["lockTime"];

      let currentDate = new Date();
      let lockTime = new Date(lt + 'z');

      const diffTime = Math.abs(currentDate.valueOf() - lockTime.valueOf()).toString().slice(0,-3);
      const diffTimeMin = Math.floor(parseInt(diffTime) / 60);

      if(diffTimeMin <= deltaMin) {
        openLocalNotification("error","edit_project","409");
      }
      else {
        // Show modal
        setTempRecord(record);
        setIsModalUnlockProjectVisible(true);
      }
    }
  }

  // Download project 
  const downloadProject = async (record: any) => {

    return await new Promise((resolve, reject) => {

      getSession().then((session: { accessToken: any; }) => {
          
          let authorization = 'Bearer ' + session.accessToken.jwtToken;
          downloadProjectFolder(authorization, record.project, record.process_files, record.sub_process_files, record.input_files).then(() => resolve(true))
      });
    });
  };

  // Check lock on project
  const checkConcurrencyBeforeUpdate = (record:any) => {
    
    getSession().then((session: { accessToken: any; }) => {
      let authorization = 'Bearer ' + session.accessToken.jwtToken;
      let api = RPMEnvManager.getApiLambda("getConcurrencyLock");
      
      let data = {"projectName": record["project"]};
      let dataToSend = JSON.stringify(data);

      $.ajax({
        url: api,
        type: "POST",
        headers: {
            'Authorization': authorization
        },
        contentType: 'application/json',
        data: dataToSend,
        processData: false,
        dataType: 'text',
        success: function( response ) {
            let lock = JSON.parse(response);
            editProject(lock, record);
            setLoading(false, record["project"]);
        },
        statusCode: {
          401: function() {
            //console.log("Invalid Token.");
            openLocalNotification("error","db_data","401");
            setLoading(false, record["project"]);
          },
          500: function() {
            openLocalNotification("error","db_data","500");
            setLoading(false, record["project"]);
          }
        }
      });
    });
  };

  // Setup search bar for columns
  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
            <Input
            ref={ searchInput }
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ marginBottom: 8, display: 'block' }}
            />
            <Space>
            <Button
                type="primary"
                onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                icon={<SearchOutlined />}
                size="small"
                style={{ width: 90 }}
            >
                Search
            </Button>
            <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                Reset
            </Button>
            <Button
                type="link"
                size="small"
                onClick={() => {
                    confirm({ closeDropdown: false });
                    setSearchText(selectedKeys[0]);
                    setSearchedColumns(dataIndex);
                }}
            >
                Filter
            </Button>
            </Space>
        </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
            formatDateTime(record[dataIndex])
                ? (formatDateTime(record[dataIndex].toString()).toLowerCase().includes(value.toLowerCase()))
                : '',
    onFilterDropdownVisibleChange: visible => {
        if (visible) {    
            setTimeout(() => searchInput.current.select());   
        }
    },
    render: text =>
        searchedColumns === dataIndex ? (
            <Highlighter
            highlightStyle={{ backgroundColor: 'white', padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text ? text.toString() : ''}
            />
        ) : (
            text
        ),
  });

  // Handle search on table
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumns(dataIndex);
  };

  // Handle reset on filters of the table
  const handleReset = clearFilters => {
      clearFilters();
      setSearchText("");
  };
 
  // closes the wizard
  const closeWizard = async () => {
    newEvent(RPMEventType.CLOSE_PROJECT_WIZARD);
    setVisibleNewProjectWizard(false);
    await sleep(500);
    let btnPrevius = document.querySelectorAll('.btn-previous') as NodeListOf<HTMLElement>;
    btnPrevius.forEach(el => {
      el.style.display = ("block");
    });
  }

  // Handle the data coming from the child element
  const handleCallback = (childData:any, childDataName:string) => {
    if(childDataName === "completed" && childData === true) {
      closeWizard();
    }
    else if(childDataName === "locked") {
      setClosable(! childData);
    }
  }

  // Show modal for the confirm on delete project
  const showModalConfirmDeleteProject = (record) => {
    setIsModalDeleteProjectVisible(true);
    setEditData(record);
  };
  
  // Handle click on delete project button
  const clickUnlockProject = () => {
    setIsModalUnlockProjectVisible(false);
    let record = tempRecord as {};
    if(record !== undefined) {
      record["edit_mode"] = true;
      setEditData(record);
      setVisibleNewProjectWizard(true);
    }
  };

  // Handle click on delete project button
  const clickDeleteProject = () => {
    setIsModalDeleteProjectVisible(false);
    if(checkedDownloadProject){
      downloadProject(editData).then(() => deleteProject(editData))
    }
    else{
      deleteProject(editData)
    }
  };

  // Handle cancel on delete project modal
  const handleCancel1 = () => {
    setIsModalDeleteProjectVisible(false);
  };

  // Handle cancel on unlock project modal
  const handleCancel2 = () => {
    setIsModalUnlockProjectVisible(false);
  };

  // Handle change on download project before delete
  const onChange = (e) => {
    setCheckedDownloadProject(e.target.checked)
  };

  // Handle change on header radio buttons
  const onChangeHeaderRB = (e) => {
    //console.log(e.target.value)
    saveNewTableView(e.target.value)
    selectedTableView = e.target.value
    getColumns();
    updateTableData();
    setTableLoading(true);
  };

  // Handle change on header refresh button
  const refreshTable = () => {
    setLoadingButton(true);
    setTableLoading(true);
    updateTableData();
  };

  // Return
  return (
    <>
    <Modal
      centered
      visible={visibleNewProjectWizard}
      onOk={() => setVisibleNewProjectWizard(false)}
      onCancel={() => closeWizard()}
      width={"1000px"}
      closable={closable}
      closeIcon={<CloseOutlined style={{color: "white"}}/>}
      destroyOnClose={true}
      maskClosable={false}
      footer={null}
      bodyStyle={{padding:0, overflow:""}}
    >
      <NewProjectWizard key="wizard" projectData={editData} parentCallback={handleCallback}></NewProjectWizard>
    </Modal>
    <Card className="shadow pl-2 pr-2 border">
      <CardHeader className="bg-transparent header-tables" align="middle" >
      <Row align="middle">
          <Col flex="auto">
            <Radio.Group defaultValue={getSettings()['table_view']} onChange={onChangeHeaderRB} buttonStyle="outline">
              <Radio.Button className="ml-1 mr-1 mt-1 mb-1" value="ONLY_USER_PROJECTS">Show only user projects</Radio.Button>
              <Radio.Button className="ml-1 mr-1 mt-1 mb-1" value="USER_AND_COLLABORATORS_PROJECTS">Show user and collaborators projects</Radio.Button>
              <Radio.Button className="ml-1 mr-1 mt-1 mb-1" value="ALL_PROJECTS">Show all projects</Radio.Button>
            </Radio.Group>
          </Col>
          <Col flex="100px">
            <Button
              shape="round"
              className="btn-default-refresh"
              icon={<ReloadOutlined />}
              size={"middle"}
              loading={loadingButton}
              onClick={() => refreshTable()}
            ></Button>
          </Col>
        </Row>
      </CardHeader>
      <Row justify="center" align="middle">
        <Col span={24}>
          <Table 
            scroll={{ x: '800px' }}
            className="" 
            dataSource={dataSource} 
            rowSelection={{
              type: "radio",
              selectedRowKeys: selectedRow,
              onSelect: (row) => onRowSelected(row, false),
            }} 
            onRow={(record) => {
              return {
                onClick: () => {onRowSelected(record, false)},       // click row
                onDoubleClick: () => {onRowSelected(record, true)},  // double click row
                onContextMenu: () => {},                             // right button click row
                onMouseEnter: () => {},                              // mouse enter row
                onMouseLeave: () => {},                              // mouse leave row
              }
            }}
            columns={columns} 
            expandable={expandedContent} 
            loading={tableLoading} 
            onChange={onChangeTable} 
          />
        </Col>
      </Row>
      <CardFooter className="bg-transparent">
      </CardFooter>
    </Card>
    <Modal className="modal-delete-project" title="Delete Project" visible={isModalDeleteProjectVisible} onOk={clickDeleteProject} onCancel={handleCancel1}>
      <p>Are you sure you want to delete the project?</p>
      <Checkbox className="checkbox-delete-project" onChange={onChange}>Download the project</Checkbox>
    </Modal>
    <Modal className="modal-unlock-project" title="Edit Project" visible={isModalUnlockProjectVisible} onOk={clickUnlockProject} onCancel={handleCancel2}>
      <p>The project you want to edit is undergoing another operation for more than 30 minutes.<br></br> Do you want to continue editing this project anyway ? </p>
    </Modal>
    </> 
  );
};

export default ProjectTable;