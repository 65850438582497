/**
 * Rulex Project Manager
 *
 * RPMValidatorTool
 * 
 * @summary Contains the class RPMValidatorTool to perform validation on the forms of the wizards.
 * @author Riccardo Poli, Lorenzo Biasotti
 *
 */

import { RPMFileType } from "types/RPMFileType";
import RPMFile from "./RPMFile";

/**
 * Class with tools to validate some fields of the wizard
 * 
 * @class RPMValidatorTool
 */
class RPMValidatorTool {
 
    // Check files ext
  checkFilesExt = (files:any, extList:any) :boolean => {
    
    var check = true;
    files.forEach(element => {
      var file = new RPMFile(element);
      if(file.checkExt(extList) === false) {
        check = false;
        return false;
      }
      else { return true; }
    });
    return check;
  }

  // Check files size
  checkFilesSize = (files:any) :boolean => {
    
    var check = true;
    files.forEach(element => {
      var file = new RPMFile(element);
      if(file.checkSize() === false) {
        check = false;
        return false;
      }
      else { return true; }
    });
    return check;
  }
  
  // Check files type
  checkFilesType = (files:any) :boolean => {
    
    var check = true;
    files.forEach(element => {
      var file = new RPMFile(element); 
      if(file.getType() !== RPMFileType.PROCESS && file.getType() !== RPMFileType.MODULE && file.getType() !== RPMFileType.INPUT_FILE) {
        check = false;
        return false;
      }
      else { return true; }
    });
    return check;
  }

  // Check files at least one process
  checkFilesAtLeastOneProcess = (files:any) :boolean => {
    
    var check = false;
    files.forEach(element => {
      var file = new RPMFile(element);
      if(file.getType() === RPMFileType.PROCESS) {
        check = true;
        return false;
      }
      else { return true; }
    });
    return check;
  }

  // Check if 2 or more process names are equals
  checkDuplicates = (processName:any, formValues:any, numberOfProcess:number) :boolean => {
    
    var check = 0;
    for(let i=0; i<numberOfProcess; i++) {
      if(formValues["pcs_name_"+i] === processName) {
        check++;
        if(check > 1) { break; }
      }
    }
    return (check>1);
  }

}
 
export default RPMValidatorTool;