/**
 * Rulex Project Manager
 *
 * --ProjectWizard
 * 
 * This file define the wizard and steps components
 * - Wizard component
 * - 5 Steps class-components
 * 
 * @summary Define the components of the project wizard.
 * @author Riccardo Poli, Lorenzo Biasotti
 *
 */

import React, { createRef, useContext, useEffect, useReducer, useRef, useState } from "react";
import { Container, Row, Col } from "reactstrap";
import {FormNP1, FormNP2, FormNP3, FormNP4, FormNP5 } from "../Forms/FormWProject";
import "bootstrap/dist/css/bootstrap.css";
import '../../styles/style-wizard.css';
import WizardCProvider from "contexts/WizardContext";
import { AccountContext } from "contexts/AccountContext";
import { Modal } from "antd";
import RPMFileUploader from "classes/RPMFileUploader";
import $ from "jquery";
import ReactWizard from "../../libraries/react-bootstrap-wizard/React-bootstrap-wizard.js";
import "../../libraries/react-bootstrap-wizard/react-bootstrap-wizard.css" 
import {sleep} from "../../scripts/functions"
import RPMEnvManager from "classes/RPMEnvManager";
import { openLocalNotification } from "components/Notifications/Notification";

/**
 * Define interface for the props
 *
 * @interface IProps
 */
interface IProps {
  parentCallback(value:any, name:string):void; // parent reference
  rulexVersion?:any;
  pjName?:any;
  uList?:any;
  buildStatusS3?:any;
  buildStatusDB?:any;
  dataToEdit?:any;
  locked?:boolean;
}

/**
 * Define interface for the props
 *
 * @interface WProps
 */
 interface WProps {
  parentCallback(value:any, name:string):void; // parent reference
  projectData?:any; // project data to be modified
}

/**
 * Define interface for the states
 *
 * @interface IState
 */
interface IState {
  // step 
  step?: string;  
  // list of rlx versions 
  rulexVersions?: Array<string>,                            
  // list of users
  userList?: Array<{"key":string, "title":string, "description":string, "value":string}>;
  // modal
  isModalVisible?:boolean;
  // upload type
  uploadType?: number;
  // form data
  form1?: {};
  form2?: {};
  form3?: {};
  form4?: Array<string>;
}

/**
 * First step: Name, description and rlx version of the project
 *
 * @class FirstStep
 * @extends {React.Component<IProps, IState>}
 */
class FirstStep extends React.Component <IProps, IState> {

  constructor(props:IProps) {
    super(props);
    this.state = {
      "step": "first step here",
      "form1": {},
    };
  }

  private formRef = createRef<any>();

  // Handle the data coming from the child element
  handleCallback = (childData:any, childDataName:string) =>{

    if(childDataName === "form1") {
      this.setState({"form1": childData});
    }
  }

  // Send data to parent
  sendFormData = () => {
    this.props.parentCallback(this.state.form1, "form1");
  }

  // Validate the step
  isValidated = async () => { 

    const form = this.formRef.current;
    let valid = false;
 
    await form
      .validateFields()
      .then(() => {
        // Validation is successful
        valid = true;
      })
      .catch((errors) => {
        valid = false;  
      });

    if (valid) {
      this.sendFormData();
    }
    return valid;
  }


  // Render
  render() {
    return( <FormNP1 formRef={this.formRef} rulexVersion = {this.props.rulexVersion} pjName = {this.props.pjName} dataToEdit = {this.props.dataToEdit} parentCallback = {this.handleCallback} ></FormNP1>);
  }
}

/**
 * Second step: Files of the project
 *
 * @class SecondStep
 * @extends {React.Component<IProps, IState>}
 */
class SecondStep extends React.Component <IProps, IState> {
  
  constructor(props:IProps) {
    super(props);
    this.state = {
      "step": "second step here",
      "form2": {},
      "uploadType": 0,
    };
  }

  private formRef1 = createRef<any>();
  private formRef2 = createRef<any>();

  // Handle the data coming from the child element
  handleCallback = (childData:any, childDataName:string) => {

    if(childDataName === "form2") {
      this.setState({"form2": childData});
    }
    else if(childDataName === "type") {
      this.setState({"uploadType": childData});
    }
  }

  // Send data to parent
  sendFormData = () => {
    this.props.parentCallback(this.state.form2, "form2");
  }

  // Validate the step
  isValidated = async () => { 

    let form;
    
    if(this.state.uploadType === 0) {
      form = this.formRef1.current;
    }
    else {
      form = this.formRef2.current;
    }
    
    let valid = false;
 
    await form
      .validateFields()
      .then(() => {
        // Validation is successful
        valid = true;
        
      })
      .catch((errors) => {
        valid = false;  
      });

    if (valid) {
      this.sendFormData();
    }
    return valid;
  }

  // Render
  render() {
    return( <FormNP2 formRef={this.formRef1} formRef2={this.formRef2} dataToEdit = {this.props.dataToEdit} parentCallback = {this.handleCallback} ></FormNP2>);
  }
}

/**
 * Third step: Name and description of each process
 *
 * @class ThirdStep
 * @extends {React.Component<IProps, IState>}
 */
class ThirdStep extends React.Component <IProps, IState> {
  
  constructor(props:IProps) {
    super(props);
    this.state = {
      "step": "third step here",
      "form3": {},
    };
  }

  private formRef = createRef<any>();

  // Handle the data coming from the child element
  handleCallback = (childData:any, childDataName:string) =>{
    
    if(childDataName === "form3") {
      this.setState({"form3": childData});
    }
  }

  // Send data to parent
  sendFormData = () => {
    this.props.parentCallback(this.state.form3, "form3");
  }

  // Validate the step
  isValidated = async () => { 

    const form = this.formRef.current;
    
    let valid = false;
 
    await form
      .validateFields()
      .then(() => {
        // Validation is successful
        valid = true;
        
      })
      .catch((errors) => {
        valid = false;  
      });

    if (valid) {
      this.sendFormData();
    }
    return valid;
  }

  // Render
  render() {
    return( <FormNP3 formRef={this.formRef} dataToEdit = {this.props.dataToEdit} parentCallback = {this.handleCallback} ></FormNP3>);
  }
}

/**
 * Fourth step: Collaborators of the project
 *
 * @class FourthStep
 * @extends {React.Component<IProps, IState>}
 */
class FourthStep extends React.Component <IProps, IState> {
  
  applyWizard: boolean;
  answerRdy: boolean;
  
  constructor(props:IProps) {
    super(props);
    this.state = {
      "step": "fourth step here",
      "form4": new Array<string>(),
      "isModalVisible": false,
    };
    this.applyWizard = false;
    this.answerRdy = false;
  }

  // Modal
  showModal = (value:boolean) => {
    this.setState({"isModalVisible": value});
  };
  handleOk = () => {
    this.applyWizard = true;
    this.answerRdy = true;
    this.setState({"isModalVisible": false});
    let btnPrevius = document.querySelectorAll('.btn-previous') as NodeListOf<HTMLElement>;
    btnPrevius.forEach(el => {
      el.style.display = ("none");
    });
  };
  handleCancel = () => {
    this.applyWizard = false;
    this.answerRdy = true;
    this.setState({"isModalVisible": false});
  };

  // Handle the data coming from the child element
  handleCallback = (childData:any, childDataName:string) =>{
    
    if(childDataName === "form4") {
      this.setState({"form4": childData});
    }
  }

  // Send data to parent
  sendFormData = () => {
    this.props.parentCallback(this.state.form4, "form4");
  }

  // Validate the step
  isValidated = async () => {
    // do some validations
    
    this.applyWizard = false;
    var counter = 0;

    // Show modal
    this.showModal(true);
    
    // Wait for answer ~15 seconds
    while(this.answerRdy === false) {
      
      // Wait half second
      await sleep(500);

      // Stop the wait if more than 15 seconds 
      if(counter > 25) {  
        this.answerRdy = true;
        this.showModal(false);
      }

      // Increment the counter
      counter ++;
    }
    
    // Send the data to the wizard
    if(this.applyWizard)
      this.sendFormData();

    // Reset answer as not ready
    this.answerRdy = false;

    // Return what i have gathered from the modal
    return (this.applyWizard);
  }

  // Render
  render() {
    return(
      <> 
      <FormNP4 uList = {this.props.uList} dataToEdit = {this.props.dataToEdit} parentCallback = {this.handleCallback} ></FormNP4>
      <Modal title="Create new Project" visible={this.state.isModalVisible} onOk={this.handleOk} onCancel={this.handleCancel}>
        <p>Do you want to create the project ?</p>
      </Modal>
      </>
    );
  }
}

/**
 * Last step: Progress bar of the building of new project
 *
 * @class LastStep
 * @extends {React.Component<IProps, IState>}
 */
class LastStep extends React.Component <IProps, IState> {
  
  constructor(props:IProps) {
    super(props);
    this.state = {
      "step": "last step here",
    };
  }

  // Send data to parent
  sendData = (completed:boolean) => {
    if(completed !== false)
      this.props.parentCallback(true, "form5");
  }

  // Validate the step
  isValidated = () => {
    // do some validations
    
    if(this.props.buildStatusS3[0] === false && this.props.buildStatusDB[0] === false && this.props.locked === false) {
      this.sendData(true);
      return true;
    }
    else {
      return false;
    }
  }

  // Render
  render() {
    return( <FormNP5 buildStatusS3 = {this.props.buildStatusS3} buildStatusDB = {this.props.buildStatusDB} dataToEdit = {this.props.dataToEdit} parentCallback = {null} ></FormNP5>);
  }
}

/******************************************************************************************************************************************************************
 * NpWizard: Wizard element for the creation of a new project
 * - 5 steps
 *
 * @return {*} 
 */
const NpWizard = (props:WProps): any => {
  
  const stdStateForm1 = {"pj_name": "", "pj_desc": "", "pj_rlxvrs": 0};
  const stdStateForm2 = {"process": null, "modules_and_inputs": null, "pr_names": [], "sp_names": [], "in_names": []};
  //let i=0;

  // State vars
  const [form1,setForm1] = useState(stdStateForm1);
  const [form2,setForm2] = useState(stdStateForm2);
  const [form3,setForm3] = useState({});
  const [form4,setForm4] = useState(Array<string>());
  const [dataReady, setDataReady] = useState(Boolean(false));
  const [buildStatus, setBuildStatus] = useState([Boolean(true), Boolean(false), ""]);          //eslint-disable-line
  const [buildStatusDb, setBuildStatusDb] = useState([Boolean(true), Boolean(false), ""]);      //eslint-disable-line
  const [userList, setUserList] = useState(new Array<{"key":number, "title":string, "description":string, "value":string}>());
  const [rlxVersions, setRlxVersions] = useState(new Array<string>());
  //const [s3uploadStatus, setS3UploadStatus] = useState(1);
  const [projectsName, setProjectsName] = useState(new Array<string>());
  const [dataToEditForm1, setDataToEditForm1] = useState({"edit_mode": false, "project_name": "", "project_description": "", "rulex_version": ""});
  const [dataToEditForm2, setDataToEditForm2] = useState({"edit_mode": false, "project_name": "", "process_files": new Array<string>(), "sub_process_files": new Array<string>(), "input_files": new Array<string>()});
  const [dataToEditForm3, setDataToEditForm3] = useState({"edit_mode": false, "process_list": []});
  const [dataToEditForm4, setDataToEditForm4] = useState({"edit_mode": false, "collaborators": new Array<string>(), "owner": ""});
  const [dataToEditForm5, setDataToEditForm5] = useState({"edit_mode": false});
  const [locked, setLocked] = useState(false);

  // Ref
  const childRef = useRef<any>();
  // Updater
  const [ignored, forceUpdate] = useReducer(x => x + 1, 0);   //eslint-disable-line
  // Session
  const { getSession } = useContext(AccountContext);
  // Events

  // Event (after the first render (only))
  useEffect(() => {
    initWizard();
    if(props.projectData["edit_mode"]){
      setDataToEdit(props.projectData);
    }
  }, []);           // eslint-disable-line react-hooks/exhaustive-deps

  // Event status of operation)
  useEffect(() => {
    if(buildStatusDb[0] === false && buildStatus[0] === false){
      sendData("locked", false);
      setLocked(false);
    }
    if(buildStatusDb[0] === true || buildStatus[0] === true){
      sendData("locked", false);
      setLocked(false);
    }
  }, [buildStatus, buildStatusDb]);           // eslint-disable-line react-hooks/exhaustive-deps

  // Event when is locked or is unlocked
  useEffect(() => {
    if(locked === true) {
      let btnPrevius = document.querySelectorAll('.btn-finish') as NodeListOf<any>;
      btnPrevius.forEach(el => {
        el.disabled = true;
      });
    }
    else if (locked === false) {
      let btnPrevius = document.querySelectorAll('.btn-finish') as NodeListOf<any>;
      btnPrevius.forEach(el => {
        el.disabled = false;
      });
    }
  }, [locked]); // eslint-disable-line react-hooks/exhaustive-deps

  // Event (when state is uploaded)
  useEffect(() => {
  
    if(dataReady === true) {
      
      //lookFormData();
      run();
    }
  }, [dataReady]);  // eslint-disable-line react-hooks/exhaustive-deps

  // Run Build
  const run = () => {

    getSession().then((session: { accessToken: any; }) => {
        
      const token = session.accessToken.jwtToken;

      //newEvent(RPMEventType.START_BUILD_PROJECT);
      sendData("locked", true);
      setLocked(true);
      
      //uploadDataOnS3(form1.pj_name, form2, token);

      if(props.projectData["edit_mode"]){
        let data = prepareDataUpdate();
        updateProject(data, token, form2);
      }
      else{
        let data = prepareData();
        createProject(data, token, form2);
      }

      setDataReady(false);
    });
  }

  // Handle the data coming from the child element
  const handleCallback = (childData:any, childDataName:string) => {

    switch (childDataName) {
      case "form1":
        setForm1(childData);
        break;
      case "form2":
        setForm2(childData);
        break;
      case "form3":
        setForm3(childData);
        break;
      case "form4":
        setForm4(childData);
        setDataReady(true);
        break;
      case "form5":
        sendData("completed", childData);
        //newEvent(RPMEventType.END_BUILD_PROJECT);
        break;
      default:
    }
    forceUpdate();  //Update the state vars
  }

  // Handle the data coming from the file uploader, check for error on token
  const handleFileUploaderCallback = (childData:any, childDataName:string) => {

    if(childDataName === "status") {
      /*i++;
      if(childData === -1 && s3uploadStatus !== -1 && i<5) {
        
        getSession().then((session: { accessToken: any; }) => { 
          const token = session.accessToken.jwtToken;
          setS3UploadStatus(childData);
          forceUpdate(); 
          //uploadDataOnS3(form1.pj_name, form2, token);
        });
      }
      else*/ if(childData === 0) {
        setBuildStatus([false, false, ""]);
      }
      else {
        setBuildStatus([false, true, childData]);
      }
    }
  }

  // Initialize wizard
  const initWizard = () => {
    getSession().then((session: { idToken: any; accessToken: any; }) => {
      
      const token = session.accessToken.jwtToken;
      const user = session.idToken.payload.email;
      getProjectsName(token);
      getRlxVersions(token);
      getUserList(token, user);
    });
  }
  
  // Prepare data
  const prepareData = () => {

    // rulexVersion
    var rulexVersion = rlxVersions[0];
    if(form1.pj_rlxvrs !== 0) {
      rulexVersion = rlxVersions[form1.pj_rlxvrs - 1];
    }

    // pcsData
    let pcsData = {};
    if(form2.process !== undefined && form2.process !== null){
      for(let i=0; i<form2.process.length; i++) {

        let process = form3["pcs_name_"+i];
        let iData = {

          "processFile":              form3["pcs_file_"+i],
          "processDescription":       form3["pcs_desc_"+i]
        };

        pcsData[process] = iData;
      }
    }

    // full data
    let data = {
      
        "projectName":            form1.pj_name, 
        "projectDescription":     form1.pj_desc, 
        "projectRulexVersion":    rulexVersion, 
        "projectCollaborators":   form4, 
        "processFileNames":       form2.pr_names,
        "subProcessFileNames":    form2.sp_names,
        "inputsFileNames":        form2.in_names,
        "processList":            pcsData

    };

    return data;
  }

  const createProject = (dataDB: {}, token: any, files:any) => {
    
    let authorization = 'Bearer ' + token;
    let api = RPMEnvManager.getApiLambda("createProject");
    
    dataDB = JSON.stringify(dataDB);

    $.ajax({
      url: api,
      type: "POST",
      headers: {
          'Authorization': authorization
      },
      contentType: 'application/json',
      data: dataDB,
      processData: false,
      success: function( response ) {
          //console.log("Finito DB");
          setBuildStatusDb([false, false, ""]);
          if(Object.keys(response).length !== 0) {
            childRef.current.uploadFiles(response, files);
          }
      },
      statusCode: {
          401: function() {
              //console.log("Invalid Token.")
              setBuildStatusDb([false, true, "401"]);
              openLocalNotification("error", "create_project", "401")
          },
          409: function() {
            //console.log("Invalid Token.")
            setBuildStatusDb([false, true, "409"]);
            openLocalNotification("error", "create_project", "409")
          },
          500: function(response) {
              //console.log(response.responseText)
              setBuildStatusDb([false, true, "500"]);
              openLocalNotification("error", "create_project", "500")
          }
      }
    });
  } 

  // Prepare data to update
  const prepareDataUpdate = () => {
    
    // rulexVersion
    var rulexVersion = rlxVersions[0];
    if(rlxVersions.includes(form1.pj_rlxvrs.toString())){
      rulexVersion = form1.pj_rlxvrs.toString()
    }
    else if(form1.pj_rlxvrs !== 0) {
      rulexVersion = rlxVersions[form1.pj_rlxvrs - 1];
    }
    
    // pcsData
    let pcsData = {};
    for(let i=0; i<form3["size"]; i++) {

      let process = form3["pcs_name_"+i];
      let iData = {
        "processFile":              form3["pcs_file_"+i],
        "processDescription":       form3["pcs_desc_"+i],
      };

      pcsData[process] = iData;
    }
    let prNames = form2.pr_names === undefined ? [] : form2.pr_names;
    let spNames = form2.sp_names === undefined ? [] : form2.sp_names;
    let inNames = form2.in_names === undefined ? [] : form2.in_names;
    
    // full data
    let data = {
      
        "projectName":            form1.pj_name, 
        "projectDescription":     form1.pj_desc, 
        "projectRulexVersion":    rulexVersion, 
        "projectCollaborators":   form4, 
        "processFileNames":       prNames,
        "subProcessFileNames":    spNames,
        "inputsFileNames":        inNames,
        "processList":            pcsData
    };

    return data;
  }

  // Update project
  const updateProject = (dataDB: {}, token: any, files:any) => {
    let authorization = 'Bearer ' + token;
    let api = RPMEnvManager.getApiLambda("updateProject");
    
    dataDB = JSON.stringify(dataDB);
    
    $.ajax({
      url: api,
      type: "POST",
      headers: {
          'Authorization': authorization
      },
      contentType: 'application/json',
      data: dataDB,
      processData: false,
      success: function( response ) {
          
      },
      statusCode: {
          200: function(response) {
            //console.log("Finito DB");
            setBuildStatusDb([false, false, ""]);
            //console.log(response);
            if(Object.keys(response).length !== 0 && !response.includes("upload completed")) {
              childRef.current.uploadFiles(response, files);
            }
            else if(response.includes("upload completed")) {
              setBuildStatus([false, false, ""]);
            }
          },
          304: function(response) {
            setBuildStatusDb([false, true, "304"]);
            setBuildStatus([false, true, "304"]);
            //console.log("No updates.");
          },
          401: function() {
              //console.log("Invalid Token.")
              setBuildStatusDb([false, true, "401"]);
              setBuildStatus([false, true, "401"]);
          },
          409: function() {
            //console.log("Invalid Token.")
            setBuildStatusDb([false, true, "409"]);
            setBuildStatus([false, true, "409"]);
          },
          500: function(response) {
              //console.log(response.responseText)
              setBuildStatusDb([false, true, "500"]);
              setBuildStatus([false, true, "500"]);
          }
      }
    });
  }
  // Upload on s3
  /*const uploadDataOnS3 = (pjName: string, data: any, token: any) => {
    childRef.current.uploadFiles(pjName, data, token);
  }*/

  // Get rulex versions list
  const getRlxVersions = (token: string) => {
    
    let authorization = 'Bearer ' + token;
    let api = RPMEnvManager.getApiLambda("getRulexVersions");

    $.ajax({
      url: api,
      type: "GET",
      headers: {
          'Authorization': authorization
      },
      contentType: false,
      success: function( response ) {
          if( response !== "" || response !== "[]") {
            var rulexVersion = new Array<string>();
            for (let i = 0; i < response.length; i++) {
              rulexVersion.push(response[i].imageTag);
            }
            setRlxVersions(rulexVersion);
          }
      },
      statusCode: {
          401: function() {
            //console.log("Invalid Token.")
            openLocalNotification("error","db_data","401");
          },
          409: function() {
            //console.log("Invalid Token.")
            openLocalNotification("error","db_data","409");
          },
          500: function() {
            //console.log("Invalid Token.")
            openLocalNotification("error","db_data","500");
          }
      }
    });
  } 

  // Get user list 
  const getUserList = (token : string, user : string) => {
    
    let authorization = 'Bearer ' + token;
    let api = RPMEnvManager.getApiLambda("listUsers");

    $.ajax({
      url: api,
      type: "GET",
      headers: {
          'Authorization': authorization
      },
      contentType: false,
      success: function( response ) {
          if( response !== "" || response !== "{}") {
            var users = new Array<{"key":number, "title":string, "description":string, "value":string}>();
            
            let i = 0;
            Object.entries(response).forEach((entry) => {
              const [keyj, value] = entry;                  //eslint-disable-line
              
              if(props.projectData["edit_mode"] && value[0]['Value'] === user)
              {
                users.push({
                  "key": i,
                  "title": value[0]['Value'],
                  "description": "user",
                  "value":  value[0]['Value'],                
                });
                i++;
              }
              else if(value[0]['Value'] !== user){
                users.push({
                  "key": i,
                  "title": value[0]['Value'],
                  "description": `description`,
                  "value":  value[0]['Value'],                
                });
                i++;
              }
            });
            setUserList(users)
          }
      },
      statusCode: {
        401: function() {
          //console.log("Invalid Token.")
          openLocalNotification("error","db_data","401");
        },
        409: function() {
          //console.log("Invalid Token.")
          openLocalNotification("error","db_data","409");
        },
        500: function() {
          //console.log("Invalid Token.")
          openLocalNotification("error","db_data","500");
        }
      }
    });
  } 

  // Get projects names
  const getProjectsName = (token: string) => {
    
    let authorization = 'Bearer ' + token;
    let api = RPMEnvManager.getApiLambda("getItems");

    $.ajax({
      url: api,
      type: "POST",
      headers: {
          'Authorization': authorization
      },
      contentType: false,
      data: 'projectName',
      success: function( response ) {
          if( response !== "" || response !== "[]") {
            var pName = new Array<string>();
            for (let i = 0; i < response.length; i++) {
              pName.push(response[i].projectName);
            }
            setProjectsName(pName);
          }
      },
      statusCode: {
        401: function() {
          //console.log("Invalid Token.")
          openLocalNotification("error","db_data","401");
        },
        409: function() {
          //console.log("Invalid Token.")
          openLocalNotification("error","db_data","409");
        },
        500: function() {
          //console.log("Invalid Token.")
          openLocalNotification("error","db_data","500");
        }
      }
    });
  } 

  // Send data to parent
  const sendData = (step:string, completed:boolean) => {
    props.parentCallback(completed, step);
  }

  // [DEBUG] Show form data 
  const lookFormData = () => {  // eslint-disable-line
    //console.log(form1);
    //console.log(form2);
    //console.log(form3);
    //console.log(form4);
  }

  //Defines the data to pass to the forms
  const setDataToEdit = (pjData: any) =>{
    setDataToEditForm1({
      "edit_mode":              pjData["edit_mode"],
      "project_name":           pjData["project"],
      "project_description":    pjData["description"],
      "rulex_version":          pjData["rulex_version"]
    });

    setDataToEditForm2({
      "edit_mode":          pjData["edit_mode"],
      "project_name":       pjData["project"],
      "process_files":      pjData["process_files"],
      "sub_process_files":  pjData["sub_process_files"],
      "input_files":        pjData["input_files"]
    });

    setDataToEditForm3({
      "edit_mode":        pjData["edit_mode"],
      "process_list":     pjData["process_list"]
    });

    setDataToEditForm4({
      "edit_mode":        pjData["edit_mode"],
      "collaborators":    pjData["collaborators"],
      "owner":            pjData["owner"]
    });
    
    setDataToEditForm5({
      "edit_mode":    pjData["edit_mode"],
    });
    
    forceUpdate();
  }
  
  // Define the steps of the wizard
  var steps = [
    { "stepName": "Project definition", "component": FirstStep, "stepProps": {"parentCallback" : handleCallback, "rulexVersion": rlxVersions, "pjName": projectsName, "dataToEdit": dataToEditForm1}},
    { "stepName": "Project Files", "component": SecondStep, "stepProps": {"parentCallback" : handleCallback, "dataToEdit": dataToEditForm2}},
    { "stepName": "Process definition", "component": ThirdStep, "stepProps": {"parentCallback" : handleCallback, "dataToEdit": dataToEditForm3}},
    { "stepName": "Collaborators", "component": FourthStep, "stepProps": {"parentCallback" : handleCallback, "uList": userList, "dataToEdit": dataToEditForm4}},
    { "stepName": "Upload", "component": LastStep, "stepProps": {"parentCallback" : handleCallback, "buildStatusS3" : buildStatus, "buildStatusDB" : buildStatusDb, "dataToEdit": dataToEditForm5, "locked": locked}}
  ];

  let title = <span className="wiz-title-big"></span>
  if(props.projectData["edit_mode"]){
    title = <span className="wiz-title-big">EDIT PROJECT</span>
  }
  else{
    title = <span className="wiz-title-big">DEPLOY PROJECT</span>
  }
  // Return
  return (
    <>
    <Container fluid className="wizard-ctn-project">
      <Row className="wizard-row-project">
        <Col className="wizard-col-project p-0">
        <WizardCProvider>
          <ReactWizard
            steps={steps}
            title={title}
            description={<Col>
              </Col>}
            headerTextCenter
            validate
            color="ego_color"
          />
          <RPMFileUploader ref={childRef} parentCallback = {handleFileUploaderCallback}></RPMFileUploader>
        </WizardCProvider>
        </Col>
      </Row>
    </Container>
    </>
  );

};

export default NpWizard;