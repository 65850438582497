/**
 * Rulex Project Manager
 *
 * --WizardContext
 * 
 * @summary Context used to trasfer data between wizard steps.
 * @author Riccardo Poli, Lorenzo Biasotti
 *
 */

import React, { FC, useState } from "react";

const contextDefaultValues: WizardContextState = {
  ctxData: new Array<string>(),
  setCtxData: () => {},
};

/*
*   THIS CONTEXT MANAGE WIZARD DATA.
*   
*/

export const WizardContext = React.createContext<WizardContextState>(contextDefaultValues);

// This context provider is passed to any component requiring the context
export const WizardCProvider: FC = ({ children }) => {
  
  const [ctxData, setData] = useState<Array<string>>(contextDefaultValues.ctxData);

  const setCtxData = (newData: Array<string>) => setData((data) => [...newData]);

  return (
    <WizardContext.Provider
      value={{
        ctxData,
        setCtxData
      }}
    >
      {children}
    </WizardContext.Provider>
  );

};

export default WizardCProvider;
