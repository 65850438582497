/**
 * Rulex Project Manager
 *
 * --RPMEventType
 * 
 * @summary This file contains the Event Enum definitions
 * @author Riccardo Poli, Lorenzo Biasotti
 *
 */

/**
 * Define the enum for the event types
 * Each event has a code, ordered by type
 *
 * @export
 * @enum
 */
export enum RPMEventType {

    IDLE = 0,
    STARTING_RPM = 1,
    OPEN_PROJECT_WIZARD_NEW = 30,
    OPEN_PROJECT_WIZARD_EDIT = 31,
    START_BUILD_PROJECT = 32,
    END_BUILD_PROJECT = 33,
    END_EDIT_PROJECT = 34,
    CLOSE_PROJECT_WIZARD = 35,
    OPEN_PROCESS_WIZARD_NEW = 40,
    OPEN_PROCESS_WIZARD_EDIT = 41,
    START_DEPLOY_PROCESS = 42,
    END_DEPLOY_PROCESS = 43,
    END_EDIT_PROCESS = 44,
    CLOSE_PROCESS_WIZARD = 45,
    START_CLONE_PRODUCTION = 50,
    END_CLONE_PRODUCTION = 51,
    END_FAST_CLONE_PRODUCTION = 52,
    START_RUN_TASK = 60,
    END_RUN_TASK = 61,
    END_DELETE_PROJECT = 70,
    WARNING_NO_UPDATE_DEPLOY = 3041,
    UNAUTH_BUILD = 4011,
    UNAUTH_DEPLOY = 4012,
    UNAUTH_EDIT = 4013,
    UNAUTH_RUN = 4014,
    UNAUTH_CLONE = 4015,
    UNAUTH_DELETE = 4016,
    CONCURRENCY_BUILD = 4091,
    CONCURRENCY_DEPLOY = 4092,
    CONCURRENCY_EDIT = 4093,
    CONCURRENCY_RUN = 4094,
    CONCURRENCY_CLONE = 4095,
    CONCURRENCY_DELETE = 4096,
    ERROR_BUILD = 5001,
    ERROR_DEPLOY = 5002,
    ERROR_EDIT = 5003,
    ERROR_RUN = 5004,
    ERROR_CLONE = 5005,
    ERROR_DELETE = 5006,

}