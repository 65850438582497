/**
 * Rulex Project Manager
 *
 * --FormWProject
 * 
 * This file contain 5 component to be used as forms/visualization for the steps of the wizard.
 * - Project definition.
 * - File input forms.
 * - Processes definition.
 * - Collaborators.
 * - LoadingBar for the progress of the wizard.
 * 
 * @summary Contains 5 form-steps components for the wizard.
 * @author Riccardo Poli, Lorenzo Biasotti
 *
 */


import React, { useContext, useEffect, useState } from "react";
import {Form as FormA, Select, Transfer, Upload, Input, Radio, Row, Col, Result} from 'antd';
import { FileAddOutlined, FileMarkdownOutlined, InboxOutlined, SettingOutlined } from '@ant-design/icons';
import { UploadChangeParam } from "antd/lib/upload";
import { RPMFileType } from "types/RPMFileType";
import { WizardContext } from "contexts/WizardContext";
import RPMFile from "classes/RPMFile";
import { useForm } from "antd/lib/form/Form";
import RPMValidatorTool from "classes/RPMValidatorTool";
import 'antd/dist/antd.css';
import DirectoryTree from "antd/lib/tree/DirectoryTree";
import VAL_MSGS from "../../files/validation-msgs.json"; 
import { openLocalNotification } from "components/Notifications/Notification";

/**
 * Interface to define the form props
 *
 * @interface IProps
 */
interface IProps {
  parentCallback(value:any, name:string):void;  // parent reference
  rulexVersion?:any;
  pjName?:any;
  uList?:any;
  buildStatusS3?:any;
  buildStatusDB?:any;
  formRef?: any;
  formRef2?: any;
  dataToEdit?: any;
}

/**
 * Form 1: Name, description and rulex version of the new project.
 * 
 * @param {IProps} props
 * @return {*} 
 */
const FormNP1 = (props:IProps) => {

  // State Vars
  const stdState = {"pj_name": "", "pj_desc": "", "pj_rlxvrs": 0};
  const [formData, setFormData] = useState(stdState);
  // Form
  const [form] = FormA.useForm();
  // Form elements
  const { Option } = Select;
  const { TextArea } = Input;

  // Event (when state is uploaded in defined variables)
  useEffect(() => {
    sendData();
  }, [formData]); // eslint-disable-line react-hooks/exhaustive-deps

  // Event (set the value of project name, description and rulex version if editMode is true)
  useEffect(() => {
    if(props.dataToEdit["edit_mode"]){
      form.setFieldsValue({"pj_name": props.dataToEdit["project_name"], "pj_desc": props.dataToEdit["project_description"], "pj_rlxvrs": props.dataToEdit["rulex_version"]});
      setFormData({"pj_name": props.dataToEdit["project_name"], "pj_desc": props.dataToEdit["project_description"], "pj_rlxvrs": props.dataToEdit["rulex_version"]});
    }
  }, [props.dataToEdit]); // eslint-disable-line

  // Send data to parent
  const sendData = () => {
    props.parentCallback(formData, "form1");
  }

  // Handle the change of form input, uploading the state vars
  const handleFormChanges = () => {
    setFormData(form.getFieldsValue());
  }

  // Define rule 1
  const rule1 = [
    { required: true, message: VAL_MSGS.PJW.S1.Name.empty},
    { min: 3, message: VAL_MSGS.PJW.S1.Name.short},
    { pattern: /^[0-9A-Za-z\-]+$/, message: VAL_MSGS.PJW.S1.Name.wrong_symbols},     //eslint-disable-line
    { pattern: /^(?![_\-]+$)/, message: VAL_MSGS.PJW.S1.Name.only_symbols},     //eslint-disable-line
    { whitespace: true, message: VAL_MSGS.PJW.S1.Name.only_spaces},
    () => ({
      validator(_:any, value:any) {
        if (value.includes("--") || value.substring(0,1) === "-"){
          // the name does't contain the character '--' because in backend are used to split the project and process
          return Promise.reject(new Error(VAL_MSGS.PJW.S1.Name.reserved_chars));
        }
        else if (!props.dataToEdit["edit_mode"] && props.pjName.includes(value)) {
          // name already exist
          return Promise.reject(new Error(VAL_MSGS.PJW.S1.Name.already_exist));
        }
        else {
          // ok
          return Promise.resolve();
        }
      },
    }),
  ];
  // Define rule 2
  const rule2 = [
    { required: true, message: VAL_MSGS.PJW.S1.Description.empty},
    //{ pattern: /^[0-9A-Za-z\s\-\_]+$/, message: 'Must only contain letters, numbers, dashes, underscores, and spaces.' },     //eslint-disable-line
    //{ pattern: /^(?![_\-]+$)/, message: 'Cannot contain only symbols.' },     //eslint-disable-line
    { whitespace: true, message: VAL_MSGS.PJW.S1.Description.only_spaces},
  ];

  // Define the available options (iterated by the number of rulex versions)
  let OptionList=props.rulexVersion.map((item:string,index:number)=>{
    return(
      <Option key={"opt_"+index} value={index+1}>{item}</Option>
    )
  });

  // Return
  return (
    <>
      <div className="form-ctn f-pj f-pj-1">
        <Row className="w-row-step-title" justify="center">
          <Col>
            <h3 className="form-step-title">Project Definition</h3>
          </Col>
        </Row>
        <Row className="w-row-form-body" justify="center" align="middle">
          <Col span={20}>
            <FormA className="w-form-body" initialValues={{"pj_name": "", "pj_desc": "", "pj_rlxvrs": 0,}} colon={false} form={form} ref={props.formRef} onValuesChange={handleFormChanges}>
              <Row justify="start" className="mt-3">
                <Col span={24} lg={6}>
                  <p>Project Name:</p>
                </Col>
                <Col span={24} lg={18}>
                  <FormA.Item className="itf11" name="pj_name" rules={rule1} validateTrigger={[]}>
                    <Input name="pj_name" className="inf11" type="text" maxLength={60} disabled={props.dataToEdit["edit_mode"]}/>
                  </FormA.Item>
                </Col>
              </Row>
              <Row justify="start" className="mt-1">
                <Col span={24} lg={6}>
                  <p>Project Description:</p>
                </Col>
                <Col span={24} lg={18}>
                <FormA.Item className="itf12" name="pj_desc" rules={rule2} validateTrigger={[]}>
                  <TextArea name="pj_desc" className="inf12" autoSize={{ minRows: 4, maxRows: 4 }} showCount maxLength={200}/>
                </FormA.Item>
                </Col>
              </Row>
              <Row justify="start" className="mt-1">
                <Col span={24} lg={6}>
                  <p>Rulex Version:</p>
                </Col>
                <Col span={24} sm={9}>
                  <FormA.Item className="itf13" name="pj_rlxvrs" /*rules={rule3} validateTrigger={[]}*/>
                    <Select className="inf13" showSearch aria-label="select" placeholder="Search to Select" optionFilterProp="children"
                            filterOption={(input, option) => option.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            filterSort={(optionA, optionB) =>
                                optionA.toLowerCase().localeCompare(optionB.toLowerCase())
                            }
                    >
                      <Option value={0}>latest</Option>
                      {OptionList}
                    </Select>
                  </FormA.Item>
                </Col>
              </Row>
            </FormA>
          </Col>
        </Row>
      </div>
    </>
  );
};



/**
 * Form 2: Files of the project.
 *
 * @param {IProps} props
 * @return {*} 
 */
const FormNP2 = (props:IProps) => {

  // State Vars
  const stdState = {"process": null, "modules_and_inputs": null, "pr_names": [], "sp_names": [], "in_names": []};
  const uploadTypeDescription = ["Upload files in their respective box...", "Upload a folder containing the files in the correct structure..."];
  const [formData,setFormData] = useState(stdState);      //eslint-disable-line
  const [uploadType, setUploadType] = React.useState({"type": 0, "description": uploadTypeDescription[0]});
  const [ready, setReady] = useState(false);
  const [treeData, setTreeData] = useState([]);
  //const [pcsToDelete, setPcsToDelete] = useState([]);
  //const [modulesToDelete, setModulesToDelete] = useState([]);
  // Context Vars
  const { setCtxData } = useContext(WizardContext);
  // Class tool
  const valTool = new RPMValidatorTool();
  let pastUpload = undefined;
  
  useEffect(() => {
    setCtxData(new Array<string>());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // Event (when state is uploaded in defined variables)
  useEffect(() => {
    if(ready === true) {
      sendData();
    }
    setReady(false);
  }, [ready]); // eslint-disable-line react-hooks/exhaustive-deps

  //Build the tree. Rebuilds the tree if a file is added (editMode)
  useEffect(() => {
    if(props.dataToEdit["edit_mode"]){
      let tree = setTree();
      setTreeData(tree);
    }
    else{
      let tree = [
        {
          title: 'Project Folder',
          key: '0-0',
          children: [
            { title: 'process_files.prcx', key: '0-0-0', isLeaf: true },
            { title: 'modules', key: '0-0-1', isLeaf: false,
              children: [
                { title: 'module_files.prcx', key: '0-0-1-0', isLeaf: true },
                { title: 'input_files', key: '0-0-1-1', isLeaf: true },
              ] 
            },
          ],
        },
      ];
      setTreeData(tree);
    }
  }, [props.dataToEdit, formData.pr_names, formData.in_names, formData.sp_names]); // eslint-disable-line

  const setTree = () => {
    //Adds the filenames of the processes found on s3
    let proc = [];
    let count = 0;
    for(count = 0; count < props.dataToEdit["process_files"].length; count++){
      if(formData.pr_names.length === 0 || !formData.pr_names.includes(props.dataToEdit["process_files"][count])){//not add a repeating file to the tree
        proc.push({ title: props.dataToEdit["process_files"][count], key: '0-0-'+count, isLeaf: true });
      }
    }

    //Checks if any files have been added and adds them to the tree
    if(formData.pr_names !== null && formData.pr_names !== undefined && formData.pr_names.length !== 0){
      for(let c = 0; c < formData.pr_names.length; c++){
        if(props.dataToEdit["process_files"].includes(formData.pr_names[c])){//If the file has the same name as one that is already on s3 then I change the icon
          proc.push({ title: formData.pr_names[c], key: '0-0-'+count++, isLeaf: true, icon:<FileMarkdownOutlined /> });
        }
        else{
          proc.push({ title: formData.pr_names[c], key: '0-0-'+count++, isLeaf: true, icon:<FileAddOutlined /> });
        }
      }
    }

    //Adds the filenames of the subProcesses found on s3
    let mod = [];
    let count2 = 0;
    for(count2 = 0; count2 < props.dataToEdit["sub_process_files"].length && props.dataToEdit["sub_process_files"][count2] !== ''; count2++){
      if(formData.sp_names.length === 0 || !formData.sp_names.includes(props.dataToEdit["sub_process_files"][count2])){
        mod.push({ title: props.dataToEdit["sub_process_files"][count2], key: '0-0-1-'+count2, isLeaf: true });
      }
    }
    //Adds the filenames of the input files found on s3
    for(let i = 0; i < props.dataToEdit["input_files"].length && props.dataToEdit["input_files"][i] !== ''; i++){
      if(formData.in_names.length === 0 || !formData.in_names.includes(props.dataToEdit["input_files"][i])){
        mod.push({ title: props.dataToEdit["input_files"][i], key: '0-0-1-'+count2++, isLeaf: true });
      }
    }
    
    //Checks if any files have been added and adds them to the tree
    if(formData.sp_names !== null && formData.sp_names !== undefined && formData.sp_names.length !== 0){
      for(let c = 0; c < formData.sp_names.length; c++){
        if(props.dataToEdit["sub_process_files"].includes(formData.sp_names[c])){//If the file has the same name as one that is already on s3 then I change the icon
          mod.push({ title: formData.sp_names[c], key: '0-0-1-'+count2++, isLeaf: true, icon:<FileMarkdownOutlined /> });
        }
        else{
          mod.push({ title: formData.sp_names[c], key: '0-0-1-'+count2++, isLeaf: true, icon:<FileAddOutlined /> });
        }
      }
    }
    //Checks if any files have been added and adds them to the tree
    if(formData.in_names !== null && formData.in_names !== undefined && formData.in_names.length !== 0){
      for(let c = 0; c < formData.in_names.length; c++){
        if(props.dataToEdit["input_files"].includes(formData.in_names[c])){//If the file has the same name as one that is already on s3 then I change the icon
          mod.push({ title: formData.in_names[c], key: '0-0-1-'+count2++, isLeaf: true, icon:<FileMarkdownOutlined /> });
        }
        else{
          mod.push({ title: formData.in_names[c], key: '0-0-1-'+count2++, isLeaf: true, icon:<FileAddOutlined /> });
        }
      }
    }

    proc.push({ title: 'modules', key: '0-0-'+count, isLeaf: false, children: mod});
    let tree = [
      {
        title: props.dataToEdit["project_name"],
        key: '0-0',
        children: proc
      },
    ];
  
    return tree;
  }

  // Send data to parent
  const sendData = () => {
    props.parentCallback(formData, "form2");
    props.parentCallback(uploadType.type, "type");
  }

  // Handle the change of form input, uploading the state vars (files)
  const handleChangeFiles = (event:UploadChangeParam<any>, place:string) => {

    var prArray = new Array<any>();
    var mdArray = new Array<any>();
    var prArrayNames = new Array<string>();
    var spArrayNames = new Array<string>();
    var inArrayNames = new Array<string>();

    for(var i=0; i<event.fileList.length; i++) {
      
      var file = new RPMFile(event.fileList[i]);
      
      if(place === "filePr_list") {
        prArray.push(file.getFile());
        prArrayNames.push(file.getName());
      }

      if(place === "fileMd_list") {
        mdArray.push(file.getFile());
        if(file.getType() === RPMFileType.INPUT_FILE) {
          inArrayNames.push(file.getName());
        }
        else if (file.getExtension() === "prcx") {
          spArrayNames.push(file.getName());
        }
      }
    }

    if(place === "filePr_list") {
      saveData(prArrayNames);
      formData.process = prArray;
      formData.pr_names = prArrayNames;
    }
    else if(place === "fileMd_list") {
      formData.modules_and_inputs = mdArray;
      formData.sp_names = spArrayNames;
      formData.in_names = inArrayNames;
    } 

    setReady(true);
  }

  const checkAlreadyUploaded = (fileList:FileList) => {

    let alreadyDone = false;
    let k=0;

    for(let file in fileList) {
  
      if(file !== pastUpload[k]) {
        alreadyDone = true;
        break;
      }
      k++;
    }

    return alreadyDone;
  }

  // Handle the change of form input, uploading the state vars (files)
  const handleChangeDirectory = (event:any) => {

    if(pastUpload === undefined || checkAlreadyUploaded(event.fileList) === false) {

      var prArray = new Array<any>();
      var mdArray = new Array<any>();
      var prArrayNames = new Array<string>();
      var spArrayNames = new Array<string>();
      var inArrayNames = new Array<string>();

      for(var i=0; i<event.fileList.length; i++) {

        var file = new RPMFile(event.fileList[i]);

        if(file.getType() === RPMFileType.PROCESS) {
          prArray.push(file.getFile());
          prArrayNames.push(file.getName());
        }
        else if(file.getType() === RPMFileType.INPUT_FILE) {
          mdArray.push(file.getFile());
          inArrayNames.push(file.getName());
        }
        else if(file.getType() === RPMFileType.MODULE) {
          mdArray.push(file.getFile());
          spArrayNames.push(file.getName());
        }
      }
      
      saveData(prArrayNames);
      formData.process = prArray;
      formData.modules_and_inputs = mdArray;
      formData.pr_names = prArrayNames;
      formData.sp_names = spArrayNames;
      formData.in_names = inArrayNames;

      pastUpload = event.fileList;

      setReady(true);
    }
  }  

  // Handle upload type change
  const onChangeUploadType = (e) => {
    setUploadType({"type": e.target.value, "description": uploadTypeDescription[e.target.value]});
    props.parentCallback(e.target.value, "type");
    props.formRef.current.resetFields();
    props.formRef2.current.resetFields();
  };
  
  // Save data into ctxData context
  const saveData = (data:Array<string>) => {
    setCtxData(data);
  }
  
  // Define rule file 1
  const rule1 = [
    () => ({
      validator(_:any, value:any) {
        if (value === undefined || value.fileList.length < 1) {
          // no file
          return Promise.reject(new Error(VAL_MSGS.PJW.S2.Process.empty));
        }
        else if(valTool.checkFilesExt(value.fileList, ["prcx"]) === false) {
          // wrong extension
          return Promise.reject(new Error(VAL_MSGS.PJW.S2.Process.extension));
        }
        else if(valTool.checkFilesSize(value.fileList) === false) {
          // too big
          return Promise.reject(new Error(VAL_MSGS.PJW.S2.Process.big));
        }
        else {
          // ok
          return Promise.resolve();
        }
      },
    }),
  ];

  // Define rule file 1
  const rule1Edit = [
    () => ({
      validator(_:any, value:any) {
        if (value === undefined || value.fileList.length < 1) {
          // no file
          return Promise.resolve();
        }
        else if(valTool.checkFilesExt(value.fileList, ["prcx"]) === false) {
          // wrong extension
          return Promise.reject(new Error(VAL_MSGS.PJW.S2.Process.extension));
        }
        else if(valTool.checkFilesSize(value.fileList) === false) {
          // too big
          return Promise.reject(new Error(VAL_MSGS.PJW.S2.Process.big));
        }
        else {
          // ok
          return Promise.resolve();
        }
      },
    }),
  ];

  // Define rule file 2
  const rule2 = [
    () => ({
      validator(_:any, value:any) {
        if (value === undefined ) {
          // no file
          return Promise.resolve();
        }
        else if(valTool.checkFilesExt(value.fileList, ["csv", "txt", "xlsx", "xls", "prcx"]) === false) {
          // wrong extension
          return Promise.reject(new Error(VAL_MSGS.PJW.S2.Modules.extension));
        }
        else if(valTool.checkFilesSize(value.fileList) === false) {
          // too big
          return Promise.reject(new Error(VAL_MSGS.PJW.S2.Modules.big));
        }
        else {
          // ok
          return Promise.resolve();
        }
      },
    }),
  ];

  // Define rule file 3
  const rule3 = [
    () => ({
      validator(_:any, value:any) {
        if (value === undefined  || value.fileList.length < 1) {
          // no file
          return Promise.reject(new Error(VAL_MSGS.PJW.S2.Folder.empty));
        }
        else if(valTool.checkFilesType(value.fileList) === false) {
          // wrong extensions
          return Promise.reject(new Error(VAL_MSGS.PJW.S2.Folder.extension));
        }
        else if(valTool.checkFilesAtLeastOneProcess(value.fileList) === false) {
          // no process found
          return Promise.reject(new Error(VAL_MSGS.PJW.S2.Folder.missing_process));
        }
        else if(valTool.checkFilesSize(value.fileList) === false) {
          // no process found
          return Promise.reject(new Error(VAL_MSGS.PJW.S2.Folder.big));
        }
        else {
          // ok
          return Promise.resolve();
        }
      },
    }),
  ];

  let formEdit =<div></div>
  if(props.dataToEdit["edit_mode"]){
    formEdit = (
      <Col span={22}>
            <Row justify="center" className="mt-2">
              <Col span={24}>
                <FormA id="pj_form_st2_type1" colon={false} hidden={uploadType.type !== 0} ref={props.formRef}>
                  <Row justify="center" gutter={[5, 0]}>
                    <Col span={16} xl={8}>
                      <FormA.Item className="itf21">
                        <p className="form-label-21">Process Files:</p>
                        <FormA.Item name="pj_filesPr" valuePropName="prList" noStyle rules={rule1Edit} validateTrigger={["onClick", "onChange"]}>
                          <Upload.Dragger className="inf21" name="prFiles_uploads" accept=".prcx" beforeUpload={() => false} multiple onChange={ (event) => handleChangeFiles(event, "filePr_list")}>
                            <p className="ant-upload-drag-icon">
                              <InboxOutlined />
                            </p>
                            <p className="ant-upload-text">Click or drag file to this area to upload</p>
                            <p className="ant-upload-hint">Support for a single or bulk upload.</p>
                          </Upload.Dragger>
                        </FormA.Item>
                      </FormA.Item>
                    </Col>
                    <Col span={16} xl={8}>
                      <FormA.Item className="itf22">
                        <p className="form-label-22">Module and Input Files:</p>
                        <FormA.Item name="pj_filesMd" valuePropName="mdList" noStyle rules={rule2} validateTrigger={["onClick", "onChange"]}>
                          <Upload.Dragger className="inf22" name="mdFiles_uploads" accept=".txt, .prcx, .csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" beforeUpload={() => false} multiple onChange={ (event) => handleChangeFiles(event, "fileMd_list")}>
                            <p className="ant-upload-drag-icon">
                              <InboxOutlined />
                            </p>
                            <p className="ant-upload-text">Click or drag file to this area to upload</p>
                            <p className="ant-upload-hint">Support for a single or bulk upload.</p>
                          </Upload.Dragger>
                        </FormA.Item>
                      </FormA.Item>
                    </Col>
                    <Col span={24} xl={8}>
                      <Row justify={"center"}> 
                      <DirectoryTree
                        multiple
                        defaultExpandAll
                        className="tree-view"
                        treeData={treeData}
                        selectable={false}
                      />
                      </Row>
                    </Col>
                  </Row>
                </FormA>
              </Col>
            </Row>
          </Col>
    );
  }
  // Return
  return (
    <>
      <div className="form-ctn f-pj f-pj-2">
        <Row className="w-row-step-title" justify="center">
          <Col>
            <h3 className="form-step-title">Project Files</h3>
          </Col>
        </Row>
        <Row hidden={props.dataToEdit["edit_mode"]} className="w-row-form-body" justify="center" align="middle">
          <Col span={22}>
            <Row justify="center" className="">
              <Col span={24}>
                <FormA id="pj_form_st2_select_type" className="formbf2">
                  <Row justify="center">
                    <Radio.Group onChange={onChangeUploadType} defaultValue={0} buttonStyle="solid">
                        <Radio.Button style={{width: "150px"}} value={0}>Upload Files</Radio.Button>
                        <Radio.Button style={{width: "150px"}} value={1}>Upload Folder</Radio.Button>
                    </Radio.Group>
                  </Row>
                  <Row justify="center">
                    <Col>
                      <p id="pjFilesForm">{uploadType.description}</p>
                    </Col>
                  </Row>
                </FormA>
              </Col>
            </Row>
            <Row justify="center" className="mt-2">
              <Col span={24}>
                <FormA id="pj_form_st2_type1" colon={false} hidden={uploadType.type !== 0} ref={props.formRef}>
                  <Row justify="center" gutter={[5, 0]}>
                    <Col span={12}>
                      <FormA.Item className="itf21">
                        <p className="form-label-21">Process Files:</p>
                        <FormA.Item name="pj_filesPr" valuePropName="prList" noStyle rules={rule1} validateTrigger={["onClick", "onChange"]}>
                          <Upload.Dragger className="inf21" name="prFiles_uploads" accept=".prcx" beforeUpload={() => false} multiple onChange={ (event) => handleChangeFiles(event, "filePr_list")}>
                            <p className="ant-upload-drag-icon">
                              <InboxOutlined />
                            </p>
                            <p className="ant-upload-text">Click or drag file to this area to upload</p>
                            <p className="ant-upload-hint">Support for a single or bulk upload.</p>
                          </Upload.Dragger>
                        </FormA.Item>
                      </FormA.Item>
                    </Col>
                    <Col span={12}>
                      <FormA.Item className="itf22">
                        <p className="form-label-22">Module and Input Files:</p>
                        <FormA.Item name="pj_filesMd" valuePropName="mdList" noStyle rules={rule2} validateTrigger={["onClick", "onChange"]}>
                          <Upload.Dragger className="inf22" name="mdFiles_uploads" accept=".txt, .prcx, .csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" beforeUpload={() => false} multiple onChange={ (event) => handleChangeFiles(event, "fileMd_list")}>
                            <p className="ant-upload-drag-icon">
                              <InboxOutlined />
                            </p>
                            <p className="ant-upload-text">Click or drag file to this area to upload</p>
                            <p className="ant-upload-hint">Support for a single or bulk upload.</p>
                          </Upload.Dragger>
                        </FormA.Item>
                      </FormA.Item>
                    </Col>
                  </Row>
                </FormA>
              </Col>
            </Row>
            <Row justify="center" className="">
              <Col span={24}>
                <FormA id="pj_form_st2_type2" colon={false} hidden={uploadType.type !== 1} ref={props.formRef2}>
                  <Row justify="center">
                    <Col span={12}>
                      <FormA.Item className="itf23">
                        <p className="form-label-23">Project Folder:</p>
                        <FormA.Item name="pjDirectoryForm1" valuePropName="prList" noStyle rules={rule3} validateTrigger={["onClick", "onChange"]}>
                          <Upload.Dragger className="inf23" name="prDirectory_uploads" directory beforeUpload={() => false} onChange={ (event) => handleChangeDirectory(event)}>
                            <p className="ant-upload-drag-icon">
                              <InboxOutlined />
                            </p>
                            <p className="ant-upload-text">Click or drag the folder to this area to upload</p>
                            <p className="ant-upload-hint">Support for a single upload.</p>
                          </Upload.Dragger>
                        </FormA.Item>
                      </FormA.Item>
                    </Col>
                    <Col span={12}>
                      <Row justify={"center"}> 
                      <DirectoryTree
                        multiple
                        defaultExpandAll
                        className="tree-view"
                        treeData={treeData}
                        selectable={false}
                      />
                      </Row>
                    </Col>
                  </Row>
                </FormA>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row hidden={!props.dataToEdit["edit_mode"]} className="w-row-form-body" justify="center" align="middle">
          {formEdit}
        </Row>
      </div>
    </>
  );
  
};

/**
 * Form 3: Name and description of each process inside the project.
 *
 * @param {IProps} props
 * @return {*} 
 */
const FormNP3 = (props:IProps) => {

  // State Vars
  const stdState = {};
  const [formData,setFormData] = useState(stdState); 
  const [disabledProcess, setDisabledProcess] = useState([]);
  // Context Vars
  const { ctxData, setCtxData } = useContext(WizardContext);    //eslint-disable-line
  // Form elements
  const { TextArea } = Input;
  // Class tool
  const valTool = new RPMValidatorTool();
  // Form 
  const [form] = useForm();

  // Event (when state is uploaded in defined variables)
  useEffect(() => {
    sendData();
  }, [formData]); // eslint-disable-line react-hooks/exhaustive-deps

  // Event (when state is uploaded in defined variables)
  /*useEffect(() => {
    
    //resetFormFields();
  }, [ctxData]);*/ // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    var dict = {};
    ctxData.map((item,index)=> {
      dict["pcs_name_"+index] = item.split(".")[0].replace(/[^a-zA-Z0-9]/g,'-');
      dict["pcs_desc_"+index] = "";
      dict["pcs_file_"+index] = item;
      return true;
    })
    
    if(props.dataToEdit["edit_mode"]){
      for(let i in props.dataToEdit["process_list"]){
        if(props.dataToEdit["process_list"][i]["taskType"] !== "prod"){
          if(ctxData.includes(props.dataToEdit["process_list"][i]["processFile"])){
            let index = ctxData.indexOf(props.dataToEdit["process_list"][i]["processFile"]);
            dict["pcs_name_"+index] = i;
            dict["pcs_desc_"+index] = props.dataToEdit["process_list"][i]["processDescription"];
            let d = disabledProcess;
            d.push(props.dataToEdit["process_list"][i]["processFile"]);
            setDisabledProcess(d);
          }
          else{
            let p = ctxData;
            p.push(props.dataToEdit["process_list"][i]["processFile"]);
            setCtxData(p);
          }
        }
      }
    }
    dict["size"] = ctxData.length;
    form.setFieldsValue(dict);
    sendData();
    setFormData(dict);
  }, [ctxData, props.dataToEdit]);  // eslint-disable-line

  // Send data to parent
  const sendData = () => {
    props.parentCallback(formData, "form3");
  }

  // Handle the change of form input, uploading the state vars
  const handleFormChanges = () => {
    let formValues = form.getFieldsValue();
    ctxData.map((item,index) => {
      formValues["pcs_file_"+index] = item;
      return true;
    });
    formValues["size"] = ctxData.length;
    setFormData(formValues);
  }

  // Reset form fields after a ctxData change (if the files are changed)
  const resetFormFields = () => { // eslint-disable-line
    setFormData({});
  }

  // Define rule 1
  const rule1 = [
    { required: true, message: VAL_MSGS.PJW.S3.Name.empty},
    { min: 3, message: VAL_MSGS.PJW.S3.Name.short },
    { pattern: /^[0-9A-Za-z\-]+$/, message: VAL_MSGS.PJW.S3.Name.wrong_symbols },     //eslint-disable-line
    { pattern: /^(?![_\-]+$)/, message: VAL_MSGS.PJW.S3.Name.only_symbols },     //eslint-disable-line
    { whitespace: true, message: VAL_MSGS.PJW.S3.Name.only_spaces },
    () => ({
      validator(_:any, value:any) {
        if (value.includes("--") || value.substring(0,1) === "-"){
          // the name does't contain the character '--' because in backend are used to split the project and process
          return Promise.reject(new Error(VAL_MSGS.PJW.S3.Name.reserved_chars));
        }
        else if(valTool.checkDuplicates(value, form.getFieldsValue(), ctxData.length) === true) {
          // not unique name
          return Promise.reject(new Error(VAL_MSGS.PJW.S3.Name.unique_name));
        }
        else {
          // ok
          return Promise.resolve();
        }
      }
    })
  ];
  // Define rule 2
  const rule2 = [
    //{ pattern: /^[0-9A-Za-z\s\-\_]+$/, message: 'Must only contain letters, numbers, dashes, underscores, and spaces.' },   //eslint-disable-line
    //{ pattern: /^(?![_\-]+$)/, message: 'Cannot contain only symbols.' },   //eslint-disable-line
    { whitespace: true, message: VAL_MSGS.PJW.S3.Description.only_spaces },
  ];

  // Define the fields of the form (iterated by the number of process files on ctxData)
  let formFields=ctxData.map((item,index)=>{ 
    return(
      <Row key={"ff_"+index}>
        <Col span={24}>
          <Row justify="center" className="" style={{textAlign: "center"}}>
            <Col span={24}>
              <hr key={"hr_"+index} hidden={index === 0}></hr>
              <h4 key={"h_"+index} className="label-process-f3" >{item}</h4>
            </Col>
          </Row>
          <Row justify="start" className="mt-3">
            <Col span={24} lg={5}>
              <p>Process Name:</p>
            </Col>
            <Col span={24} lg={19}>
              <FormA.Item key={"fgpn_"+index} className="itf31" name={"pcs_name_"+index} rules={rule1} validateTrigger={[]}>
                <Input key={"fcpn_"+index} className="inf31" maxLength={60} name="pcs_name" type="text" disabled={disabledProcess.includes(item)}/>
              </FormA.Item>
            </Col>
          </Row>
          <Row justify="start" className="">
            <Col span={24} lg={5}>
              <p>Process Description:</p>
            </Col>
            <Col span={24} lg={19}>
              <FormA.Item key={"fgpd_"+index} className="itf32" name={"pcs_desc_"+index} rules={rule2} validateTrigger={[]}>
                <TextArea key={"fcpd_"+index} className="inf32" autoSize={{ minRows: 2, maxRows: 2 }} showCount maxLength={200} name="pcs_desc" rows={2}/>
              </FormA.Item>
            </Col>
          </Row>
        </Col>
      </Row>
    )
  });

  // Return
  return (
    <>
      <div className="form-ctn f-pj f-pj-3">
        <Row className="w-row-step-title" justify="center">
          <Col>
            <h3 className="form-step-title">Process Definition</h3>
          </Col>
        </Row>
        <Row className="w-row-form-body max-h" justify="center" align="middle">
          <Col span={22}>
            <FormA className="" ref={props.formRef} colon={false} form={form} onChange={(event) => handleFormChanges()}>
              {formFields}
            </FormA>
          </Col>
        </Row>
      </div>
    </>
  );
};

/**
 * Form 4: Collaborators of the project.
 *
 * @param {IProps} props
 * @return {*} 
 */
const FormNP4 = (props:IProps) => {

  // State Vars
  const [source, setSource] = useState([]); // eslint-disable-line
  const [targetKeys, setTargetKeys] = useState([]);
  const [selectedKeys, setSelectedKeys] = useState([]);

  // Event (when state is uploaded in defined variables)
  useEffect(() => {
    sendData();
  }, [targetKeys]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if(props.dataToEdit["edit_mode"]){
      for(let i = 0; i < props.uList.length; i++){
        if(props.dataToEdit["collaborators"].includes(props.uList[i].value)){
          if(props.uList[i].description === "user"){
            props.uList[i]["disabled"] = true;
          }
          let keys = targetKeys;
          keys.push(props.uList[i].key)
          setTargetKeys(keys);
          sendData();
        }
        else if(props.dataToEdit["owner"] === props.uList[i].value){
          props.uList[i]["disabled"] = true;
        }
      }
    }
    setSource(props.uList);
  }, [props.uList, props.dataToEdit]);  // eslint-disable-line

  // Send data to parent
  const sendData = () => {
    var formData = new Array<string>();

    // Find data with target keys
    targetKeys.map(item => (
      formData.push(props.uList[item].value)
    ));
    props.parentCallback(formData, "form4");
  }

  // Handle the change of form input, uploading the state vars
  const handleChange = (nextTargetKeys, direction, moveKeys) => {
    //console.log('targetKeys:', nextTargetKeys);
    //console.log('direction:', direction);
    //console.log('moveKeys:', moveKeys);
    setTargetKeys(nextTargetKeys);
  }
  
  // Filter the data 
  const filterOption = (inputValue, option) => option.description.indexOf(inputValue) > -1;
  
  // Handle the change of selected elements
  const onSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
    //console.log('sourceSelectedKeys:', sourceSelectedKeys);
    //console.log('targetSelectedKeys:', targetSelectedKeys);
    setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);
  };
  
  // Handle the scroll
  const onScroll = (direction, e) => {
    //console.log('direction:', direction);
    //console.log('target:', e.target);
  };
  
  // Return
  return (
    <>
      <div className="form-ctn f-pj f-pj-4">
        <Row className="w-row-step-title" justify="center">
          <Col>
            <h3 className="form-step-title">Collaborators</h3>
          </Col>
        </Row>
        <Row className="w-row-form-body" justify="center" align="middle">
          <Col span={22} className="max-w mt-4">
            <Transfer
              listStyle={{width: "100%", height: 300, padding: 10, borderRadius: 10}}
              dataSource={props.uList}
              titles={['Users', 'Collaborators']}
              showSearch
              filterOption={filterOption}
              targetKeys={targetKeys}
              selectedKeys={selectedKeys}
              onChange={handleChange}
              onSelectChange={onSelectChange}
              onScroll={onScroll}
              pagination
              render={item => item.title}
            />
          </Col>
        </Row>
      </div>
    </>
  );
};

/**
 * Form 5: Show the status of the project creation.
 *
 * @param {IProps} props
 * @return {*} 
 */
const FormNP5 = (props:IProps) => {

  const [status, setStatus] = useState("error");
  const [msg, setMsg] = useState("");

  const LoadingElement = <SettingOutlined spin style={{ color: '#0F4A6A' }}/>;
  
  let isLoading = props.buildStatusDB[0] || props.buildStatusS3[0];
  let error = props.buildStatusDB[1] || props.buildStatusS3[1];

  useEffect(() =>{
    let text = {"loading": "", "success": "", "error": "", "warning": ""};
    let msgType :msgsType;
    
    if(props.dataToEdit !== undefined && props.dataToEdit["edit_mode"]){
      msgType = "edit_project";
      text = {
        "loading": "Updating the project ...",
        "success": "Update of the project completed",
        "error": "Update failed!",
        "warning": "No update found"
      };
    }
    else{
      msgType = "create_project";
      text = {
        "loading": "Creating the project ...",
        "success": "Creation of the project completed",
        "error": "Creation failed!",
        "warning": ""
      };
    }    

    if(isLoading) {
      setStatus("error");
      setMsg(text.loading);
    }
    else if(error && props.buildStatusDB[2] !== "304") {
      setStatus("error");
      setMsg(text.error);
      openLocalNotification("error",msgType,props.buildStatusDB[2]);
    }
    else if(error) {
      setStatus("warning");
      setMsg(text.warning);
      openLocalNotification("warning",msgType,"500");
    }
    else {
      setStatus("success");
      setMsg(text.success);
      openLocalNotification("success",msgType,"");
    }

  }, [props.dataToEdit, props.buildStatusDB, props.buildStatusS3]);  // eslint-disable-line

  // DEBUG:
      //isLoading = true;
      //error = true;

  // Return
  return (
    <>
      <div className="form-ctn f-pj f-pj-5">
        <Row className="w-row-step-title" justify="center">
          <Col>
            <h3 className="form-step-title">Upload</h3>
          </Col>
        </Row>
        <Row className="w-row-form-body" justify="center" align="middle">
          <Col span={20}>
            <Result
                    status={status as any}
                    icon={isLoading ? LoadingElement : null}
                    title={<p className="done-msg-f5">{msg}</p>}
                    subTitle=""
                    className="done-img-f5"
            />
          </Col>
        </Row>
      </div>
    </>
  );
};

export { FormNP1, FormNP2, FormNP3, FormNP4, FormNP5};