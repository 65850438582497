/**
 * Rulex Project Manager
 *
 * --FormWProcess
 * 
 * This File contains three components, one for each step of the wizard.
 * - Memory and Cpu selector.
 * - Schedule definition and visualization. 
 * - Progressbar for the final step of the wizard.
 * 
 * @summary Contains three form components used inside the wizard
 * @author Riccardo Poli, Lorenzo Biasotti
 *
 */


import { useCallback, useEffect, useReducer, useRef, useState } from "react";
import {Form as FormA, Select, Input, Divider, Row, Progress, Timeline, Col, Switch, Button, Result,} from 'antd';
import 'antd/dist/antd.css';
import { Tab, ReCron } from "../../libraries/@sbzen/re-cron";
import { CheckCircleOutlined, CloseCircleOutlined, SettingOutlined, SyncOutlined } from "@ant-design/icons";
import * as cronjsMatcher from "@datasert/cronjs-matcher";
import { Container } from "reactstrap";
import VAL_MSGS from "../../files/validation-msgs.json";
import DEPLOY_STEPS from "../../files/deploy-steps.json";
import UPDATE_TASK_MESSAGES from "files/update-task-messages.json"

/**
 * Interface to define the form props 
 *
 * @interface IProps
 */
interface IProps {
  parentCallback(value:any, name:string):void;  // parent reference
  dataFromParent?:any;                         // data coming from parent
  dataToEdit?:any;                             
  formRef?: any;
}

/**
 * Form 1: Cpu and Memory of the process instance.
 * 
 * @param {IProps} props
 * @return {*} 
 */
const FormDP1 = (props:IProps) => {  

  // State Vars
  const stdState = {"pcs_cpu": -1, "pcs_memory": -1};
  const [formData, setFormData] = useState(stdState);
  // Form
  const [form] = FormA.useForm();
  // Form elements
  const { Option } = Select;

  let [optionsCpu, setOptionsCpu] = useState(props.dataFromParent["cpu"]);
  let [optionsMem, setOptionsMem] = useState(props.dataFromParent["mem"]);

  // Event (when state is uploaded in defined variables)
  useEffect(() => {
    sendData();
  }, [formData]); // eslint-disable-line react-hooks/exhaustive-deps

  // Event (when state is uploaded in defined variables)
  useEffect(() => {
    setOptionsCpu(props.dataFromParent["cpu"]);
    setOptionsMem(props.dataFromParent["mem"]);
  }, [props.dataFromParent]); // eslint-disable-line react-hooks/exhaustive-deps

  // Event (set the value of cpu, memory if editMode is true)
  useEffect(() => {
    if(props.dataToEdit["edit_mode"]){
      form.setFieldsValue({pcs_cpu: props.dataToEdit["cpu"], pcs_memory: props.dataToEdit["memory"]});
      setFormData({pcs_cpu: props.dataToEdit["cpu"], pcs_memory: props.dataToEdit["memory"]});
      setOptionsMem(props.dataFromParent["cpu_map"].get(props.dataToEdit["cpu"]));
      form.validateFields();
    }
  }, [props.dataToEdit]); //eslint-disable-line

  // Send data to parent
  const sendData = () => {
    props.parentCallback(formData, "form1");
  }

  // Handle the change of form input, uploading the state vars
  const handleFormChanges = (value:any, formName:string) => {

    //form.validateFields();

    if(formName === "pcs_cpu") {
      setOptionsCpu(props.dataFromParent["cpu"]);
      setOptionsMem(props.dataFromParent["cpu_map"].get(value));
    }
    setFormData(form.getFieldsValue());
  }

  // Define rule
  const rule = [
    { required: true, message: VAL_MSGS.PCSW.S1.empty},
    () => ({
      validator(_:any, value:any) {
        if( 
            (formData.pcs_cpu !== undefined)
              &&
            (formData.pcs_memory !== undefined)
              &&
            (formData.pcs_cpu !== -1)
              &&
            (formData.pcs_memory !== -1)
              &&
            (props.dataFromParent["cpu_map"].get(formData.pcs_cpu).includes(formData.pcs_memory))
              &&
            (props.dataFromParent["mem_map"].get(formData.pcs_memory).includes(formData.pcs_cpu)) 
          ) {
          return Promise.resolve();
        }
        else {
            if((formData.pcs_cpu === undefined) || (formData.pcs_memory === undefined) || 
                (formData.pcs_cpu === -1) || (formData.pcs_memory === -1)){
              return Promise.resolve();
            }
            else{
              return Promise.reject(new Error(VAL_MSGS.PCSW.S1.compatibility));
            }
        }
      },
    }),
  ];

  // Define CpuOptions
  var CpuOptions=optionsCpu.map((item:number,index:number) => {
    return (
      <Option key={"cpu_opt_"+index} value={item}>{item/1024+" vCPU"}</Option>
    )
  });

  // Define CpuOptions
  var MemoryOptions=optionsMem.map((item:number,index:number) => {
    return (
      <Option key={"cpu_opt_"+index} value={item}>{item+" GB"}</Option>
    )
  });

  // Return
  return (
    <>
      <div className="form-ctn f-pc f-pc-1">
        <Row className="w-row-step-title" justify="center">
          <Col>
            <h4 className="form-step-title">Cpu and Memory</h4>
          </Col>
        </Row>
        <Row className="w-row-form-body-pr mt-5" justify="center" align="middle">
          <Col span={14}>
            <FormA className="" colon={false} initialValues={{"pcs_cpu": optionsCpu[0], "pcs_memory": optionsMem[0]}} form={form} ref={props.formRef}>
              <Row justify="start" className="mt-5">
                <Col span={24} md={6}>
                  <p>Cpu :</p>
                </Col>
                <Col span={24} md={18}>
                  <FormA.Item className="itf11-pr" name="pcs_cpu" rules={rule} validateTrigger={[]}>
                    <Select
                      className="inf11-pr"
                      onChange={ (value) => handleFormChanges(value, "pcs_cpu")}
                      showSearch
                      placeholder="Search..."
                      optionFilterProp="children"
                      filterOption={(input, option) => 
                        option.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      filterSort={(optionA, optionB) =>
                        optionA.children.toString().toLowerCase().localeCompare(optionB.children.toString().toLowerCase())
                      }
                    >
                      {CpuOptions}
                    </Select>
                  </FormA.Item>
                </Col>
              </Row>
              <Row justify="start" className="mt-4">
                <Col span={24} md={6}>
                  <p>Memory :</p>
                </Col>
                <Col span={24} md={18}>
                  <FormA.Item className="itf12-pr" name="pcs_memory" rules={rule} validateTrigger={[]}>
                    <Select
                      className="inf12-pr"
                      onChange={ (value) => handleFormChanges(value, "pcs_memory")}
                      showSearch
                      placeholder="Search..."
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      filterSort={(optionA, optionB) =>
                        optionA.children.toString().toLowerCase().localeCompare(optionB.children.toString().toLowerCase())
                      }
                    >
                      {MemoryOptions}
                    </Select>
                  </FormA.Item>
                </Col>
              </Row>
            </FormA>
          </Col>
        </Row>
      </div>
    </>
  );
};

/**
 * Form 2: Scheduling of the process.
 * 
 * @param {IProps} props
 * @return {*} 
 */
 const FormDP2 = (props:IProps) => {

  const defaultValue = {"scheduled": false, "cron": ""};

  //set minimum scheduling interval (in milliseconds)
  const MIN_SCHEDULING_INTERVAL = 3600000; // 1 hour

  // State Vars
  const [formData, setFormData] = useState(defaultValue);
  const [switchV, setSwitchV] = useState(false);
  const [nextDatesUTC, setNextDatesUTC] = useState([]);
  const [nextDates, setNextDates] = useState([]);
  const [showDates, setShowDates] = useState(false);
  const [error, setError] = useState(true);
  const [textButtonDates, setTextButtonDates] = useState("Next Execution Dates");
  const [cronValue, setCronValue] = useState("");
  // Form
  const [form] = FormA.useForm();
  // Ref
  const inputRef = useRef<Input>(null);

  useEffect(() => {
    if(props.dataToEdit["edit_mode"] && props.dataToEdit["scheduled"] === "yes"){
      let scheduledExpression = props.dataToEdit["scheduled_expression"].substring(5);
      scheduledExpression = "0 " + scheduledExpression.split(")")[0];
      setSwitchV(true);
      setFormData({scheduled: true, cron: scheduledExpression});
      inputRef.current?.setValue(scheduledExpression);
      setCronValue(scheduledExpression);
      form.validateFields();
    }
  }, [props.dataToEdit]); //eslint-disable-line

  // Event (when state is uploaded in defined variables)
  useEffect(() => {
    sendData();
  }, [formData]); // eslint-disable-line react-hooks/exhaustive-deps

  // Send data to parent
  const sendData = () => {
    if(!switchV) {
      props.parentCallback(defaultValue, "form2");
    }
    else {
      props.parentCallback(formData, "form2");
    }   
  }

  const onChange = (checked) => {
    setSwitchV(checked);
    if(checked === false) {
      if(showDates)
        showNextDates();
      setFormData(defaultValue);
      form.validateFields();
    }
    else if(checked === true) {
      setFormData({"scheduled": true, "cron": ""});
      inputRef.current?.setValue("");
      form.validateFields();
    }
  }

  const customSetValue = useCallback(
    (newValue: string) => {
      setFormData({"scheduled": true, "cron": newValue});
      inputRef.current?.setValue(newValue);
      setCronValue(newValue);
      form.validateFields();
    },
    [inputRef] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const handleInputChange = (newValue) => {
    setFormData({"scheduled": true, "cron": newValue});
    form.validateFields();
  }

  const showNextDates = () => {
    if(showDates){
      setShowDates(false);
      setTextButtonDates("Next Execution Dates");
    }
    else{
      setShowDates(true);
      setTextButtonDates("Hide Next Execution Dates");
    }
  }

  // Define rule
  const rule = [
    () => ({
      validator(_:any, value:any) {
        setError(true);
        if(formData.scheduled === false) {
          return Promise.resolve();
        }
        else if (formData.cron === "") {
          if(showDates)
            showNextDates();
          return Promise.reject(new Error(VAL_MSGS.PCSW.S2.empty));
        }
        else {
          try {
            if(formData.cron.substr(2).split(" ").length < 6){
              throw VAL_MSGS.PCSW.S2.wrong;
            }
            let dates = cronjsMatcher.getFutureMatches(formData.cron.substr(2), {matchCount: 10});
            if(dates.length === 0) {
              throw VAL_MSGS.PCSW.S2.wrong;
            }
            let datesUTC = [];
            let d : any;
            let dateString = "";
            let dateStringUTC = "";
            let day1 = new Date(dates[0]);
            let day2 = new Date(dates[1]);
            
            for(let i = 0; i < dates.length; i++){
              //let p = d.toLocaleDateString(undefined, { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric'});
              d =  new Date(dates[i]);
              dateString = ("0" + d.getDate()).slice(-2) + "-" + ("0"+(d.getMonth()+1)).slice(-2) + "-" + d.getFullYear() + " " + ("0" + d.getHours()).slice(-2) + ":" + ("0" + d.getMinutes()).slice(-2);
              dates[i] = dateString;
              dateStringUTC = ("0" + d.getUTCDate()).slice(-2) + "-" + ("0"+(d.getUTCMonth()+1)).slice(-2) + "-" + d.getUTCFullYear() + " " + ("0" + d.getUTCHours()).slice(-2) + ":" + ("0" + d.getUTCMinutes()).slice(-2);
              datesUTC.push(dateStringUTC);
            }
            
            setNextDatesUTC(datesUTC);
            setNextDates(dates);

            if(day2.getTime()-day1.getTime() < MIN_SCHEDULING_INTERVAL){
              throw VAL_MSGS.PCSW.S2.short_time;
            }
  
            setError(false);
            return Promise.resolve();
          } 
          catch(error) {
            if(showDates)
              showNextDates();
            if(error === VAL_MSGS.PCSW.S2.short_time){
              return Promise.reject(error);
            }
            return Promise.reject(new Error(VAL_MSGS.PCSW.S2.wrong));
          }
        }     
      },
    }),
  ];

  // Return
  return (
    <>
      <div className="form-ctn f-pc f-pc-2">
        <Row className="w-row-step-title" justify="center">
          <Col>
            <h4 className="form-step-title mb-0">Schedule</h4>
          </Col>
        </Row>
        <Row className="w-row-form-body-pr" justify="center" align="middle">
          <Col span={22}>
            <Row justify="center">
              <Col span={24} style={{textAlign: "center"}}>
                <FormA form={form} ref={props.formRef}>
                  <FormA.Item name="pcs_cron" rules={rule} validateTrigger={[]}>  
                    <span> 
                      <Input className="fake_input" value="" bordered={false}/>
                      <Row justify="center">
                        <Col>
                          <Switch checked={switchV} onChange={onChange} />
                        </Col>
                      </Row> 
                    </span>
                  </FormA.Item> 
                </FormA>
              </Col>
            </Row>
            <Divider style={{marginTop: "5px"}}>Set Cron Schedule: </Divider>
            <Row gutter={16} >
              <Col flex="auto">
                <Input
                  disabled = {!switchV}
                  ref={inputRef}
                  onChange={(event) => {
                    handleInputChange(event.target.value);
                  }}
                />
              </Col>
              <Col flex="10%">
                  <Button disabled = {!switchV || error} onClick={(event) => {showNextDates();}}>{textButtonDates}</Button>
              </Col>
            </Row>
            <Row justify={"center"}>
              <Col span={24}>
                <div hidden = {!switchV || showDates} className="form-body-schedule-1">
                  <Row justify={"center"} style={{alignContent: "center"}}>
                      <Col span={24}>
                        <Container>
                          <ReCron
                              value={cronValue}
                              tabs={[Tab.MINUTES, Tab.HOURS, Tab.DAY, Tab.MONTH, Tab.YEAR]}
                              onChange={customSetValue} 
                          />
                        </Container>
                      </Col>
                  </Row>
                </div>
              </Col>
            </Row>
            <Row justify={"center"}>
              <Col span={24}>
                <div hidden = {!showDates || !switchV} className="form-body-schedule-2">
                  <Row justify={"center"}>
                    <Col span={12}>
                      <p>UTC:</p>
                      <ul>
                        {nextDatesUTC.map((txt, index) => <li key={index}>{txt}</li>)}
                      </ul>
                    </Col>
                    <Col span={12}>
                      <p>Local Time:</p>
                      <ul>
                        {nextDates.map((txt, index) => <li key={index}>{txt}</li>)}
                      </ul>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </>
  );
};

/**
 * Form 5: Show the status of the project creation.
 *
 * @param {IProps} props
 * @return {*} 
 */
const FormDP3 = (props:IProps) => {
  
  const [progress, setProgress] = useState(0);
  const [tlMsgs, setTlMsgs] = useState(new Array<string>());
  const [tlStatus, setTlStatus] = useState(new Array<boolean>());
  const [pending, setPending] = useState([]);
  const [prStatus, setPrStatus] = useState("active" as "active" | "exception" | "success" | "normal");
  const [msgResource, setMsgResouce] = useState(UPDATE_TASK_MESSAGES.start);
  const [updating, setUpdating] = useState(true);
  const [success, setSuccess] = useState(true); // eslint-disable-line
  const [status, setStatus] = useState("error");


  // Updater
  const [ignored, forceUpdate] = useReducer(x => x + 1, 0);       //eslint-disable-line

  // Event (after the first render (only))
  useEffect(() => {
    setPending([DEPLOY_STEPS.Deploy]);

    if(props.dataFromParent["msg"] !== "") {
      if(props.dataFromParent["msg"] === "-start-") {
        setTlMsgs([]);
        setTlStatus([]);
        setPrStatus("active");
      }
      tlMsgs.push(props.dataFromParent["msg"]);
      tlStatus.push(props.dataFromParent["status"]);
      if(props.dataFromParent["completed"] === true) {
        setPending([false]);
        var errors = false as boolean;
        tlStatus.map((item:boolean,index:number) => {
          if(item === false) {
            errors = true;
            return false;
          }
          return true;
        });
        if(errors === true) {
          setPrStatus("exception");
        }
        else {
          setPrStatus("success");
        }
      }
      forceUpdate();
    }   
  }, [props.dataFromParent]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if(!props.dataToEdit["finished"]){
      setUpdating(true);
      if(props.dataToEdit["msg"] === "finished_db"){
        setMsgResouce(UPDATE_TASK_MESSAGES.finished_db);
      }
      else if(props.dataToEdit["msg"] === "TaskDefinition"){
        setMsgResouce(UPDATE_TASK_MESSAGES.Task_definition);
      }
      else if(props.dataToEdit["msg"] === "Rule"){
        setMsgResouce(UPDATE_TASK_MESSAGES.Rule);
      }
    }
    else if(props.dataToEdit["msg"] === "success"){
      setUpdating(false);
      setSuccess(true);
      setStatus("success");
      setMsgResouce(UPDATE_TASK_MESSAGES["success"]);
    }
    else if(props.dataToEdit["msg"] === "error"){
      setUpdating(false);
      setSuccess(false);
      setStatus("error");
      setMsgResouce(UPDATE_TASK_MESSAGES["error"]);
    }
    else if(props.dataToEdit["msg"] === "warning"){
      setUpdating(false);
      setSuccess(false);
      setStatus("warning");
      setMsgResouce(UPDATE_TASK_MESSAGES["warning"]);
    }
    forceUpdate();

  }, [props.dataToEdit]);


  // Progress
  
  const increase = () => { //eslint-disable-line
    let percent = progress + 10;
    if (percent > 100) {
      percent = 100;
    }
    setProgress(percent);
  };

  const decline = () => { //eslint-disable-line
    let percent = progress - 10;
    if (percent < 0) {
      percent = 0;
    }
    setProgress(percent);
  };

  // Define Timeline item
  var TimelineMsgs=tlMsgs.map((item:string,index:number) => {
    
    var style = {};
    
    if(index !== tlMsgs.length-1) {
        if(tlStatus[index+1] === true)
          style = {dot: <CheckCircleOutlined />, color: "green"};
        else 
          style = {dot: <CloseCircleOutlined />, color: "red"};
    }
    else {
      
      if(props.dataFromParent["completed"]) {
        style = {dot: <CheckCircleOutlined />, color: "green"};
        tlStatus.map((item:boolean,index:number) => {
          if(item === false) {
            style = {dot: <CloseCircleOutlined />, color: "red"};
            return false;
          }
          return true;
        });
      }
      else {
        style = {dot: <SyncOutlined spin />, color: "blue"};
      }   
    }
    
    return (
      <Timeline.Item 
        dot={style["dot"]}
        color={style["color"]} 
        key={"tl_"+index}>{item}
      </Timeline.Item>
    )
  });

  const LoadingElement = <SettingOutlined spin style={{ color: '#0F4A6A' }}/>;

  
  // Return
  return (
    <>
      <div className="form-ctn f-pc f-pc-3">
        <div hidden={props.dataToEdit["edit_mode"]}>
          <Row className="w-row-step-title" justify="center">
            <Col>
              <h4 className="form-step-title">Deploy</h4>
            </Col>
          </Row>
          <Row className="w-row-form-body-pr mt-2" justify="center" align="middle">
            <Col span={20}>
              <Row className="row-st3 row-st3-progress" justify="center">
                <Col span={24}>
                  <Progress percent={props.dataFromParent["value"]} status={prStatus} />
                </Col>
              </Row>
              <Row className="row-st3 row-st3-timeline">
                <Col span={24}>
                  <Timeline pending={pending[0]} >
                    {TimelineMsgs}
                  </Timeline>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
        <div hidden={!props.dataToEdit["edit_mode"]}>
          <Row className="w-row-step-title" justify="center">
            <Col>
              <h3 className="form-step-title">Update</h3>
            </Col>
          </Row>
          <Row className="w-row-form-body" justify="center" align="middle">
            <Col span={20}>
              <Result
                      status={status as any}
                      icon={updating ? LoadingElement : null}
                      title={<p className="done-msg-f5">{msgResource}</p>}
                      subTitle=""
                      className="done-img-f5"
              />
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export { FormDP1, FormDP2, FormDP3 };