/**
 * Rulex Project Manager
 *
 * --user-pool-config
 * 
 * @summary Definition of user pool variables.
 * @author Riccardo Poli, Lorenzo Biasotti
 *
 */

import { CognitoUserPool } from "amazon-cognito-identity-js";
import RPMEnvManager from "classes/RPMEnvManager";

/** 
 * Setup pool id
 * @type {*} 
 * 
 */
var userPoolConfig = RPMEnvManager.getUserPoolConfig()

const poolData = {
    "UserPoolId": userPoolConfig.user_pool_ID,
    "ClientId": userPoolConfig.app_client_ID
}

export default new CognitoUserPool(poolData);