/**
 * Rulex Project Manager
 *
 * AdminNavbar
 * 
 * @summary Contains the navbar component used in the admin pages (top horizontal navbar).
 * @author Creative Tim (www.creative-tim.com)
 * @edited_by Riccardo Poli, Lorenzo Biasotti
 *
 */

import { Link } from "react-router-dom";
import { DropdownMenu, DropdownItem, UncontrolledDropdown, DropdownToggle, Navbar, Nav, Container, Media} from "reactstrap";
import { useContext, useEffect, useState } from "react";
import { AccountContext } from "../../contexts/AccountContext";
import { Avatar } from "antd";

/**
 * Navbar of the Admin pages
 *
 * @param {*} props
 * @return {*} 
 */
const AdminNavbar = (props) => {
  const { logout, getSession } = useContext(AccountContext);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [color, setColor] = useState("#D5896F");

  // Colors of the user dot
  const COLORS = ["#D5896F", "#70A288", "#DAB785","#A6EBC9","#6DAEDB","#FF595E", "#FFD447"];

  function getRandomInt(max) {
    return Math.floor(Math.random() * max);
  }

  useEffect(() => {
    getSession().then((session: { idToken: any; }) => {
      setColor(COLORS[getRandomInt(7)]);
      let email = session.idToken.payload.email;
      if (email.split("@")[0].includes(".")) {
        let label = email.substring(0, 1) + email.split(".")[1].substring(0,1);
        setName(label.toUpperCase()); 
      }
      else {
        setName(email.substring(0, 2).toUpperCase()); 
      }
      setEmail(email.split("@")[0]);
    });
  }, []); // eslint-disable-line

  return (    
    <>
      <Navbar className="navbar-top navbar-dark" expand="md" id="navbar-main">
        <Container fluid>
          <Link
            className="h4 mb-0 text-white text-uppercase d-none d-lg-inline-block"
            to={{}}
          >
            {props.brandText}
          </Link>
          <Nav className="align-items-center d-none d-md-flex" navbar>
            <UncontrolledDropdown nav>
              <DropdownToggle className="pr-0" nav>
                <Media className="align-items-center">
                  <Media className="mr-3 d-none d-lg-block">
                    <span className="mb-0 text-sm font-weight-bold" style={{ color: "white"}}>
                      {email}
                    </span>
                  </Media>
                  <span className="">
                    <Avatar style={{ border: "", backgroundColor: color,  verticalAlign: "middle"}} size="large">{name}</Avatar>
                  </span>
                </Media>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-arrow" right>
                <DropdownItem className="noti-title" style={{textAlign: "center", fontWeight: "bold"}} header tag="div">
                  <h6 className="text-overflow m-0">- RPM -</h6>
                </DropdownItem>
                <DropdownItem to="/admin/user-profile" disabled tag={Link}>
                  <i className="ni ni-single-02" />
                  <span>My profile</span>
                </DropdownItem>
                <DropdownItem to="/admin/user-profile" disabled tag={Link}>
                  <i className="ni ni-settings-gear-65" />
                  <span>Settings</span>
                </DropdownItem>
                <DropdownItem to="/admin/user-profile" disabled tag={Link}>
                  <i className="ni ni-calendar-grid-58" />
                  <span>Activity</span>
                </DropdownItem>
                <DropdownItem to="/admin/user-profile" disabled tag={Link}>
                  <i className="ni ni-support-16" />
                  <span>Support</span>
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem onClick={logout}>
                  <i className="ni ni-user-run" />
                  <span>Logout</span>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </Container>
      </Navbar>
    </>
  );
};

export default AdminNavbar;

/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/