/**
 * Rulex Project Manager
 *
 * --Notification
 * 
 * This Component define the notifications used to alert the user inside the app
 * The notifications are activated on response to an event (websocket msg or frontend action)
 * 
 * @summary Define the notification component to be used inside the app.
 * @author Riccardo Poli, Lorenzo Biasotti
 *
 */

import { SettingOutlined } from "@ant-design/icons";
import { Col, notification, Result, Row } from "antd";
import RPMMsgParser from "classes/RPMMsgParser";
import { EventContext } from "contexts/EventContext";
import { SettingsContext } from "contexts/SettingsContext";
import { WebSocketContext } from "contexts/WebSocketContext";
import { useContext, useEffect } from "react";
import { RPMEventType } from "types/RPMEventType";
import NOT_MSGS from "../../files/notification-msgs.json";

/**
 * Interface to define the form props
 *
 * @interface NotificationProps
 */
interface NotificationProps {
  // empty
}

/**
 * Notification manager.
 * Listen to Events and Websocket Msgs
 * Display notify on the upper right of the screen.
 * 
 * @param props
 * @returns 
 */
const Notification = (props:NotificationProps) => {  

    // Context
    const { onEvent, getEvent, newEvent, name } = useContext(EventContext);     // eslint-disable-line
    const { messageDeployProcess, messageEditProcess, messageCloneProcess, messageRunTask } = useContext(WebSocketContext);
    const { getSettings } = useContext(SettingsContext);

    // Effect on msgs
    useEffect(() => {
        if (messageDeployProcess !== "") {
          handleMsgs(messageDeployProcess, "deploy_process");
        }
    }, [messageDeployProcess]); // eslint-disable-line react-hooks/exhaustive-deps
    useEffect(() => {
        if (messageEditProcess !== "") {
          handleMsgs(messageEditProcess, "edit_process");
        }
    }, [messageEditProcess]); // eslint-disable-line react-hooks/exhaustive-deps
    useEffect(() => {
        if (messageCloneProcess !== "") {
          handleMsgs(messageCloneProcess, "clone_process");
        }
    }, [messageCloneProcess]); // eslint-disable-line react-hooks/exhaustive-deps
    useEffect(() => {
        if (messageRunTask !== "") {
          handleMsgs(messageRunTask, "run_task");
        }
    }, [messageRunTask]); // eslint-disable-line react-hooks/exhaustive-deps

    // Handle msg incoming from websocket
    const handleMsgs = (msg:string, msgType:msgsType) => {

        let show = true;
        let loading = true;
        let error = false;
        let msgParser = new RPMMsgParser(msgType);
        let msgData = msgParser.getMsgData(msg);

        // detect msg type, collect data.
        switch (msgType) {
            case "deploy_process":
                if(msgData["ignore"])
                    break;
                loading = msgData["index"] < 7 && msgData["index"] !== -1;     
                error = msgData["status"] === false;
                if(loading === false) {
                    newEvent(RPMEventType.END_DEPLOY_PROCESS);
                }
                break;
            case "edit_process":
                loading = msgData["finished"] === false;
                error = msgData["msg"] === "error";
                if(loading === false) {
                    newEvent(RPMEventType.END_EDIT_PROCESS);
                }
                break;
            case "clone_process":
                if(msgData["ignore"])
                    break;
                loading = msgData["index"] < 7 && msgData["index"] !== -1;
                error = msgData["status"] === false;
                if(loading === false) {
                    newEvent(RPMEventType.END_CLONE_PRODUCTION);
                }
                break;    
            case "run_task":
                loading = msgData["status"] !== "finished" && msgData["status"] !== "error";
                error = msgData["status"] === "error";
                
                // checking if it is not-interesting process notification
                let processTag = msgData["project"]+"||"+msgData["process"];
                if(getSettings()["user_process"].includes(processTag) === false) {
                    show = false;
                }
                if(loading === false) {
                    newEvent(RPMEventType.END_RUN_TASK);
                }
                break;  
            default:
                break;
        }

        // Display notify if show is true
        if(show === true) {
            // Display notify if procedure has ended (not loading)
            if(loading === false) {
                let prc = {"project": "", "process": msgData["process"], "stage": ""};
                let status = error === true ? "error" : "success" as actionStatusType;
                openNotification(status, msgType, 60, "500", false, prc);
            }  
        }
    } 

    // Return
    return ( 
        <>
        </>
    );
};

/**
     * Display the notification on the upper right of the screen
     *
     * @param {actionStatusType} status   -> status of the action
     * @param {msgsType}         type     -> type of the action
     * @param {boolean}          showData -> whether to show additional data
     * @param {actionDataType}   data     -> additional data
     * @param {number}           duration -> duration of the notify
     */
 const openNotification = (status:actionStatusType, type:msgsType, duration:number=20, errorType:string="500", showData:boolean=false, data:actionDataType={}) => {

    const LoadingElement = <SettingOutlined spin style={{ color: '#0F4A6A' }}/>;
    const note = notification;
    const key = '';

    let loading = status === "loading";
    let text = "";
    if (status === "error") {
        text = NOT_MSGS[type][status][errorType];
    }
    else {
        text = NOT_MSGS[type][status];
    }
     
    let textData = "";

    // Select additional data to show:
    if(showData) {
        textData = data["process"];
    }

    note.open({
        key,
        message: <p className="not-msg">Notification</p>,
        description:
            <>
                <div id="notification-body">
                    <Row className="not-rpm" justify="center" align="middle">
                        <Col span={10}>
                            <Result
                                status={status as any}
                                icon={loading ? LoadingElement : null}          
                                subTitle=""
                                className="done-img-notification"
                            />
                        </Col>
                        <Col span={14}>
                            <p className="process-msg-not" hidden={!showData}>{textData}</p>
                            <p className="done-msg-not" hidden={loading}>{text}</p>
                            <p className="loading-msg-not" hidden={!loading}>{text}</p>
                        </Col>
                    </Row>
                </div>
            </>,
        duration: duration,
        onClick: () => {
            // console.log('Notification Clicked!');
        },
    });
};

const openLocalNotification = (status:actionStatusType, type:msgsType, errorType:string="500", duration:number=5) => {
    
    openNotification(status,type,duration,errorType);
}

export {Notification, openLocalNotification};