/**
 * Rulex Project Manager
 *
 * --RPMFileType
 * 
 * @summary This file contains the File Type Enum definitions
 * @author Riccardo Poli, Lorenzo Biasotti
 *
 */

/**
 * Define the enum for the file types:
 * - process
 * - module
 * - input files
 *
 * @export
 * @enum {number}
 */
export enum RPMFileType {
    UNDEFINED = "uknown",
    PROCESS = "process",
    MODULE = "module",
    INPUT_FILE = "input_file",
}