/**
 * Rulex Project Manager
 *
 * --WebSocketContext
 * 
 * @summary Context used to manage WebSocket communications
 * @author Riccardo Poli, Lorenzo Biasotti
 *
 */

import RPMEnvManager from "classes/RPMEnvManager";
import React, { useEffect, useState } from "react";

// COntext default values
const contextDefaultValues: WebSocketContextState = {
  messageDeployProcess: "",
  messageEditProcess: "",
  messageCloneProcess: "",
  messageRunTask: "",
  checkStatusStack(process:string, project:string){},
  disconnect(){}
};

/*
*   THIS CONTEXT MANAGE WEBSOCKET MSGS.
*   
*/

// defining the context 
const WebSocketContext = React.createContext<WebSocketContextState>(contextDefaultValues);

// This context provider is passed to any component requiring the context
const WebSocketComponent = (props) => {
  
  // State var
  const [messageDeployProcess, setMessagesDeployProcess] = useState("");
  const [messageEditProcess, setMessagesEditProcess] = useState("");
  const [messageCloneProcess, setMessagesCloneProcess] = useState("");
  const [messageRunTask, setMessagesRunTask] = useState("");
  const [webSocket, setWebSocket] = useState(undefined as WebSocket); 

  // Event (after the first render (only))
  useEffect(() => {
    setupWebSocket();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  
  // Turn on web socket
  const setupWebSocket = () => {

    let newWS = new WebSocket(RPMEnvManager.getApiWebSocket());

    newWS.onerror = () => {};
    
    newWS.onopen = () => {
      //console.log('websocket connected')
    };
    
    newWS.onmessage = (event) => {

      switch(event.data.split("|")[0]) {

        case "deploy_process": 
          //console.log("deploy -- " + event.data);
          setMessagesDeployProcess(event.data);
          break;
        case "edit_process": 
          //console.log("edit -- " + event.data);
          setMessagesEditProcess(event.data);
          break;
        case "clone_process": 
          //console.log("clone -- " + event.data);
          setMessagesCloneProcess(event.data);
          break;
        case "run_task": 
          //console.log("run -- " + event.data);
          setMessagesRunTask(event.data);
          break;
      }
    };
    
    // On close web socket
    newWS.onclose = function(event) {
      //console.log("WebSocket is closing now...");
      setTimeout(setupWebSocket, 1000); // Restart websocket after 1 sec
    };

    setWebSocket(newWS);
  }

  // Check status (open listener)
  const checkStatusStack = (process:string, project:string) => {

    let toSend = JSON.stringify({"action": "monitorStack", "process": process, "project": project});
    webSocket.send(toSend);
  }

  // Disconnect from websocket
  const disconnect = () =>{
    webSocket.close();
  }

  return (
    <WebSocketContext.Provider
      value={{ checkStatusStack, messageDeployProcess, messageEditProcess, messageCloneProcess, messageRunTask, disconnect }}
    >
      {props.children}
    </WebSocketContext.Provider>
  );

};

export { WebSocketComponent, WebSocketContext };