/**
 * Rulex Project Manager
 *
 * --RPMFilesTreeBuilder
 * 
 * @summary Contains the class RPMFilesTreeBuilder to build the ANTD tree data-structure.
 * @author Riccardo Poli, Lorenzo Biasotti
 *
 */

import FILE_STRUCTURE from "../files/file-structure.json";

/**
 * Class to create tree structure from data of files
 * ATM the class is able to represent only structures with max 1 subfolder
 * The structure is taken from file_structure.json in files folder
 * The tree structure is used to represent the tree of files with antd component
 * 
 * @class RPMFileTreeBuilder
 */
class RPMFilesTreeBuilder {
 
  // Data of the tree
  private data :any;

  // Keys of the tree
  private keys :any;

  // Error in the procedure
  private error :boolean;

  // files data
  private files = { "processes": [], "modules": [], "input_files": []}
  
  // Keys of the tree
  private key = {"key1": "", "key2": {}};

  // Children Levels members
  private L1_Child = [];
  private L2_Child = {};

  // Name of the 
  private FOLDER_NAME :string;

  constructor(processList:[], subProcessList:[], inputFileList:[]) {

    this.files = {
      "processes": processList,
      "modules": subProcessList,
      "input_files": inputFileList,
    }

    this.FOLDER_NAME = "Project Folder";
    this.error = false;

    this.getStructure();
    if( ! this.error ) {
      this.buildTree();
    }
  }

  // Get the structure and define keys
  private getStructure = () => {

    // Folders used
    let folders = [];

    // keys of each level
    let key1 = "0-0";
    let key2 = {};

    // support index
    let ix = 0;
    
    // For each filetype define what are the folders used in the structure and what are the children of level 1
    for(let filetype in FILE_STRUCTURE) {
            
      // Get the path from the structure
      let folderList = FILE_STRUCTURE[filetype]["path"].split("/").slice(1,-1);

      // add to L1_Child the elements that are there in the structure 
      if(folderList.length === 0) { // add file types
        this.L1_Child.push(filetype);
      }
      else if (folderList.length === 1) { // add folders ( _ notation for the folders )
          if(! this.L1_Child.includes("_"+folderList[0])) { 
            this.L1_Child.push("_"+folderList[0]);
            folders.push("_"+folderList[0]);
          }   
      }
      else { // cannot build a tree with more than 1 subfolder atm
          this.error = true;
      }
    }

    // Building keys-2
    ix = 0;
    this.L1_Child.forEach((child) => {
        if(child[0] !== "_") {
            key2[child] = [];
            let i = 0;
            for(let el in this.files[child]) {  // eslint-disable-line
                let iz = ix + i;
                key2[child].push(key1 + "-" + iz);
                i++;
            }
            ix = ix + i -1;
        }
        else {
            key2[child] = (key1 + "-" + ix);
        } 
        ix++;

        this.key = {
          "key1": key1,
          "key2": key2
        }
    });

    // Setting the children of the second level
    folders.forEach((f, i) => {

      this.L2_Child[f] = [];

      for(let filetype in FILE_STRUCTURE) {
          if(f === "_" + FILE_STRUCTURE[filetype]["path"].split("/").slice(1,-1)[0]) {
            this.L2_Child[f].push(filetype);
          }
      }
    });
  }

  // Build the tree
  public buildTree = () => {

    let data = [];       // final data for the tree
    let keys = [];       // keys used on the tree
    let L1_Nodes = [];   // first level nodes
    let L2_Nodes = {};   // second level nodes
    let ix = 0;          // support index 
  
    // Setting node2 as a map-array
    for(let child in this.L2_Child) {
      L2_Nodes[child] = [];
    }

    // Filling nodes of 2nd level
    ix = 0;
    for(let folder in this.L2_Child) {
      this.L2_Child[folder].forEach((child) => {  // eslint-disable-line
            for(let el in this.files[child]) { 
                let k = this.key.key2[folder] + '-' + ix;
                L2_Nodes[folder].push({ title: this.files[child][el], key: k, isLeaf: true });
                keys.push(k);
                ix++;
            }
        });
    }
        
    // Filling nodes for the 1st level
    this.L1_Child.forEach((child, ix) => {
      if(child[0] !== "_") { // Files
          let k = this.key.key2[child];
          let i = 0;
          for(let el in this.files[child]) {
              L1_Nodes.push({ title: this.files[child][el], key: k[i], isLeaf: true });
              keys.push(k[i]);
              i++;
          }
      }
      else {  // Folders
          let k = this.key.key2[child];
          L1_Nodes.push({ title: child.slice(1), key: k, children: L2_Nodes[child] });    
          keys.push(k);
      }

    });
    
    data.push({
      "title": this.FOLDER_NAME,
      "key": this.key.key1,
      "children": L1_Nodes, 
    });
    keys.push(this.key.key1);
    
    this.data = data;
    this.keys = keys;
  }

  // Return the tree
  getTree() {
    return {"data": this.data, "keys": this.keys, "error": this.error};
  }
}
 
export default RPMFilesTreeBuilder;