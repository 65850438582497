/**
 * Rulex Project Manager
 *
 * --EventContext
 * 
 * @summary Context used to manage Events (queue, listeners,...)
 * @author Riccardo Poli, Lorenzo Biasotti
 *
 */

import React, { useState } from "react";
import { RPMEventType } from "types/RPMEventType";

/**
 * Context default values 
 * @type {*} 
 * */
const contextDefaultValues: EventContextState = {
  onEvent: RPMEventType.STARTING_RPM,
  getEvent(index = -1){},
  newEvent(newEvent:RPMEventType){},
  name: (event) => ""
};

/*
*   THIS CONTEXT MANAGE EVENTS DEFINED IN RPMEventTYpe.
*   Events are disposed as a queue.
*   
*/

// defining the context 
const EventContext = React.createContext<EventContextState>(contextDefaultValues);

// This context provider is passed to any component requiring the context
const EventComponent = (props) => {

  // State var
  const [onEvent, setEvent] = useState(RPMEventType.STARTING_RPM);
  const [eventQueue, setEventQueue] = useState([RPMEventType.STARTING_RPM]);

  // Add an event
  const newEvent = (newEvent:RPMEventType) => {
    addToEventQueue(newEvent);
    setEvent(newEvent);
  }

  // Get an event (deafult last)
  const getEvent = (index = -1):RPMEventType => {
    let ev = eventQueue.slice(index)[0];
    setEvent(RPMEventType.IDLE);
    return ev;
  }

  // Get event name
  const name = (event:RPMEventType):string => {
    return RPMEventType[event];
  }

  // Manage add to queue
  const addToEventQueue = (newEvent:RPMEventType) => {
    
    let eQ = eventQueue;
    if(eQ.length > 5) {
      eQ = eventQueue.slice(2,-1);
      eQ[0] = RPMEventType.IDLE;
      eQ.push(newEvent);
    }
    else {
      eQ.push(newEvent);
    }
    setEventQueue(eQ);
  }

  return (
    <EventContext.Provider
      value={{ onEvent, getEvent, newEvent, name }}
    >
      {props.children}
    </EventContext.Provider>
  );

};

export { EventComponent, EventContext };