/**
 * Rulex Project Manager
 *
 * --ProcessWizard
 * 
 * This file define the wizard and steps components
 * - Wizard component
 * - 3 Steps class-components
 * 
 * @summary Define the components of the process wizard.
 * @author Riccardo Poli, Lorenzo Biasotti
 *
 */

import React, { createRef, useContext, useEffect, useReducer, useState } from "react";
import {FormDP1, FormDP2, FormDP3 } from "../Forms/FormWProcess";
import "bootstrap/dist/css/bootstrap.css";
import WizardCProvider from "contexts/WizardContext";
import { AccountContext } from "contexts/AccountContext";
import ReactWizard from "../../libraries/react-bootstrap-wizard/React-bootstrap-wizard.js";
import "../../libraries/react-bootstrap-wizard/react-bootstrap-wizard.css"
import '../../styles/style-wizard.css';
import RPMSpecsReader from "classes/RPMSpecsReader";
import {Modal} from 'antd';
import $ from "jquery";
import { WebSocketContext } from "contexts/WebSocketContext";
import { Container, Row, Col } from "reactstrap";
import DEPLOY_STEPS from "../../files/deploy-steps.json";
import {sleep} from "../../scripts/functions"
import { RPMEventType } from "types/RPMEventType";
import { EventContext } from "contexts/EventContext";
import RPMMsgParser from "classes/RPMMsgParser";
import RPMEnvManager from "classes/RPMEnvManager";
import { openLocalNotification } from "components/Notifications/Notification";

/**
 * Define interface for the props
 *
 * @interface IProps
 */
interface IProps {
  parentCallback(value:any, name:string):void; // parent reference
  dataFromParent?:any;                         // data coming drom parent
  dataToEdit?:any;
  locked?:boolean;
}
interface IPropsWizard {
  //processData?:{pcs_name: string, pcs_file: string, pj_name: string, rlx_vrs: string};    
  processData?:any;   
  parentCallback(value:any, name:string):void; // data coming drom parent
}

/**
 * Define interface for the states
 *
 * @interface IState
 */
interface IState {
  // step 
  step?: string;                              
  // modal
  isModalVisible?:boolean;
  // form data
  form1?: {};
  form2?: {};
}

/**
 * Cpu and Memory of the process instance
 *
 * @class FirstStep
 * @extends {React.Component<IProps, IState>}
 */
class FirstStep extends React.Component <IProps, IState> {

  constructor(props:IProps) {
    super(props);
    this.state = {
      "step": "first step here",
      "form1": {},
    };
  }

  private formRef = createRef<any>();

  // Handle the data coming from the child element
  handleCallback = (childData:any, childDataName:string) =>{

    if(childDataName === "form1") {
      this.setState({"form1": childData});
    }
  }

  // Send data to parent
  sendFormData = () => {
    this.props.parentCallback(this.state.form1, "form1");
  }

  // Validate the step
  isValidated = async () => { 

    const form = this.formRef.current;
    let valid = false;
 
    await form
      .validateFields()
      .then(() => {
        // Validation is successful
        valid = true;
        
      })
      .catch(() => {
        valid = false;  
      });

    if (valid) {
      this.sendFormData();
    }
    return valid;
  }

  // Render
  render() {
    return( <FormDP1 dataFromParent = {this.props.dataFromParent} dataToEdit = {this.props.dataToEdit} formRef={this.formRef}  parentCallback = {this.handleCallback} ></FormDP1>);
  }
}

/**
 * Second step: Scheduling of the process
 *
 * @class FirstStep
 * @extends {React.Component<IProps, IState>}
 */
 class SecondStep extends React.Component <IProps, IState> {

  applyWizard: boolean;
  answerRdy: boolean;

  constructor(props:IProps) {
    super(props);
    this.state = {
      "step": "first step here",
      "form2": {},
    };
    this.applyWizard = false;
    this.answerRdy = false;
  }

  private formRef = createRef<any>();

  // Modal
  showModal = (value:boolean) => {
    this.setState({"isModalVisible": value});
  };
  handleOk = () => {
    this.applyWizard = true;
    this.answerRdy = true;
    this.setState({"isModalVisible": false});
    let btnPrevius = document.querySelectorAll('.btn-previous') as NodeListOf<HTMLElement>;
    btnPrevius.forEach(el => {
      el.style.display = ("none");
    });
  };
  handleCancel = () => {
    this.applyWizard = false;
    this.answerRdy = true;
    this.setState({"isModalVisible": false});
  };

  // Handle the data coming from the child element
  handleCallback = (childData:any, childDataName:string) =>{

    if(childDataName === "form2") {
      this.setState({"form2": childData});
    }
  }

  // Send data to parent
  sendFormData = () => {
    this.props.parentCallback(this.state.form2, "form2");
  }

  // Validate the step
  isValidated = async () => { 

    const form = this.formRef.current;
    let valid = false;
 
    await form
      .validateFields()
      .then(() => {
        // Validation is successful
        valid = true;
        
      })
      .catch(() => {
        valid = false;  
      });

    if (valid) {

      this.applyWizard = false;
      var counter = 0;
      
      // Show modal
      this.showModal(true);
      
      // Wait for answer ~15 seconds
      while(this.answerRdy === false) {
        
        // Wait half second
        await sleep(500);

        // Stop the wait if more than 15 seconds 
        if(counter > 25) {  
          this.answerRdy = true;
          this.showModal(false);
        }

        // Increment the counter
        counter ++;
      }

      // Send the data to the wizard
      if(this.applyWizard)
        this.sendFormData();

      // Reset answer as not ready
      this.answerRdy = false;

      // Return what i have gathered from the modal
      return (this.applyWizard);

    }
    return valid;
  }

  // Render
  render() {
    return( 
      <>
      <FormDP2 formRef={this.formRef}  parentCallback = {this.handleCallback} dataToEdit = {this.props.dataToEdit}></FormDP2>
      <Modal title="Deploy Process" visible={this.state.isModalVisible} onOk={this.handleOk} onCancel={this.handleCancel}>
        <p>Do you want to deploy this process ?</p>
      </Modal>
      </>
    );
  }
}

/**
 * Last step: Progress bar of the deploy of the process
 *
 * @class LastStep
 * @extends {React.Component<IProps, IState>}
 */
class LastStep extends React.Component <IProps, IState> {

  constructor(props:IProps) {
    super(props);
    this.state = {
      "step": "last step here",
    };
  }

  // Send data to parent
  sendData = (completed:boolean) => {
    if(completed !== false)
      this.props.parentCallback(true, "form3");
  }

  // Validate the step
  isValidated = () => {
    // do some validations
    if(this.props.locked === false) {
      this.sendData(true);
      return true;
    }
    else {
      return false;
    }
      
  }

  // Render
  render() {
    return( <FormDP3 dataFromParent = {this.props.dataFromParent} dataToEdit = {this.props.dataToEdit} parentCallback = {null} ></FormDP3>);
  }
}

/**
 * NpWizard: Wizard element for the creation of a new project
 * - 5 steps
 *
 * @return {*} 
 */
const ProcessWizard = (props:IPropsWizard): any => {

  // State vars
  const [form1,setForm1] = useState({});
  const [form2,setForm2] = useState({});
  const [dataReady, setDataReady] = useState(Boolean(false));
  const [processInfo, setProcessInfo] = useState({});
  const [specs, setSpecs] = useState({"cpu": [], "mem": [], "cpu_map": null, "mem_map": null});
  const [buildStatus, setBuildStatus] = useState({"value": 0, "msg": "","status": false, "completed": false});
  const [resourceupdated, setResourceupdated] = useState({"edit_mode": false, "msg": "", "finished": false});
  const [barProgress, setBarProgress] = useState(0);
  const [deployStepName, setDeployStepName] = useState([]);
  const [progIncr, setProgIncr] = useState(0);
  const [dataToEditForm1, setDataToEditForm1] = useState({"edit_mode": false, "cpu": -1, "memory": -1});
  const [dataToEditForm2, setDataToEditForm2] = useState({"edit_mode": false, "scheduled": "", "scheduled_expression": ""});
  const [locked, setLocked] = useState(false);

  // Updater
  const [ignored, forceUpdate] = useReducer(x => x + 1, 0);       //eslint-disable-line
  // Context
  const { getSession } = useContext(AccountContext);   //eslint-disable-line
  const {messageDeployProcess, messageEditProcess, checkStatusStack} = useContext(WebSocketContext);
  const { newEvent, getEvent } = useContext(EventContext);
  const [firstMessageDeploy, setFirstMessageDeploy] = useState(true);
  const [firstMessageEdit, setFirstMessageEdit] = useState(true);

  // Event (after the first render (only))
  useEffect(() => {
    
    setProcessInfo(props.processData);
    getSpecs();
    if(props.processData["edit_mode"]){
      setDataToEdit(props.processData);
    }

  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // Event (after the form 2 update completed)
  useEffect(() => {

    if(Object.entries(form2).length !== 0) {
      setDataReady(true);
    }
  }, [form2]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if(locked === true) {
      let btnPrevius = document.querySelectorAll('.btn-finish') as NodeListOf<any>;
      btnPrevius.forEach(el => {
        el.disabled = true;
      });
    }
    else if (locked === false) {
      let btnPrevius = document.querySelectorAll('.btn-finish') as NodeListOf<any>;
      btnPrevius.forEach(el => {
        el.disabled = false;
      });
    }
  }, [locked]); // eslint-disable-line react-hooks/exhaustive-deps

  // Event (after the form 2 update completed)
  useEffect(() => {
    if (buildStatus["completed"] === true && getEvent() === RPMEventType.START_DEPLOY_PROCESS) {
      //setBuildStatus({"value": 0, "msg": "", "status": false, "completed": false});
      newEvent(RPMEventType.END_DEPLOY_PROCESS);
    }
    
  }, [buildStatus]); // eslint-disable-line react-hooks/exhaustive-deps

  // Event (after the form 2 update completed)
  useEffect(() => {
    if (resourceupdated["finished"] === true && getEvent() === RPMEventType.START_DEPLOY_PROCESS) {
      //setResourceupdated({"edit_mode": false, "msg": "", "finished": false});
      newEvent(RPMEventType.END_EDIT_PROCESS);
      newEvent(RPMEventType.WARNING_NO_UPDATE_DEPLOY);
    }
  }, [resourceupdated]); // eslint-disable-line react-hooks/exhaustive-deps

  // Event (when state is uploaded)
  useEffect(() => {
    
    if(dataReady === true) {

      //lookFormData();
      checkStatusStack(processInfo["pcs_name"], processInfo["pj_name"]);
      run();  
    }
  }, [dataReady]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if(firstMessageDeploy){
      setFirstMessageDeploy(false);
    }
    else if (messageDeployProcess !== "") {
      handleMsgs(messageDeployProcess, "deploy_process");
    }
  }, [messageDeployProcess]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if(firstMessageEdit){
      setFirstMessageEdit(false);
    }
    else if (messageEditProcess !== "") {
      handleMsgs(messageEditProcess, "edit_process");
    }
  }, [messageEditProcess]); // eslint-disable-line react-hooks/exhaustive-deps

  // Run Deploy
  const run = () => {
    
    getSession().then((session: { accessToken:any;}) => {
        
      const token = session.accessToken.jwtToken;

      let dataStack = prepareDataStack();

      newEvent(RPMEventType.START_DEPLOY_PROCESS);

      if(!processInfo["edit_mode"]){
        addProgress(0, deployStepName[0], true);
        addProgress(0, deployStepName[1], true);
      }
      else{
        setResourceupdated({"edit_mode": true, "msg": "", "finished": false});
      }

      sendData("locked", true);
      setLocked(true);
      createStack(dataStack, token);
      
      setDataReady(false);
    });
  }

  // Handle the data coming from the child element steps
  const handleCallback = (childData:any, childDataName:string) => {

    switch (childDataName) {
      case "form1":
        setForm1(childData);
        break;
      case "form2":
        if(childData["scheduled"]) {
          setDeployStepName ([
            "-start-",
            DEPLOY_STEPS.Update_DB,
            DEPLOY_STEPS.Log_group, 
            DEPLOY_STEPS.Role, 
            DEPLOY_STEPS.Task_definition, 
            DEPLOY_STEPS.Rule, 
            DEPLOY_STEPS.End
          ]);
          setProgIncr(20);
        }
        else {
          setDeployStepName ([
            "-start-",
            DEPLOY_STEPS.Update_DB,
            DEPLOY_STEPS.Log_group, 
            DEPLOY_STEPS.Role, 
            DEPLOY_STEPS.Task_definition, 
            DEPLOY_STEPS.End
          ]);
          setProgIncr(25);
        }
        setForm2(childData);
        break;
      case "form3":
        sendData("completed", childData);     
        break;
      default:
    }
    forceUpdate();  //Update the state vars
  }

  // Handle the websocket msgs
  const handleMsgs = (msg:string, msgType:msgsType) => {

    let msgParser = new RPMMsgParser(msgType);
    let msgData = msgParser.getMsgData(msg);

    if(msgData["ignore"] === false) {
      switch (msgType) {
        case "deploy_process":
          if(msgData["index"] < 7) {
            addProgress(
              msgData["progress"] !== 0 ? progIncr : 0,
              msgData["index"] !== -1 ? deployStepName[msgData["index"]] : "Error",
              msgData["status"]
            )
          }
          break;
        case "edit_process":
          addResourceupdate(
            msgData["msg"],
            msgData["finished"]
          );
          break;
        default:
      } 
    }
  }

  // Prepare data
  const prepareDataStack = () => {
    
    // Setup memory format
    const memory = form1["pcs_memory"]*1024;

    // Setup cron format 
    const cronValue = Object.entries(form2).length !== 0 ? form2["cron"].substr(2) : "";

    // Setup process file
    //const baseProcessName = processInfo["pcs_file"].split(".")[0];

    let dataStack = {
      "projectName":          processInfo["pj_name"],
      "taskName":             processInfo["pcs_name"],
      "env":                  "staging",
      "cpu":                  form1["pcs_cpu"],
      "memory":               memory,
      "rulexParameters":      "",
      "scheduleExpression":   "cron(" + cronValue + ")",
    }

    if(processInfo["edit_mode"]){
      dataStack["stackName"] = processInfo["pcs_stack_name"];
      dataStack["env"] = processInfo["pcs_env"];
      if(processInfo["pcs_env"] === "prod"){
        dataStack["taskName"] = dataStack["taskName"] + "-prod";
      }
      if(processInfo["pcs_schedule_ID"] !== null && processInfo["pcs_schedule_ID"] !== undefined){
        dataStack["scheduleId"] = processInfo["pcs_schedule_ID"];
      }
    }

    return dataStack;
  }

   // Prepare data
   // eslint-disable-next-line

  // Call lambda function cloudformationStackGenerator
  const createStack = (dataStack: {}, token: any) => {
    
    let authorization = 'Bearer ' + token;
    let api = RPMEnvManager.getApiLambda("createStack");
    
    dataStack = JSON.stringify(dataStack);
    $.ajax({
      url: api,
      type: "POST",
      headers: {
          'Authorization': authorization
      },
      contentType: 'application/json',
      data: dataStack,
      processData: false,
      success: function( ) {
          //console.log("Finito call Stack e update DB");
          sendData("locked", false);
          setLocked(false);
          if(processInfo["edit_mode"]){
            addResourceupdate("finished_db", false);
          }
          else{
            addProgress(progIncr, deployStepName[2], true);
          }
      },
      statusCode: {
          304: function(response) {
            sendData("locked", false);
            setLocked(false);
            if(processInfo["edit_mode"]) {
              addResourceupdate("warning", true);
              openLocalNotification("warning","edit_process","304");
            }
            //console.log("No updates.");
          },
          401: function() {
            sendData("locked", false);
            setLocked(false);
            if(processInfo["edit_mode"]) {
              addResourceupdate("error", true);
              openLocalNotification("error","edit_process","401");
            }
            else{
              addProgress(0, "Stack Error", false);
              openLocalNotification("error","deploy_process","401");
            }
          },
          409: function() {
            sendData("locked", false);
            setLocked(false);
            if(processInfo["edit_mode"]) {
              addResourceupdate("error", true);
              openLocalNotification("error","edit_process","409");
            }
            else{
              addProgress(0, "Stack Error", false);
              openLocalNotification("error","deploy_process","409");
            }
          },
          500: function(response) {

            sendData("locked", false);
            setLocked(false);
            if(processInfo["edit_mode"]) {
              addResourceupdate("error", true);
              openLocalNotification("error","edit_process","500");
            }
            else{
              addProgress(0, "Stack Error", false);
              openLocalNotification("error","deploy_process","500");
            }
          }
      }
    });
  }

  // Get specs mem-cpu
  const getSpecs = () => {
    
    const specsReader = new RPMSpecsReader();

    setSpecs({
      "cpu":      specsReader.getCpuValues(),
      "mem":      specsReader.getMemValues(),
      "cpu_map":  specsReader.getCpuMap(),
      "mem_map":  specsReader.getMemMap()
    });
    forceUpdate();
  } 

  // Send data to parent
  const sendData = (step:string, completed:boolean) => {
    props.parentCallback(completed, step);
  } 

  // Add progress
  const addProgress = (increment: number, msg: string, status: boolean) => {

      let newBarProgress = barProgress + increment;
      setBarProgress(newBarProgress);

      let bStatus = {
        "value":      newBarProgress, 
        "msg":        msg, 
        "status":     status,
        "completed":  (newBarProgress >= 100) || (status === false)
      };
      setBuildStatus(bStatus);
  }

  // Add resource update
  const addResourceupdate = (msg: string, finished: boolean) => {
    setResourceupdated({"edit_mode": true, "msg": msg, "finished": finished});
  }

  // Defines the data to pass to the forms
  const setDataToEdit = (pcsData: any) =>{
    setDataToEditForm1({
      "edit_mode":  pcsData["edit_mode"],
      "cpu":        parseInt(pcsData["pcs_cpu"]),
      "memory":     parseInt(pcsData["pcs_memory"])/1024
    });

    setDataToEditForm2({
      "edit_mode":              pcsData["edit_mode"],
      "scheduled":              pcsData["pcs_scheduled"],
      "scheduled_expression":   pcsData["pcs_scheduled_expression"]
    });

    forceUpdate();
  }
  // Define the steps of the wizard
  var steps = [
    { "stepName": "Cpu and Memory", "component": FirstStep, "stepProps": {"parentCallback" : handleCallback, "dataFromParent" : specs, "dataToEdit": dataToEditForm1}},
    { "stepName": "Schedule", "component": SecondStep, "stepProps": {"parentCallback" : handleCallback, "dataToEdit": dataToEditForm2}},
    { "stepName": "Deploy", "component": LastStep, "stepProps": {"parentCallback" : handleCallback, "dataFromParent" : buildStatus, "dataToEdit": resourceupdated, "locked": locked}}
  ];

  // Return
  return (
    <>
    <Container fluid className="wizard-ctn-process">
      <Row className="wizard-row-process">
        <Col className="wizard-col-process p-0">
        <WizardCProvider>
          <ReactWizard
            steps={steps}
            title={processInfo["edit_mode"] ? <span className="wiz-title">EDIT PROCESS</span> : <span className="wiz-title">DEPLOY PROCESS</span>}
            headerTextCenter
            validate
            color="ego_color"
            description={
              <Col>
              </Col>
            }
          />
        </WizardCProvider>
        </Col>
      </Row>
    </Container>
    </>
  );

};
export default ProcessWizard;