/**
 * Rulex Project Manager
 *
 * --Process
 * 
 * @summary Contains the component of the process page.
 * @author Creative Tim (www.creative-tim.com)
 * @edited_by Riccardo Poli, Lorenzo Biasotti
 *
 */

import { Col, Container, Row} from "reactstrap";
import Header from "components/Headers/Header";
import ProcessTable from "components/Tables/ProcessTable";
import ProcessTabs from "components/Tabs/ProcessTabs";
import { RefObject, useReducer, useRef, useState } from "react";

/**
 * Process page
 *  
 * @return {*} 
 */
const Processes = () => {

  // State Var
  const [selectedProcess, setSelectedProcess] = useState();
  const [onUpdateTabs, updateTabs] = useReducer(x => x + 1, 0);   //eslint-disable-line
  const [onRefreshTabs, refreshTabs] = useReducer(x => x + 1, 0);   //eslint-disable-line
  // Ref
  const tabsRef = useRef() as RefObject<HTMLDivElement>;

  // scroll to element tabs
  const executeScroll = () => tabsRef.current.scrollIntoView();

  // Move focus on the tabs
  const focusTabs = () => {
    executeScroll();
  }

  return (
    <>
      <Header />
      <Container className="mt-5" fluid>
        <Row>
          <Col>
              <ProcessTable selectedProcess = {selectedProcess} setSelectedProcess = {setSelectedProcess} focusTabs = {focusTabs} updateTabs = {updateTabs} refreshTabs={refreshTabs}></ProcessTable>
          </Col>
        </Row>
        <Row>
          <Col className="mt-5">
            <ProcessTabs tabsRef={tabsRef} process={selectedProcess} onUpdateTabs={onUpdateTabs} onRefreshTabs={onRefreshTabs}></ProcessTabs>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Processes;

/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/