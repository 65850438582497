/**
 * Rulex Project Manager
 *
 * Sidebar
 * 
 * @summary Contains the sidebar used in the app.
 * @author Creative Tim (www.creative-tim.com)
 * @edited_by Riccardo Poli, Lorenzo Biasotti
 *
 */

import { useContext, useState } from "react";
import { NavLink as NavLinkRRD, Link } from "react-router-dom";
import * as PropTypes from 'prop-types'
import { EventContext } from "contexts/EventContext";
import "../../styles/my-style.css";
import { Collapse, NavbarBrand, Navbar, NavItem, NavLink, Nav, Container, Row, Col} from "reactstrap";
import { Modal } from "antd";
import NewProjectWizard from "components/Wizards/ProjectWizard";
import { CloseOutlined, DiffOutlined } from "@ant-design/icons";
import { RPMEventType } from "types/RPMEventType";
import { sleep } from "scripts/functions";

/**
 * Sidebar of the main pages
 *
 * @param {*} props
 * @return {*} 
 */
const Sidebar = (props) => {
  const [collapseOpen, setCollapseOpen] = useState<any | null>(null);
  const [visibleWizard, setVisibleWizard] = useState(false);
  const {newEvent} = useContext(EventContext);
  const [closable, setClosable] = useState(true);

  // Handle the data coming from the child element
  const handleCallback = (childData:any, childDataName:string) => {
    if(childDataName === "completed" && childData === true) {
      closeWizard();
    }
    else if(childDataName === "locked") {
      setClosable(! childData);
    }
  }

  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => { // eslint-disable-line
    return props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  };
  // toggles collapse between opened and closed (true/false)
  const toggleCollapse = () => {
    setCollapseOpen((data) => !data);
  };
  // closes the collapse
  const closeCollapse = () => {
    setCollapseOpen(false);
  };
  // open the wizard
  const openWizard = () => {
    setVisibleWizard(true);
  };
  // closes the wizard
  const closeWizard = async () => {
    newEvent(RPMEventType.CLOSE_PROJECT_WIZARD);
    setVisibleWizard(false);
    await sleep(500);
    let btnPrevius = document.querySelectorAll('.btn-previous') as NodeListOf<HTMLElement>;
    btnPrevius.forEach(el => {
      el.style.display = ("block");
    });
  };
  
  // creates the links that appear in the left menu / Sidebar
  const createLinks = (routes) => {
    return routes.map((prop, key) => {
      if(prop.name !== "Login") {
        return (
          <NavItem key={key}>
            <NavLink
              to={{
                pathname: prop.layout + prop.path,
                query: {"skip": true}
              }}
              tag={NavLinkRRD}
              onClick={closeCollapse}
              activeClassName="active"
            >
              {prop.icon}
              <p className="sidebar-text"> {prop.name}</p>
            </NavLink>
          </NavItem>
        );
      } 
      else {
        return true;
      }
    });
  };

  const { routes, logo } = props;
  let navbarBrandProps;
  if (logo && logo.innerLink) {
    navbarBrandProps = {
      to: logo.innerLink,
      tag: Link,
    };
  } else if (logo && logo.outterLink) {
    navbarBrandProps = {
      href: logo.outterLink,
      target: "_blank",
    };
  }

  return (
    <Navbar
      className="navbar-vertical navbar-light bg-white"
      expand="md"
      id="sidenav-main"
    >
      <Container fluid>
        <Modal
          centered
          visible={visibleWizard}
          onOk={() => setVisibleWizard(false)}
          onCancel={() => closeWizard()}
          width={"1000px"}
          closable={closable}
          closeIcon={<CloseOutlined style={{color: "white"}}/>}
          destroyOnClose={true}
          maskClosable={false}
          footer={null}
          bodyStyle={{padding:0, overflow:""}}
        >
          <NewProjectWizard key="wizard" projectData={{"edit_mode":false}} parentCallback={handleCallback}></NewProjectWizard>
        </Modal>
        
        <button
          className="navbar-toggler"
          type="button"
          onClick={toggleCollapse}
        >
          <span className="navbar-toggler-icon" />
        </button>
        {logo ? (
          <NavbarBrand className="pt-0" {...navbarBrandProps}>
            <img
              alt={logo.imgAlt}
              className="navbar-brand-img rounded-circle"
              src={logo.imgSrc}
            />
            <h5 className="mt-2">Rulex Project Manager</h5>
          </NavbarBrand>
        ) : null}
        <Collapse navbar isOpen={collapseOpen}>
          <div className="navbar-collapse-header d-md-none">
            <Row>
              {logo ? (
                <Col className="collapse-brand" xs="6">
                  {logo.innerLink ? (
                    <Link to={logo.innerLink}>
                      <img alt={logo.imgAlt} src={logo.imgSrc} />
                    </Link>
                  ) : (
                    <a href={logo.outterLink}>
                      <img alt={logo.imgAlt} src={logo.imgSrc} />
                    </a>
                  )}
                </Col>
              ) : null}
              <Col className="collapse-close" xs="6">
                <button
                  className="navbar-toggler"
                  type="button"
                  onClick={toggleCollapse}
                >
                  <span />
                  <span />
                </button>
              </Col>
            </Row>
          </div>
          <Nav navbar>{createLinks(routes)}</Nav>
          <hr className="my-3" />
          <Nav navbar>
            <NavItem>
              <NavLink
                onClick={() => openWizard()}
              >
                <DiffOutlined className="sidebar-img"/>
                <p className="sidebar-text">New Project</p>
              </NavLink>
            </NavItem> 
          </Nav>
        </Collapse>
      </Container>
    </Navbar>
  );
};

Sidebar.defaultProps = {
  routes: [{}],
};

Sidebar.propTypes = {
  // links that will be displayed inside the component
  routes: PropTypes.arrayOf(PropTypes.object),
  logo: PropTypes.shape({
    // innerLink is for links that will direct the user within the app
    // it will be rendered as <Link to="...">...</Link> tag
    innerLink: PropTypes.string,
    // outterLink is for links that will direct the user outside the app
    // it will be rendered as simple <a href="...">...</a> tag
    outterLink: PropTypes.string,
    // the image src of the logo
    imgSrc: PropTypes.string.isRequired,
    // the alt for the img
    imgAlt: PropTypes.string.isRequired,
  }),
};

export default Sidebar;

/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/