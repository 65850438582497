/**
 * Rulex Project Manager
 *
 * --RPMEnvManager
 * 
 * @summary class to manage env variables
 * @author Riccardo Poli, Lorenzo Biasotti
 *
 */
class RPMEnvManager {

    
    /**
     *  getApiLambda
     *  Returns the api lambda url from the defined lmbda name
     *  Takes the api endpoint from env file
     *
     * @static
     * @param {string} name (name of the lambda)
     * @return {string} api lambda url
     * @memberof RPMEnvManager
     */
    public static getApiLambda(name:string) {
        var lambdaEndpoint = process.env.REACT_APP_LAMBDA_ENDPOINT;
        return lambdaEndpoint + "/" + name;
    }

    /**
     *  getApiWebSocket
     *  Returns the api websocket url
     *  Takes the api endpoint from env file
     *
     * @static
     * @return {string} api websocket url
     * @memberof RPMEnvManager
     */
    public static getApiWebSocket() {
        var websocketEndpoint = process.env.REACT_APP_WEBSOCKET_ENDPOINT;
        return "wss://" + websocketEndpoint;
    }

    /**
     *  getUserPoolConfig
     *
     * @static
     * @return {json} user pool config
     * @memberof RPMEnvManager
     */
     public static getUserPoolConfig() {
        var userPoolConfig =  {
            "user_pool_ID": process.env.REACT_APP_USER_POOL_ID,
            "app_client_ID": process.env.REACT_APP_APP_CLIENT_ID,
        }
        return userPoolConfig;
    }

    /**
     *  getCognitoDomainName
     *
     * @static
     * @return {string} cognito domain
     * @memberof RPMEnvManager
     */
     public static getCognitoDomainName() {
        return process.env.REACT_APP_COGNITO_DOMAIN_NAME;
    }

    /**
     *  getWebsiteDomainName
     *
     * @static
     * @return {string} website domain
     * @memberof RPMEnvManager
     */
     public static getWebsiteDomainName() {
        return process.env.REACT_APP_WEBSITE_DOMAIN_NAME;
    }

}
 
export default RPMEnvManager;