/**
 * Rulex Project Manager
 *
 * --ProjectTabs
 * 
 * This file contains all the necessary to use the tabs for the projects.
 * - 4 tab component definitions (tab contents)
 * - 1 main-parent component that organize the contents of the tabs inside.
 * 
 * @summary Define the components used for the tabs.
 * @author Riccardo Poli, Lorenzo Biasotti
 *
 */

import { CheckCircleOutlined, ClockCircleOutlined, CloseCircleOutlined, CloseOutlined, ExclamationCircleOutlined, SyncOutlined } from "@ant-design/icons";
import { Button, Checkbox, Col, Divider, List, message, Modal, Row, Table, Tabs, Tag } from "antd";
import NewProjectWizard from "components/Wizards/ProjectWizard";
import { AccountContext } from "contexts/AccountContext";
import { EventContext } from "contexts/EventContext";
import { downloadProjectFolder, formatDateTime, sleep } from "scripts/functions";
import { RPMEventType } from "types/RPMEventType";
import $ from "jquery"
import { DownloadOutlined } from "@ant-design/icons";
import DirectoryTree from "antd/lib/tree/DirectoryTree";
import { RefObject, useContext, useEffect, useReducer, useState } from "react";
import RPMFilesTreeBuilder from "../../classes/RPMFilesTreeBuilder";
import RPMEnvManager from "classes/RPMEnvManager";
import { openLocalNotification } from "components/Notifications/Notification";

/**
 * Interface to define the form props
 *
 * @interface IProps
 */
interface IProps {
    project:any;
    tabsRef:RefObject<HTMLDivElement>;
    onUpdateTabs:any;
    onRefreshTabs:any;
}
interface IPropsChild {
    tabName:string;
    get: (field) => any;
    user:string;
    updateOnTabs:any;
    refreshOnTabs:any;
}

// Tab 1
const Tab1 = (props:IPropsChild) => {

    const [dataToEdit, setDataToEdit] = useState({}); // eslint-disable-line 
    const [visibleWizard, setVisibleWizard] = useState(false);
    const {newEvent} = useContext(EventContext);
    //const [visibleNewProjectWizard, setVisibleNewProjectWizard] = useState(false);
    const [isModalDeleteProjectVisible, setIsModalDeleteProjectVisible] = useState(false);
    const [checkedDownloadProject, setCheckedDownloadProject] = useState(false);
    const [tempRecord, setTempRecord] = useState();
    const [isModalUnlockProjectVisible, setIsModalUnlockProjectVisible] = useState(false);
    const [editLoading, setEditLoading] = useState(false);

    const { getSession } = useContext(AccountContext);  

    // close the wizard
    const closeWizard = async () => {
        newEvent(RPMEventType.CLOSE_PROJECT_WIZARD);
        setVisibleWizard(false);
        await sleep(500);
        let btnPrevius = document.querySelectorAll('.btn-previous') as NodeListOf<HTMLElement>;
        btnPrevius.forEach(el => {
        el.style.display = ("block");
        });
    };

    // Handle the data coming from the child element
    const handleCallback = (childData:any, childDataName:string) => {
        if(childDataName === "completed") {
            closeWizard();
        }
    }

    // Event on click edit process
    const clickEditProject = () => {
        setEditLoading(true);
        checkConcurrencyBeforeUpdate();
    }

     // Check lock on project
    const checkConcurrencyBeforeUpdate = () => {
        
        getSession().then((session: { accessToken: any; }) => {
        let authorization = 'Bearer ' + session.accessToken.jwtToken;
        let api = RPMEnvManager.getApiLambda("getConcurrencyLock");
        
        let data = {"projectName": props.get("project")};
        let dataToSend = JSON.stringify(data);

        $.ajax({
            url: api,
            type: "POST",
            headers: {
                'Authorization': authorization
            },
            contentType: 'application/json',
            data: dataToSend,
            processData: false,
            dataType: 'text',
            success: function( response ) {
                let lock = JSON.parse(response);
                editProject(lock);
                setEditLoading(false);
            },
            statusCode: {
            401: function() {
                //console.log("Invalid Token.");
                openLocalNotification("error","db_data","401");
                setEditLoading(false);
            },
            500: function() {
                openLocalNotification("error","db_data","500");
                setEditLoading(false);
            }
            }
        });
        });
    };

    // edit project
    const editProject = (lock: {}) => {

        if(lock["onLock"] === false) {
            props.get("all")["edit_mode"] = true;
            setDataToEdit(props.get("project"));
            setVisibleWizard(true);
        }
        else if(lock["onLock"]) {

            const deltaMin = 30;

            let lt = lock["lockTime"];

            let currentDate = new Date();
            let lockTime = new Date(lt + 'z');

            const diffTime = Math.abs(currentDate.valueOf() - lockTime.valueOf()).toString().slice(0,-3);
            const diffTimeMin = Math.floor(parseInt(diffTime) / 60);

            if(diffTimeMin <= deltaMin) {
                openLocalNotification("error","edit_project","409");
            }
            else {
                // Show modal
                setTempRecord(props["projectData"]);
                setIsModalUnlockProjectVisible(true);
            }
        }
    }

    // Download project folder
    const downloadProject = async () => {

        return await new Promise((resolve, reject) => {

            getSession().then((session: { accessToken: any; }) => {
                
                let authorization = 'Bearer ' + session.accessToken.jwtToken;
                downloadProjectFolder(authorization, props.get("project"), props.get("process_files"), props.get("sub_process_files"), props.get("input_files")).then(() => resolve(true))  
            });
        });
    };
    
    // Write msg start 
    const displayStartMsg = (text:string) => {
        const hide = message.loading(text, 0);
        // Dismiss manually and asynchronously
        setTimeout(hide, 3500);
    }

    // delete project
    const deleteProject = (project:string) =>{
        getSession().then((session: { accessToken: any; }) => {
            let authorization = 'Bearer ' + session.accessToken.jwtToken;
            let api = RPMEnvManager.getApiLambda("deleteProject");
            
            let data = {"projectName": project};
            let dataToSend = JSON.stringify(data);

            displayStartMsg("Deleting project " + project + " ..");
      
            $.ajax({
              url: api,
              type: "POST",
              headers: {
                  'Authorization': authorization
              },
              contentType: false,
              data: dataToSend,
              processData: false,
              success: function( response ) {
                    //console.log("Delete ok");
                    newEvent(RPMEventType.END_DELETE_PROJECT);
                    openLocalNotification("success","delete_project");
              },
              statusCode: {
                  401: function() {
                    //console.log("Invalid Token.");
                    newEvent(RPMEventType.UNAUTH_DELETE);
                    openLocalNotification("error","delete_project","401");
                  },
                  409: function() {
                    newEvent(RPMEventType.CONCURRENCY_DELETE);
                    openLocalNotification("error","delete_project","409");
                  },
                  500: function(response) {
                    //console.log(response.responseText);
                    newEvent(RPMEventType.ERROR_DELETE);
                    openLocalNotification("error","delete_project","500");
                  }
              }
            });
          });
    }

    // Handle click on delete project button
    const clickUnlockProject = () => {
        setIsModalUnlockProjectVisible(false);
        let record = tempRecord as {};
        if(record !== undefined) {
            props.get("all")["edit_mode"] = true;
            setDataToEdit(props.get("project"));
            setVisibleWizard(true);
        }
    };

    // Show modal to confirm delete
    const showModalConfirmDeleteProject = () => {
        setIsModalDeleteProjectVisible(true);
    };

    // Click to delete project
    const clickDeleteProject = () => {
        setIsModalDeleteProjectVisible(false);
        if(checkedDownloadProject){
          downloadProject().then(() => deleteProject(props.get("project")))
        }
        else{
          deleteProject(props.get("project"))
        }
        
    };

    // Handle cancel on delete project modal
    const handleCancel1 = () => {
        setIsModalDeleteProjectVisible(false);
    };

    // Handle cancel on unlock project modal
    const handleCancel2 = () => {
        setIsModalUnlockProjectVisible(false);
    };

    // On change download checkbox
    const onChange = (e) => {
        setCheckedDownloadProject(e.target.checked)
    };

    let dati = <Row className="mt-6" justify="center" align="top" style={{color: "black", fontWeight: 500, fontSize: 20}}>Select a project</Row>
    if(props.get("project") !== ""){
        dati = (
        <div>
            <Row className="mt-5" justify="center" align="top">
                <Col span={20} xl={14}>
                    <Row justify="start">
                        <Col className="tab-inner-content">
                            <p>Name: <span>{props.get("project")}</span></p>
                        </Col>
                    </Row>  
                </Col>
                <Col span={20} xl={6}>
                    <Row justify="start">
                        <Col className="tab-inner-content">
                            <p>Rulex Version: <span>{props.get("rulex_version")}</span></p>
                        </Col>
                    </Row>  
                </Col>
            </Row>
            <Row className="mt-2" justify="center" align="top">
                <Col span={20} xl={12}>
                    <Row justify="start">
                        <Col className="tab-inner-content">
                            <p>Description: <span>{props.get("description")}</span></p>
                        </Col>
                    </Row>    
                </Col>
                <Col span={20} xl={8}>

                </Col>
            </Row>
            <Row className="mt-2" justify="center" align="top">
                <Col span={20} xl={14}>
                    <Row justify="start">
                        <Col className="tab-inner-content">
                            <p>Owner: <span>{props.get("owner")}</span></p>
                        </Col>
                    </Row>
                    <Row justify="start">
                        <Col className="tab-inner-content">
                            <p>Collaborators: <span>{props.get("collaborators").join(', ')}</span></p>
                        </Col>
                    </Row>
                </Col>
                <Col span={20} xl={6}>     
                    <Row justify="start">
                        <Col className="tab-inner-content">
                            <p>Creation: <span>{formatDateTime(props.get("creation"))}</span></p>
                        </Col>
                    </Row>
                    <Row justify="start">
                        <Col span={20} xl={20} className="tab-inner-content">
                            <p>Last Update: <span>{formatDateTime(props.get("last_update"))}</span></p>
                        </Col>
                        <Col span={20} xl={20} className="tab-inner-content">
                            <p style={{marginLeft: "15px"}} >By: <span>{props.get("last_updated_by")}</span></p>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row className="mt-2 mb-2" justify="center" align="top">
                <Col span={20} xl={16} className="tab-inner-content"></Col>
                <Col span={20} xl={8} className="tab-inner-content">
                    <Row justify="start">
                        <Col span={20} xl={20} className="tab-inner-content">
                            <Button 
                                className="tab-btn"
                                type="primary"
                                style={{margin: 5}}
                                shape="round"
                                size={"large"}
                                onClick={clickEditProject} 
                                disabled={props.get("owner") !== props.user && !props.get("collaborators").includes(props.user)}
                                loading={editLoading}
                            >Edit</Button>
                            <Button 
                                className="tab-btn"
                                type="primary"
                                style={{margin: 5}}
                                shape="round"
                                size={"large"}
                                danger
                                onClick={showModalConfirmDeleteProject}
                                disabled={props.get("owner") !== props.user && !props.get("collaborators").includes(props.user)}
                            >Delete</Button>
                            
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>);
    }
    // Return
    return (
        <>
        <Modal
          centered
          visible={visibleWizard}
          onOk={() => setVisibleWizard(false)}
          onCancel={() => closeWizard()}
          width={"1000px"}
          closable={true}
          closeIcon={<CloseOutlined style={{color: "white"}}/>}
          destroyOnClose={true}
          maskClosable={false}
          footer={null}
          bodyStyle={{padding:0, overflow:""}}
        >
          <NewProjectWizard key="wizard" projectData={props.get("all")} parentCallback={handleCallback}></NewProjectWizard>
        </Modal>
        <Row className="tab-body" justify="center" align="top">
            <Col span={24}>
                <Row className="mt-3 mb-2" justify="center" align="top">
                    <Col>
                        <h1>{props.tabName}</h1>
                    </Col>
                </Row>
                {dati}
            </Col>
        </Row>
        <Modal className="modal-delete-project" title="Delete Project" visible={isModalDeleteProjectVisible} onOk={clickDeleteProject} onCancel={handleCancel1}>
            <p>Are you sure you want to delete the project?</p>
            <Checkbox className="checkbox-delete-project" onChange={onChange}>Download the project</Checkbox>
        </Modal>
        <Modal className="modal-unlock-project" title="Edit Project" visible={isModalUnlockProjectVisible} onOk={clickUnlockProject} onCancel={handleCancel2}>
            <p>The project you want to edit is undergoing another operation for more than 30 minutes.<br></br> Do you want to continue editing this project anyway ? </p>
        </Modal>
        </>
    );
}

// Tab 2
const Tab2 = (props:IPropsChild) => {

    // Return
    return (
        <Row className="tab-body" justify="center" align="top">
            <Col span={24}>
                <Row className="mt-3 mb-2" justify="center" align="top">
                    <Col>
                        <h1>{props.tabName}</h1>
                    </Col>
                </Row>
                <Row hidden={props.get("project") !== ""} className="mt-6" justify="center" align="top" style={{color: "black", fontWeight: 500, fontSize: 20}}>Select a project</Row>
                <Row hidden={props.get("project") === ""} className="mt-5" justify="center" align="top">
                    <Col>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
}

// Tab 3
const Tab3 = (props:IPropsChild) => {

    const DEF_STATE = {"process": [""], "sub_process": [""], "input": [""]};
    const [listData, setListData] = useState(DEF_STATE);
    const [treeData, setTreeData] = useState([]);
    const [expKeys, setExpKeys] = useState([]);
    
    // Session
    const { getSession } = useContext(AccountContext);

    // On changed project
    useEffect(() => {
        getData();
        getTreeData();
    }, [props.updateOnTabs, props.refreshOnTabs]); // eslint-disable-line react-hooks/exhaustive-deps

    // Get data from parent
    const getData = () => {

        let data = DEF_STATE;

        data.process = (props.get("process_files").length > 0) ?  props.get("process_files") : [""];
        data.sub_process = (props.get("sub_process_files").length > 0) ?  props.get("sub_process_files") : [""];
        data.input = (props.get("input_files").length > 0) ? props.get("input_files") : [""];

        setListData(data);
    };

    // Get tree data using treebuilder
    const getTreeData = () => {

        let TreeBuilder = new RPMFilesTreeBuilder(props.get("process_files"), props.get("sub_process_files"), props.get("input_files"));

        let tree = TreeBuilder.getTree();

        if(! tree.error) {
            setTreeData(tree.data);
            setExpKeys(tree.keys);
        }
        
    };

    // On select tree
    const onSelect = (keys: React.Key[], info: any) => {
        //console.log('Trigger Select', keys, info);
      };
    
    // On expand tree
    const onExpand = () => {
        //console.log('Trigger Expand');
    };

    // On click button download folder
    const onClickButton = (event:any) => {
            downloadFolder();
    }

    // Download project folder
    const downloadFolder = () => {

        getSession().then((session: { accessToken: any; }) => {
            
            let authorization = 'Bearer ' + session.accessToken.jwtToken;
            downloadProjectFolder(authorization, props.get("project"), props.get("process_files"), props.get("sub_process_files"), props.get("input_files"))
        });
    };
    
    // Return
    return (
        <Row className="tab-body-ul" justify="center" align="top">
            <Col span={24}>
                <Row className="mt-3 mb-2" justify="center" align="top">
                    <Col>
                        <h1>{props.tabName}</h1>
                    </Col>
                </Row>
                <Row hidden={props.get("project") !== ""} className="mt-6" justify="center" align="top" style={{color: "black", fontWeight: 500, fontSize: 20}}>Select a project</Row>
                <Row hidden={props.get("project") === ""} className="mt-3 mb-5" justify="center" align="top">
                    <Col span={22}>
                        <Row align="top" justify="center">
                            <Col span={24} xl={11}>
                                <Divider orientation="center">Process Files</Divider>
                                <List
                                size="small"
                                bordered
                                dataSource={listData.process}
                                renderItem={item => <List.Item>{item}</List.Item>}
                                />
                                <Divider orientation="center">Sub Process Files</Divider>
                                <List
                                size="small"
                                bordered
                                dataSource={listData.sub_process}
                                renderItem={item => <List.Item>{item}</List.Item>}
                                />
                                <Divider orientation="center">Input Files</Divider>
                                <List
                                size="small"
                                bordered
                                dataSource={listData.input}
                                renderItem={item => <List.Item>{item}</List.Item>}
                                />
                            </Col>
                            <Col span={24} xl={2}>
                            </Col>
                            <Col span={24} xl={11}>
                                <Row className="mt-5" align="top" justify="center">
                                    <Col>
                                        <DirectoryTree
                                            multiple
                                            expandedKeys={expKeys}
                                            onSelect={onSelect}
                                            onExpand={onExpand}
                                            treeData={treeData}
                                            selectable={false}
                                        />
                                    </Col>
                                </Row>
                                <Row className="mt-5" align="top" justify="center">
                                    <Col>
                                        <Button type="primary" className="btn-primary-rpm" onClick={(event) => onClickButton(event)} shape="round" icon={<DownloadOutlined />} size={"large"}
                                            disabled={props.get("owner") !== props.user && !props.get("collaborators").includes(props.user)}
                                        >
                                            Download
                                        </Button>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
}

// Tab 4
const Tab4 = (props:IPropsChild) => {

    const COL = ["name", "description", "type", "scheduled", "status"];

    // Finite filters on columns
    const FILTERS = {
        "type":       ["not deployed", "staging", "prod"],
        "scheduled":  ["yes", "no"],
    };

    // Status namespace
    const STATUS = {
        "":            {"color": "default",     "text": "IDLE",     "icon": false},
        "idle":        {"color": "default",     "text": "IDLE",     "icon": false},
        "loading":     {"color": "default",     "text": "LOADING",  "icon": <ClockCircleOutlined />},
        "pending":     {"color": "warning",     "text": "PENDING",  "icon": <ClockCircleOutlined />},
        "running":     {"color": "processing",  "text": "RUNNING",  "icon": <SyncOutlined spin />},
        "warning":     {"color": "warning",     "text": "WARNING",  "icon": <ExclamationCircleOutlined />},
        "finished":    {"color": "success",     "text": "FINISHED", "icon": <CheckCircleOutlined />},
        "error":       {"color": "error",       "text": "ERROR",    "icon": <CloseCircleOutlined />},
    }
    
    // State var
    const [dataT, setDataT] = useState([]);
    const [columnsT, setColumnsT] = useState([]);

    // on project changed
    useEffect(() => {
        getColumns();
        getData();
    }, [props.updateOnTabs, props.refreshOnTabs]); //eslint-disable-line
    
    // Get columns
    const getColumns = () => {

        let columns = [];

        
        COL.forEach((field, index) => {
            columns.push({
                "title": field.replace("_", " "),
                "dataIndex": field,
                "key": field,
                "sorter": {
                    "compare": (a, b) => a[field].localeCompare(b[field]),
                },
            });

            if(field === "name") {
                columns[columns.length-1]["fixed"] = "left";
            }

            if(field === "scheduled" || field === "status" || field === "type") {
                columns[columns.length-1]["align"] = "center";
            }
            
            if(field === "status") {
                columns[columns.length -1]["render"] = (tags) => (
                    <>
                        < Tag icon={STATUS[tags]["icon"]} color={STATUS[tags]["color"]}>{STATUS[tags]["text"]}</Tag>
                    </>
                ); 
            }
            


            if(field in FILTERS) {

                let newFilter = new Array<{}>();
        
                FILTERS[field].forEach(option => {
                    newFilter.push({
                        "text": option,
                        "value": option            
                    });
                });

                columns[columns.length -1]["filters"] = newFilter;
                columns[columns.length -1]["onFilter"] = (value, record) => record[field].indexOf(value) === 0;
            }
        });

        setColumnsT(columns);
    }

    // Setup table data
    const getData = () => {

        let data = [];

        let processList = props.get("process_list");

        for (var element in processList) {

            let processName = element;
            
            if(processList[element]["taskType"] === "prod"){
                processName = element.substring(0, element.length-5);
            }
            
            let scheduled = (undefined !== processList[element]["taskSchedule"]) &&
                            ("cron()"  !== processList[element]["taskSchedule"]) ? "yes": "no";

            data.push({
                "key":          element,
                "name":         processName,
                "description":  processList[element]["processDescription"],
                "type":         processList[element]["taskType"],
                "scheduled":    scheduled,
                "status":       processList[element]["taskStatus"],
            });
        }

        setDataT(data);
    }
    

    // Return
    return (
        <Row className="tab-body" justify="center" align="top">
            <Col span={24}>
                <Row className="mt-3 mb-2" justify="center" align="top">
                    <Col>
                        <h1>{props.tabName}</h1>
                    </Col>
                </Row>
                <Row hidden={props.get("project") !== ""} className="mt-6" justify="center" align="top" style={{color: "black", fontWeight: 500, fontSize: 20}}>Select a project</Row>
                <Row hidden={props.get("project") === ""} className="mt-5" justify="center" align="top">
                    <Col span={22}>
                        <Table 
                            columns={columnsT} 
                            dataSource={dataT}
                            scroll={{ x: '800px' }}
                            pagination={{ pageSize: 4 }}
                            onRow={(record, rowIndex) => {
                                return {
                                  onClick: event => {},                                   // click row
                                  onDoubleClick: event => {},                             // double click row
                                  onContextMenu: event => {},                             // right button click row
                                  onMouseEnter: event => {},                              // mouse enter row
                                  onMouseLeave: event => {},                              // mouse leave row
                                }
                            }}
                        />
                    </Col>
                </Row>
            </Col>
        </Row>
    );
}

/**
 * Project Tabs
 * 
 * @param props
 * @returns 
 */
const ProjectTabs = (props:IProps) => {  

    const { TabPane } = Tabs;
    const TAB_LIST = ["Project", "Versions", "Files", "Process List"];

    const [activeTab, setActiveTab] = useState("0");    //eslint-disable-line
    const [updateOnTabs, updateTabs] = useReducer(x => x + 1, 0);   //eslint-disable-line
    const [refreshOnTabs, refreshTabs] = useReducer(x => x + 1, 0);   //eslint-disable-line

    const [user, setUser] = useState("");
    const { getSession } = useContext(AccountContext);  

    // On project changed
    useEffect(() => {
        getSession().then((session: { idToken: any;}) => {
            setUser(session.idToken.payload.email);
        });
    }, [props.project]);    // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(()=> {
        updateTabs();
    }, [props.onUpdateTabs])
    
    useEffect(()=> {
        refreshTabs();
    }, [props.onRefreshTabs])

    // get value from data
    const getData = (field:string) :any => {

        if(props.project === null || props.project === undefined) {
            return "";
        }
        else if(field === "all") {
            return props.project;
        }
        else {
            return props.project[field];
        }
    } 

    // Return
    return (
        <>
        <div ref={props.tabsRef} className="card-container tab-project tab-rpm">
            <Tabs type="card" onChange={(key) => setActiveTab(key)}>
                <TabPane tab={TAB_LIST[0]} key="0">
                    <Tab1 updateOnTabs={updateOnTabs} refreshOnTabs={refreshOnTabs} get={getData} user={user} tabName={TAB_LIST[0]}></Tab1>  
                </TabPane>
                <TabPane disabled tab={TAB_LIST[1]} key="1">
                    <Tab2 updateOnTabs={updateOnTabs} refreshOnTabs={refreshOnTabs} get={getData} user={user} tabName={TAB_LIST[1]}></Tab2>  
                </TabPane>
                <TabPane tab={TAB_LIST[2]} key="2">
                    <Tab3 updateOnTabs={updateOnTabs} refreshOnTabs={refreshOnTabs} get={getData} user={user} tabName={TAB_LIST[2]}></Tab3>  
                </TabPane>
                <TabPane tab={TAB_LIST[3]} key="3">
                    <Tab4 updateOnTabs={updateOnTabs} refreshOnTabs={refreshOnTabs} get={getData} user={user} tabName={TAB_LIST[3]}></Tab4>  
                </TabPane>
            </Tabs> 
        </div> 
        </>
    );


}

export default ProjectTabs;