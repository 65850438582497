/**
 * Rulex Project Manager
 *
 * AdminFooter
 * 
 * @summary Contains the footer component used in the admin pages.
 * @author Creative Tim (www.creative-tim.com)
 * @edited_by Riccardo Poli, Lorenzo Biasotti
 *
 */

import { Row, Col, Nav, NavItem, NavLink } from "reactstrap";

/**
 * Footer of the admin pages
 *
 * @return {*} 
 */
const Footer = () => {
  return (
    <footer className="footer">
      <Row className="align-items-center justify-content-xl-between">
        <Col xl="6">
          <div className="copyright text-center text-xl-left text-muted" style={{paddingLeft: "10px"}}> 
            © {2022}{" "}
            <a
              className="font-weight-bold ml-1"
              href="https://ego.energy/en/"
              rel="noopener noreferrer"
              target="_blank"
              style={{color: "#0F4A6A"}}
            >
              Ego Group
            </a>
          </div>
        </Col>

        <Col xl="6">
          <Nav className="nav-footer justify-content-center justify-content-xl-end">
            <NavItem>
              <NavLink
                href="https://ego.energy/en/"
                rel="noopener noreferrer"
                target="_blank"
              >
                Ego Energy
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink
                href="https://ego.energy/en/about/group-history/"
                rel="noopener noreferrer"
                target="_blank"
              >
                About Us
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink
                href="https://ego.energy/en/contact-us/"
                rel="noopener noreferrer"
                target="_blank"
              >
                Contact Us
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink
                href="https://www.rulex.ai/"
                rel="noopener noreferrer"
                target="_blank"
                style={{paddingRight: "15px"}}
              >
                Rulex 
              </NavLink>
            </NavItem>
          </Nav>
        </Col>
      </Row>
    </footer>
  );
};

export default Footer;

/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/