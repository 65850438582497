/**
 * Rulex Project Manager
 *
 * --RPMFileUploader
 * 
 * @summary Contains the class RPMFileUploader to upload project files on s3.
 * @author Riccardo Poli, Lorenzo Biasotti
 *
 */

import RPMFile from "classes/RPMFile";
import $ from "jquery";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { RPMFileType } from "types/RPMFileType";
//import FILE_STRUCTURE from "../files/file-structure.json"
//import RPMEnvManager from "./RPMEnvManager";

/**
 * Interface to define the form props
 *
 * @interface IProps
 */
interface IProps {
    parentCallback(value:any, name:string):void;  // parent reference
    dataFromParent?:any;                          // data coming drom parent
}

/** 
 * File Uploader , upload file to s3.
 * Component to manage the upload of files on s3.
 * Pass to parent some code to signal success, failure or warnings.
 * 
 * @type {*} 
 */
const RPMFileUploader: any = forwardRef((props:IProps, ref:any) => {
    
    const [status,setStatus] = useState(1);

    // Event (after the first render (only))
    useEffect(() => {

        // Avoid first call
        if (status !== 1) {
            sendData();
            setStatus(1);
        }
    }, [status]); // eslint-disable-line react-hooks/exhaustive-deps

    // Send data to parent
    const sendData = () => {
        props.parentCallback(status, "status");
    }
    
    useImperativeHandle(ref, () => ({
        
        // Upload files on s3
        /*uploadFiles(projectName:string, processData:any, token:string) {

            const API_ENDPOINT = RPMEnvManager.getApiLambda("upload/fileName?");

            let authorization = '';
        
            // token = document.getElementById("tokenID").value;
    
            const mainFilePath = API_ENDPOINT + 'projects/' + projectName + '/staging/files';
    
            var invalidToken = 0;
            authorization = 'Bearer ' + token;
            
            let countSuccessProcess = 0;
            let countProcessAndModuls = 0;
            

            //PROCESS FILES
            if(processData.process !== null && processData.process !== undefined){

                const processFilePath = mainFilePath + FILE_STRUCTURE.processes.path;
            
                var processFile;

                for(let i = 0; i < processData.process.length; i++){
        
                    processFile = new RPMFile(processData.process[i]);

                    var apiPlusFileName = processFilePath + processFile.getName();
        
                    //call lambda function for getting presigned url, for each processes files
                    $.ajax({
                        url: apiPlusFileName,
                        type: "GET",
                        headers: {
                            'Authorization': authorization
                        },
                        contentType: false,
                        success: function( response ) {                         //eslint-disable-line
                            if( response !== "" ) {
                                var resp = response.split('\"');                //eslint-disable-line
                                //upload file to s3 using the presigned url
                                $.ajax({
                                    url: resp[1],
                                    type: "PUT",
                                    data: processFile.getFile(),
                                    processData: false,
                                    contentType: 'application/octet-stream',
                                    success: function( response ) {
                                        if( response === "" ) {
                                            countSuccessProcess++;
                                            if(countSuccessProcess === processData.process.length) {
                                                //console.log('Processes files Uploaded!');
                                                countProcessAndModuls++;
                                                if(processData["modules_and_inputs"] !== null){
                                                    if(countProcessAndModuls > 1){
                                                        setStatus(0);
                                                    }
                                                }
                                                else{
                                                    setStatus(0);
                                                }
                                            }
                                        }
                                    }
                                });
                            }
                        },
                        statusCode: {
                            401: function() {                          //eslint-disable-line
                                if(invalidToken === 0){
                                    invalidToken++;
                                    setStatus(-1);
                                }
                            },
                            500: function() {
                                setStatus(500);
                            }
                        }
                    });
                }
            }

            let countSuccessInput = 0;

            const moduleFilePath = mainFilePath + FILE_STRUCTURE.modules.path;
            const inputFilePath = mainFilePath + FILE_STRUCTURE.input_files.path;
    
            //MODULES AND INPUT FILES
            if(processData["modules_and_inputs"] !== null && processData["modules_and_inputs"] !== undefined) {
    
                for(let i = 0; i < processData["modules_and_inputs"].length; i++){
                    
                    var moduleAndInputfiles = new RPMFile(processData["modules_and_inputs"][i]);
            
                    //check the extension to know where to put the file
                    if(moduleAndInputfiles.getType() === RPMFileType.INPUT_FILE) {
                        apiPlusFileName = inputFilePath + moduleAndInputfiles.getName();
                    }
                    else if(moduleAndInputfiles.checkExt(FILE_STRUCTURE.modules.ext)) {
                        apiPlusFileName = moduleFilePath + moduleAndInputfiles.getName();
                    }
            
                    //call lambda function for getting presigned url, for each modules and input files
                    $.ajax({
                        url: apiPlusFileName,
                        type: "GET",
                        headers: {
                            'Authorization': authorization
                        },
                        contentType: false,
                        success: function( response ) {                         //eslint-disable-line
                            if( response !== "" ) {
                                var resp = response.split('\"');                //eslint-disable-line
                                //upload file to s3 using the presigned url
                                $.ajax({
                                    url: resp[1],
                                    type: "PUT",
                                    data: moduleAndInputfiles.getFile(),
                                    processData: false,
                                    contentType: 'application/octet-stream',
                                    success: function( response ) {
                                        if( response === "" ) {
                                            countSuccessInput++;
                                            if(countSuccessInput === processData["modules_and_inputs"].length) {
                                                //console.log('Files Uploaded!');
                                                countProcessAndModuls++;
                                                if(countProcessAndModuls > 0){
                                                    setStatus(0);
                                                }
                                            }
                                        }
                                    }
                                });
                            }
                        },
                        statusCode: {
                            401: function() {               //eslint-disable-line
                                if(invalidToken === 0){
                                    invalidToken++;
                                    setStatus(-1);
                                }
                            },
                            500: function() {
                                setStatus(500);
                            }
                        }
                    });
                }
            }

            if((processData.processes === null || processData.processes === undefined) && (processData["modules_and_inputs"] === null || processData["modules_and_inputs"] === undefined)) {
                setStatus(0);
                return;
            }
        }*/

        uploadFiles(fileUrls:any, processData:any) {
            let noProcess = processData["process"] === null || processData["process"] === undefined;
            let noModulesAndInputs = processData["modules_and_inputs"] === null || processData["modules_and_inputs"] === undefined;
            let count = 0;
            
            if(!noProcess){
                count += processData["process"].length;
            }
            if(!noModulesAndInputs){
                count += processData["modules_and_inputs"].length;
            }
            if(count === 0){
                return;
            }

            fileUrls = JSON.parse(fileUrls);
            
            let formData: FormData;
            for(let file in fileUrls){
                let f: RPMFileType;
                try{
                    if(!noProcess && processData["pr_names"].includes(file)){
                        let i = getIndex(processData["process"], file);
                        f = new RPMFile(processData["process"][i]).getFile();
                    }
                    else if(!noModulesAndInputs && (processData["in_names"].includes(file) || processData["sp_names"].includes(file))){
                        let i = getIndex(processData["modules_and_inputs"], file);
                        f = new RPMFile(processData["modules_and_inputs"][i]).getFile();
                    }
                    else{
                        setStatus(500);
                        break;
                    }
                }catch(te:any){
                    setStatus(500);
                    break;
                }
                
                formData = new FormData();// eslint-disable-next-line
                Object.keys(fileUrls[file]["fields"]).forEach(key => {
                    formData.append(key, fileUrls[file]["fields"][key]);
                });
                formData.append("file", f);
                
                $.ajax({
                    url: fileUrls[file]["url"],
                    type: "POST",
                    enctype: 'multipart/form-data',
                    data: formData,
                    processData: false,
                    contentType: false,
                    success: function(response) {//eslint-disable-line
                        count--;
                        if(count === 0) {
                            setStatus(0);
                        }
                    },
                    statusCode: {
                        500: function() {
                            setStatus(500);
                        }
                    }
                });
            }
        }
    }));
    
    const getIndex = (fileArray:any, value:any) => {
        for(let i = 0; i < fileArray.length; i++){
            if(fileArray[i]["name"] === value){
                return i;
            }
        }
        return -1;
    }
    
    return(<></>);
});

export default RPMFileUploader