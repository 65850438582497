/**
 * Rulex Project Manager
 *
 * --Projects
 * 
 * @summary Contains the component of the project page.
 * @author Creative Tim (www.creative-tim.com)
 * @edited_by Riccardo Poli, Lorenzo Biasotti
 *
 */

import {Col, Container, Row} from "reactstrap";
import ProjectTable from "../components/Tables/ProjectTable"
import Header from "components/Headers/Header";
import ProjectTabs from "components/Tabs/ProjectTabs";
import { RefObject, useReducer, useRef, useState } from "react";

declare global {
  interface Window {
      Chart:any;
  }
}

/**
 * Project - main page
 *  
 * @return {*} 
 */
const Index = (props) => {

  // State var
  const [selectedProject, setSelectedProject] = useState();
  const [onUpdateTabs, updateTabs] = useReducer(x => x + 1, 0);   //eslint-disable-line
  const [onRefreshTabs, refreshTabs] = useReducer(x => x + 1, 0);   //eslint-disable-line
  // Ref
  const tabsRef = useRef() as RefObject<HTMLDivElement>;

  // scroll to element
  const executeScroll = () => tabsRef.current.scrollIntoView();

  // Move focus on the tabs
  const focusTabs = () => {
    executeScroll();
  }

  return (
    <>
      <Header />
      <Container className="mt-5" fluid>
        <Row>
          <Col>
            <ProjectTable selectedProject = {selectedProject} setSelectedProject = {setSelectedProject} focusTabs = {focusTabs} updateTabs = {updateTabs} refreshTabs={refreshTabs}></ProjectTable>
          </Col>
        </Row>
        <Row>
          <Col className="mt-5">
            <ProjectTabs tabsRef={tabsRef} project={selectedProject} onUpdateTabs={onUpdateTabs} onRefreshTabs={onRefreshTabs}></ProjectTabs>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Index;

/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/