/**
 * Rulex Project Manager
 *
 * --AccountContext
 * 
 * @summary Context used to manage users and sessions
 * @author Riccardo Poli, Lorenzo Biasotti
 *
 */

import React, { createContext } from "react";
import * as AmazonCognitoIdentity from "amazon-cognito-identity-js";
import Pool from "../variables/user-pool-config" 
//import { useHistory } from "react-router-dom";

import {CognitoAuth} from 'amazon-cognito-auth-js-promises';
import RPMEnvManager from "classes/RPMEnvManager";
//import { CookieStorage } from "amazon-cognito-identity-js";

/**
 * Define Account Default Type 
 * @type {*} 
 * 
 */
const contextDefaultValues: AccountContextState = {
    authenticate: async (username: string, password: string) => {},
    getSession: async () => {},
    logout: () => {}
};

/*
*   THIS CONTEXT MANAGE THE SESSION AND THE USER INFOS
*
*/

//const day = 24 * 60 * 60 * 1000;
// const day = 60 * 1000;
// var cookieSession = new CookieStorage({
//     path: '/',
//     domain: RPMEnvManager.getWebsiteDomainName(),
//     expires: Date.now(),
//     secure: true,
//     sameSite: 'strict'
//   })

var userPoolConfig = RPMEnvManager.getUserPoolConfig()

const authData = {
	ClientId : userPoolConfig.app_client_ID, // Your client id here
	AppWebDomain : RPMEnvManager.getCognitoDomainName(),
	TokenScopesArray : ['phone', 'email', 'profile','openid', 'aws.cognito.signin.user.admin'], // e.g.['phone', 'email', 'profile','openid', 'aws.cognito.signin.user.admin'],
	RedirectUriSignIn : 'https://' + RPMEnvManager.getWebsiteDomainName(), //'https://www.rulex.dev.ego.energy',
	RedirectUriSignOut : 'https://' + RPMEnvManager.getWebsiteDomainName(),
	IdentityProvider : 'SAML', // e.g. 'Facebook',
	UserPoolId : userPoolConfig.user_pool_ID, // Your user pool id here
	//AdvancedSecurityDataCollectionFlag : '<TODO: boolean value indicating whether you want to enable advanced security data collection>', // e.g. true
    //Storage:  window.sessionStorage// OPTIONAL e.g. new CookieStorage(), to use the specified storage provided
};


// Define Account context
const AccountContext = createContext<AccountContextState>(contextDefaultValues);

// Account Manager
const Account = (props) => {
    //const history = useHistory();

    // Check if user is logged in
    const getSession = async () => {
        return await new Promise((resolve, reject) => {

            const user = Pool.getCurrentUser();
            //console.log(user)
            if (user) {
                user.getSession((err, session) => {
                if (err) {
                    //console.log(err);
                    //auth.getSession();
                    reject();
                } else {
                    resolve(session);
                }
                });
            } else {
                //history.push("/auth");
                //console.log(auth.getCurrentUser())
                var url = window.location.href;
                url = url.replace("#", "");

                auth.parseCognitoWebResponse(url).then((resp) => {
    
                    //console.log(resp)
        
                    if(resp === undefined){
                        console.log("START")
                        auth.getSession();
                        reject();
                    }
                    //console.log(resp.accessToken.jwtToken.split('.')[1])
                    //console.log(resp.accessToken)
                    // create a CognitoAccessToken using the response accessToken
                    const AccessToken = new 
                    AmazonCognitoIdentity.CognitoAccessToken({ 
                        AccessToken: resp.accessToken.jwtToken, 
                    });
    
                    // create a CognitoIdToken using the response idToken   
                    const IdToken = new AmazonCognitoIdentity.CognitoIdToken({    
                        IdToken: resp.idToken.jwtToken, 
                    });
                
                    // create a RefreshToken using the response refreshToken 
                    const RefreshToken = new    
                    AmazonCognitoIdentity.CognitoRefreshToken({ 
                        RefreshToken: resp.refreshToken.refreshToken, 
                    });
                
                    // create a session object with all the tokens
                    const sessionData = { 
                        IdToken: IdToken, 
                        AccessToken: AccessToken, 
                        RefreshToken: RefreshToken, 
                    };
    
                    // create the CognitoUserSession using the sessionData
                    const session = new AmazonCognitoIdentity.CognitoUserSession(     
                        sessionData 
                    );
                
                    // create an object with the UserPoolId and ClientId 
                    var poolData = { 
                        UserPoolId: Pool.getUserPoolId(), 
                        ClientId: Pool.getClientId(), 
                    };
                
                    // pass the poolData object to CognitoUserPool 
                    var userPool = new AmazonCognitoIdentity.CognitoUserPool( 
                        poolData 
                    );
    
                    // create an object containing the username and user pool. 
                    // You can get the username from CognitoAccessToken object 
                    // we created above.
                    var userData = { 
                        Username: AccessToken.payload.username, 
                        Pool: userPool, 
                    };
                
                    // create a cognito user using the userData object 
                    var cognitoUser = new AmazonCognitoIdentity.CognitoUser( 
                        userData 
                    );
                
                    // set the cognito user session w/ the CognitoUserSession 
                    cognitoUser.setSignInUserSession(session);
                    //console.log(Pool.getCurrentUser())
                    console.log(cognitoUser)
                    //console.log(cognitoUser.getSignInUserSession())
                    resolve(cognitoUser.getSignInUserSession());
                    //history.push("/admin");
                    window.location.reload();
                });
            }
        });
    };

    // login user
    const authenticate = async (Username, Password) => {
        return await new Promise((resolve, reject) => {
            /*const user = new CognitoUser({ Username, Pool });

            const authDetails = new AuthenticationDetails({ Username, Password });

            user.authenticateUser(authDetails, {
                onSuccess: (data) => {
                resolve(data);
                history.replace("/admin");
                },
                onFailure: (err) => {
                reject(err);
                },
                newPasswordRequired: (data) => {
                resolve(data);
                },
            });*/
        });
    };

    //logout
    const logout = () => {
        auth.signOut();
        const user = Pool.getCurrentUser();
        if (user) {
            user.signOut();
        }
        //console.log(user);
    };

    //Remember me
   /* window.onbeforeunload = function() {
        if(sessionStorage.getItem("singleSession") !== "true" && localStorage.getItem("rememberMe") !== "true"){
            localStorage.clear();
        }
    };*/
    
    var auth = new CognitoAuth(authData);
    auth.useCodeGrantFlow();
    /*auth.userhandler = {
        onSuccess: function(result) {
            alert("Sign in success");
            console.log(result);
        },
        onFailure: function(err) {
            alert("Error!");
        }
    };*/

    return (
        <AccountContext.Provider value={{ authenticate, getSession, logout }}>
        {props.children}
        </AccountContext.Provider>
    );
};
export { Account, AccountContext };