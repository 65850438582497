/**
 * Rulex Project Manager
 *
 * --routes
 * 
 * @summary Define the pages and the links of the app.
 * @author Creative Tim (www.creative-tim.com)
 * @edited_by Riccardo Poli, Lorenzo Biasotti
 *
 */

import { CodeOutlined, ContainerOutlined } from "@ant-design/icons";
import Index from "views/Projects";
import Login from "views/Login";
import Processes from "views/Processes";

/** 
 * Routes of the website
 * @type {*} 
 * 
 */
var routes = [
  {
    "path": "/index",
    "name": "Projects",
    "title": "Project Manager",
    "icon": <ContainerOutlined className="sidebar-img"/>,
    "component": Index,
    "layout": "/admin",
  },
  {
    "path": "/processes",
    "name": "Processes",
    "title": "Process Manager",
    "icon": <CodeOutlined className="sidebar-img"/>,
    "component": Processes,
    "layout": "/admin",
  },
  {
    "path": "/login",
    "name": "Login",
    "title": "Login",
    "icon": "ni ni-key-25 text-primary",
    "component": Login,
    "layout": "/auth",
  }
];
export default routes;

/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/