/**
 * Rulex Project Manager
 *
 * --ProcessTable
 * 
 * 
 * @summary Define the component for the process table.
 * @author Riccardo Poli, Lorenzo Biasotti
 *
 */

import { Button, Col, Input, Modal, Radio, Row, Space, Spin, Table, Tag} from "antd";
import { AccountContext } from "../../contexts/AccountContext";
import { useContext, useEffect, useReducer, useRef, useState } from "react";
import { useLocation } from 'react-router-dom';
import { Card, CardFooter, CardHeader, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown} from "reactstrap"
import Highlighter from 'react-highlight-words';
import $ from "jquery";
import ProcessWizard from "../Wizards/ProcessWizard";
import { CheckCircleOutlined, ClockCircleOutlined, CloseCircleOutlined, CloseOutlined, ExclamationCircleOutlined, ExpandAltOutlined, LoadingOutlined, ReloadOutlined, SearchOutlined, SyncOutlined } from "@ant-design/icons/lib/icons";
import { EventContext } from "contexts/EventContext";
import { RPMEventType } from "types/RPMEventType";
import { compareDateTime, displayLoadingMsg, formatDateTime, sleep } from "scripts/functions";
import RPMMsgParser from "classes/RPMMsgParser";
import { WebSocketContext } from "contexts/WebSocketContext";
import RPMEnvManager from "classes/RPMEnvManager";
import { SettingsContext } from "contexts/SettingsContext";
import { openLocalNotification } from "components/Notifications/Notification";

/**
 * Interface to define the form props
 *
 * @interface IProps
 */
interface IProps {
  selectedProcess:any
  setSelectedProcess(sProcess:any):void;
  focusTabs():void;
  updateTabs():void;
  refreshTabs():void;
  location?:any
}

/**
 * Process Table
 * 
 * @param props
 * @returns 
 */
const ProcessTable = (props:IProps) => {  
  
  // Vars
      // Add here columns
  const FIELDS = ["process", "description", "project", "owner", "rulex_version", "last_execution", "type", "scheduled", "status"];
      // Add here finite filters on columns
  const FILTERS = {
    "type":       ["not deployed", "staging", "prod"],
    "scheduled":  ["yes", "no"],
  };
  // Add here status namespace
  const STATUS = {
    "":            {"color": "default",     "text": "IDLE",     "icon": false},
    "idle":        {"color": "default",     "text": "IDLE",     "icon": false},
    "loading":     {"color": "default",     "text": "LOADING",  "icon": <ClockCircleOutlined />},
    "pending":     {"color": "warning",     "text": "PENDING",  "icon": <ClockCircleOutlined />},
    "running":     {"color": "processing",  "text": "RUNNING",  "icon": <SyncOutlined spin />},
    "warning":     {"color": "warning",     "text": "WARNING",  "icon": <ExclamationCircleOutlined />},
    "finished":    {"color": "success",     "text": "FINISHED", "icon": <CheckCircleOutlined />},
    "error":       {"color": "error",       "text": "ERROR",    "icon": <CloseCircleOutlined />},
  }
  // State Vars
  const [dataSource, setDataSource] = useState([]);
  const [dataBackup, setDataBackup] = useState([]); 
  const [columns, setColumns] = useState([]);  
  const [searchText, setSearchText] = useState("");
  const [searchedColumns, setSearchedColumns] = useState("");
  const [visibleProcessWizard, setVisibleProcessWizard] = useState(false);
  const [tableLoading, setTableLoading] = useState(true);
  const [deployData, setDeployData] = useState({});
  const [isModalRunTaskVisible, setIsModalRunTaskVisible] = useState(false);
  const [selectedRow, setSelectedRow] = useState(["-1"]); //put here default highlighted.
  const [isModalCloneInProductionVisible, setIsModalCloneInProductionVisible] = useState(false);
  const [getRecord, setRecord] = useState();
  const [warningCloneInProd, setWarningCloneInProd] = useState(true);
  const [closable, setClosable] = useState(true);
  const [user, setUser] = useState();
  const [isModalUnlockProjectVisibleDeploy, setIsModalUnlockProjectVisibleDeploy] = useState(false);
  const [isModalUnlockProjectVisibleEdit, setIsModalUnlockProjectVisibleEdit] = useState(false);
  const [isModalUnlockProjectVisibleClone, setIsModalUnlockProjectVisibleClone] = useState(false);
  const [tempRecord, setTempRecord] = useState();
  const [loadingButton, setLoadingButton] = useState(false);
  const [filterLocationActive, setFilterLocationActive] = useState(false);
  // Updater
  const [ignored, forceUpdate] = useReducer(x => x + 1, 0);   //eslint-disable-line
  // Ref
  const searchInput = useRef(null);
  // Context
  const { getSession } = useContext(AccountContext);   //eslint-disable-line
  const { onEvent, getEvent, newEvent } = useContext(EventContext);
  const { addUserProcess, saveNewTableView, getSettings } = useContext(SettingsContext);
  const { messageRunTask, checkStatusStack } = useContext(WebSocketContext); 
  const location = useLocation();
  //Spin
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  let loading = false;
  let loadingData = {"projet":"", "process":"", "type":""};
  let selectedTableView = getSettings()['table_view'] as String;

  enum typeClick {DEPLOY=0, EDIT=1, CLONE=2};

  // Effect on data set
  useEffect(() => {
    setTableLoading(false);
    setLoadingButton(false);
  }, [dataSource]); //eslint-disable-line

  // Effect on data set
  useEffect(() => {
    updateSelectedRow();
    getSession().then((session: { idToken: any; }) => {
      let email = session.idToken.payload.email;
      let processOfUser = []
      dataBackup.forEach(row => {
        if(row["owner"] === email || row["collaborators"].includes(email)) {
          processOfUser.push(row["project"] + "||" + row["process"]);
        }
      });
      addUserProcess(processOfUser);
    }).catch((err) => {
     //console.log(err);
    });
  }, [dataBackup]); //eslint-disable-line

  // Effect on event
  useEffect(() => {
    if(onEvent !== RPMEventType.IDLE) {
      if( getEvent() === RPMEventType.CLOSE_PROJECT_WIZARD            || 
          getEvent() === RPMEventType.CLOSE_PROCESS_WIZARD            ||
          getEvent() === RPMEventType.END_BUILD_PROJECT               ||
          getEvent() === RPMEventType.END_EDIT_PROJECT                ||
          getEvent() === RPMEventType.END_DEPLOY_PROCESS              ||
          getEvent() === RPMEventType.END_EDIT_PROCESS                ||
          getEvent() === RPMEventType.END_CLONE_PRODUCTION            ||
          getEvent() === RPMEventType.END_FAST_CLONE_PRODUCTION       ||
          getEvent() === RPMEventType.END_DELETE_PROJECT              ||
          getEvent() >= 400) {
        
        let currEvent = getEvent()
        let removeFilter = (currEvent === RPMEventType.CLOSE_PROJECT_WIZARD) ? true : false;
        updateTableData(removeFilter);
      }
    }
  }, [onEvent]);  // eslint-disable-line react-hooks/exhaustive-deps

  // Effect on change location 
  useEffect(() => {
    //TODO: rimuovere la parte commentata
    //if (location !== null && location.query !== undefined && location.query.skip === true) {
      // console.log("location")
      // console.log(location)
      // updateTableData(true);
    //}
    /*else*/ if (location !== null && location.query !== undefined && location.query.active !== undefined) {
      setFilterLocationActive(location['query']['active']);
      setSelectedRow(['0']);
    }
  }, [location]); // eslint-disable-line react-hooks/exhaustive-deps

  // Effect on the first render
  useEffect(() => {
    if(user === undefined || user === null){
      getSession().then((session: { idToken: any; accessToken: any;}) => {
        setUser(session.idToken.payload.email);
      });
    }
    else{
      setTableLoading(true);
      getColumns();
      //console.log("user")
      updateTableData();
    }
  }, [user]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (messageRunTask !== "") {
      handleMsgs(messageRunTask, "run_task");
    }
  }, [messageRunTask]); // eslint-disable-line react-hooks/exhaustive-deps

  // Handle the data coming from the child element
  const handleCallback = (childData:any, childDataName:string) => {
    if(childDataName === "completed" && childData === true) {
      closeProcessWizard();
    }
    else if(childDataName === "locked") {
      setClosable(! childData);
    }
  }

  // Handle the msgs from websocket
  const handleMsgs = (msg:string, msgType:msgsType) => {

    let msgParser = new RPMMsgParser(msgType);
    let msgData = msgParser.getMsgData(msg);

    let project = msgData["project"];
    let process = msgData["process"];
    let type = msgData["type"];
    let status = msgData["status"];
    let executionDateTime = msgData['executionDateTime'];

    for (const [i, value] of Object.entries(dataBackup)) { // eslint-disable-line
      if(value["project"] === project && value["process"] === process && value["type"] === type) {
        value["status"] = status;
        if(status === "finished" || status === "finished") {
          value["last_execution"] = executionDateTime.replace(" ","T") + "Z"
        }
        setDataSource([...dataBackup]);
        setDataBackup([...dataBackup]);
        forceUpdate();
      }
    }
  }

  // Event on change table
  function onChangeTable(pagination, filters, sorter, extra) {
    //console.log('params', pagination, filters, sorter, extra);
  }

  // On select row
  const onRowSelected = (selectedRowData, move) => {
    setSelectedRow([selectedRowData.key]);
    props.setSelectedProcess(selectedRowData);
    if(move) {
      props.focusTabs();
    }
    props.updateTabs();
  };

  // Update child tabs
  const updateSelectedRow = () => {
    props.setSelectedProcess(dataBackup[Number(selectedRow)]);
    props.refreshTabs();
  }

  // Handle click on delete project button
  const clickUnlockProjectDeploy = () => {
    setIsModalUnlockProjectVisibleDeploy(false);
    let record = tempRecord as {};
    if(record !== undefined) {
      deployProcess(record);
    }
  };
  const clickUnlockProjectEdit = () => {
    setIsModalUnlockProjectVisibleEdit(false);
    let record = tempRecord as {};
    if(record !== undefined) {
      edit(record);
    }
  };
  const clickUnlockProjectClone = () => {
    setIsModalUnlockProjectVisibleClone(false);
    let record = tempRecord as {};
    if(record !== undefined) {
     showModalConfirmCloneInProduction(record);
    }
  };

  const setLoading = (status:boolean, projectName:string, processName:string, processType:string) => {
    loading = status;
    loadingData["project"] = projectName;
    loadingData["process"] = processName;
    loadingData["type"] = processType;
    getColumns();
  }

  // Event on click deploy process
  const clickDeployProcess = (record: any) => {
    setLoading(true, record["project"], record["process"], record["type"]);
    checkConcurrencyBeforeUpdate(record, typeClick.DEPLOY);
  }

  // Event on click edit process
  const clickEditProcess = (record: any) => {
    setLoading(true, record["project"], record["process"], record["type"]);
    checkConcurrencyBeforeUpdate(record, typeClick.EDIT);
  }

  // Event on click clone process
  const clickCloneProcess = (record: any) => {
    setLoading(true, record["project"], record["process"], record["type"]);
    checkConcurrencyBeforeUpdate(record, typeClick.CLONE);
  }

  // edit process
  const modalManager = (lock: {}, record: any, modal:typeClick) => {

    if(lock["onLock"] === false) {
      if(modal === typeClick.DEPLOY){
        deployProcess(record);
        setVisibleProcessWizard(true);
      }
      else if(modal === typeClick.EDIT){
        setVisibleProcessWizard(true);
        edit(record)
      }
      else if(modal === typeClick.CLONE){
        showModalConfirmCloneInProduction(record)
      }
    }
    else if(lock["onLock"]) {

      const deltaMin = 30;

      let lt = lock["lockTime"];

      let currentDate = new Date();
      let lockTime = new Date(lt + 'z');

      const diffTime = Math.abs(currentDate.valueOf() - lockTime.valueOf()).toString().slice(0,-3);
      const diffTimeMin = Math.floor(parseInt(diffTime) / 60);

      if(diffTimeMin <= deltaMin) {
        openLocalNotification("error","edit_project","409");
      }
      else {
        // Show modal
        if(modal === typeClick.DEPLOY){
          setIsModalUnlockProjectVisibleDeploy(true);
          setTempRecord(record);
        }
        else if(modal === typeClick.EDIT){
          setIsModalUnlockProjectVisibleEdit(true);
          setTempRecord(record);
        }
        else if(modal === typeClick.CLONE){
          setIsModalUnlockProjectVisibleClone(true);
          setTempRecord(record);
        }
      }
    }
  }

  // Event on click edit process
  const edit = (record) => {

    let data = {
      "edit_mode":                 true,
      "pcs_name":                 record["process"],
      "pcs_file":                 record["file_name"].toString().split(".")[0],
      "pj_name":                  record["project"],
      "rlx_vrs":                  record["rulex_version"],
      "pcs_cpu":                  record["cpu"],
      "pcs_memory":               record["memory"],
      "pcs_scheduled":            record["scheduled"],
      "pcs_scheduled_expression":  record["schedule_expression"],
      "pcs_stack_name":            record["stack_name"],
      "pcs_schedule_ID":           record["schedule_ID"],
      "pcs_env":                  record["type"],
    };
    setDeployData(data);
    openProcessWizard("edit_process");
  }

  // Check lock on project
  const checkConcurrencyBeforeUpdate = (record:any, modal:typeClick) => {
    
    getSession().then((session: { accessToken: any; }) => {
      let authorization = 'Bearer ' + session.accessToken.jwtToken;
      let api = RPMEnvManager.getApiLambda("getConcurrencyLock");
      
      let data = {"projectName": record["project"]};
      let dataToSend = JSON.stringify(data);

      $.ajax({
        url: api,
        type: "POST",
        headers: {
            'Authorization': authorization
        },
        contentType: 'application/json',
        data: dataToSend,
        processData: false,
        dataType: 'text',
        success: function( response ) {
            let lock = JSON.parse(response);
            setLoading(false, record["project"], record["process"], record["type"]);
            modalManager(lock, record, modal);
        },
        statusCode: {
          401: function() {
            //console.log("Invalid Token.");
            openLocalNotification("error","db_data","401");
            setLoading(false, record["project"], record["process"], record["type"]);
          },
          500: function() {
            openLocalNotification("error","db_data","500");
            setLoading(false, record["project"], record["process"], record["type"]);
          }
        }
      });
    });
  };

  // Event on click deploy process
  const deployProcess = (record) => {

    let data = {
      "edit_mode":     false,
      "pcs_name":     record["process"],
      "pcs_file":     record["file_name"].toString().split(".")[0],
      "pj_name":      record["project"],
      "rlx_vrs":      record["rulex_version"]
    };
    setDeployData(data);
    openProcessWizard("deploy_process");
  }

  // Event on click run task
  const runTask = (record) => {
    //console.log("Start running");

    if(record.task_definition_name === undefined){   
      //console.log("Process not deployed");
      return;
    }
    getSession().then((session: { accessToken: any; }) => {
      let authorization = 'Bearer ' + session.accessToken.jwtToken;
      let api = RPMEnvManager.getApiLambda("runTask");

      let data = {"projectName": record.project, "taskDefinition": record.task_definition_name}
      let dataToSend = JSON.stringify(data);
      
      newEvent(RPMEventType.START_RUN_TASK);
      displayLoadingMsg("Running task " + record.process + " ..");

      $.ajax({
        url: api,
        type: "POST",
        headers: {
            'Authorization': authorization
        },
        contentType: 'application/json',
        data: dataToSend,
        processData: false,
        success: function( ) {
            //console.log("Run ok");
            for (const [, value] of Object.entries(dataBackup)) {  // eslint-disable-line
              if(value["project"] === record["project"] && value["process"] === record["process"] && value["type"] === record["type"]){
                value["status"] = "loading";
                setDataSource([...dataBackup]);
              }
            }
        },
        statusCode: {
          401: function() {
            //console.log("Invalid Token.");
            newEvent(RPMEventType.UNAUTH_RUN);
            openLocalNotification("error","run_task","401");
          },
          409: function(response) {
            newEvent(RPMEventType.CONCURRENCY_RUN);
            openLocalNotification("error","run_task","409");
          },
          500: function(response) {
            //console.log(response.responseText);
            newEvent(RPMEventType.ERROR_RUN);
            openLocalNotification("error","run_task","500");
          }
        }
      });
    });
  }

  // Event on click clone to production
  const cloneInProduction = (record) => { 
    //console.log("Cloning...");

    let data = {"projectName": record.project, "processName": record.process};
    let dataToSend = JSON.stringify(data);
    checkStatusStack(record.process, record.project);

    getSession().then((session: { accessToken: any; }) => {
      let authorization = 'Bearer ' + session.accessToken.jwtToken;
      let api = RPMEnvManager.getApiLambda("cloneInProduction");

      newEvent(RPMEventType.START_CLONE_PRODUCTION);
      displayLoadingMsg("Cloning Process " + record.process + " ..");

      $.ajax({
        url: api,
        type: "POST",
        headers: {
            'Authorization': authorization
        },
        contentType: 'application/json',
        data: dataToSend,
        processData: false,
        success: function( response ) {
            //console.log("Clone ok");
            if(response === "Only files updated") {
              newEvent(RPMEventType.END_FAST_CLONE_PRODUCTION);
              openLocalNotification("success","clone_process");
            }
        },
        statusCode: {
            401: function() {
                //console.log("Invalid Token.");
                newEvent(RPMEventType.UNAUTH_CLONE);
                openLocalNotification("error","clone_process","401");
            },
            409: function() {
              newEvent(RPMEventType.CONCURRENCY_CLONE);
              openLocalNotification("error","clone_process","409");
            },
            500: function( response ) {
              //console.log(response.responseText);
              newEvent(RPMEventType.ERROR_CLONE);
              openLocalNotification("error","clone_process","500");
            }
        }
      });
    });
  }

  // Show modal to confirm the run
  const showModalConfirmRunTask = (record) => {
    setIsModalRunTaskVisible(true);
    setRecord(record);
  };

  // Onclick button handler for run task
  const clickRunTask = () => {
    setIsModalRunTaskVisible(false);
    runTask(getRecord)
  };

  // Show the modal to confirm the clone to production
  const showModalConfirmCloneInProduction = (record) => {
    setIsModalCloneInProductionVisible(true);
    setRecord(record);
    if(record["num_process"] > 1){
      setWarningCloneInProd(false);
    }
    else{
      setWarningCloneInProd(true);
    }
  };

  // Handle click on clone to production
  const clickCloneInProduction = () => {
    setIsModalCloneInProductionVisible(false);
    cloneInProduction(getRecord)
  };

  // Handle cancel on modal clone to production
  const handleCancel1 = () => {
    setIsModalRunTaskVisible(false);
    setIsModalCloneInProductionVisible(false);
  };

  // Handle cancel on unlock project modal
  const handleCancel2 = () => {
    setIsModalUnlockProjectVisibleDeploy(false);
    setIsModalUnlockProjectVisibleEdit(false);
    setIsModalUnlockProjectVisibleClone(false);
  };

  // Open the wizard
  const openProcessWizard = (type:msgsType) => {
    setVisibleProcessWizard(true);
    if(type === "deploy_process") {
      newEvent(RPMEventType.OPEN_PROCESS_WIZARD_NEW);
    }
    else if (type === "edit_process") {
      newEvent(RPMEventType.OPEN_PROCESS_WIZARD_EDIT);
    }
  };

  // Closes the wizard
  const closeProcessWizard = async () => {
    setVisibleProcessWizard(false);
    newEvent(RPMEventType.CLOSE_PROCESS_WIZARD);
    await sleep(500);
    let btnPrevius = document.querySelectorAll('.btn-previous') as NodeListOf<HTMLElement>;
    btnPrevius.forEach(el => {
      el.style.display = ("block");
    });
  };

  // Setup of the columns of the table
  const getColumns = () => {

    let col = [];

    FIELDS.forEach(field => {

      if (field === "owner" && selectedTableView === "1") {
        col.push({
          "title":            field.replace("_", " "),
          "dataIndex":        field,
          "key":              ("key" + FIELDS.indexOf(field))
        });
      }
      else if(field in FILTERS) { // with filter tool
      
        let newFilter = new Array<{}>();
        FILTERS[field].forEach(option => {
          newFilter.push({
            text: option,
            value: option            
          });
        });
       
        col.push({
        
          "title":            field.replace("_", " "),
          "dataIndex":        field,
          "key":              ("key" + FIELDS.indexOf(field)),
          "sorter": {
            "compare":          (a, b) => a[field].localeCompare(b[field]),
          },
          "filters":          newFilter,
          "onFilter":         (value, record) => record[field].indexOf(value) === 0,
        });
      }
      else {  // with search tool
        col.push({
        
          "title":            field.replace("_", " "),
          "dataIndex":        field,
          "key":              ("key" + FIELDS.indexOf(field)),
          "sorter": {
              "compare":        (a, b) => a[field].localeCompare(b[field]),
          },
          ...getColumnSearchProps(field),
        });
      }
    
      if(field === "last_execution") {
        col[col.length-1]["render"] = (value:string) => formatDateTime(value);
        col[col.length-1]["sorter"] = (a, b) => compareDateTime(a[field], b[field]);
      }
      if(field === "process") {
        col[col.length-1]["fixed"] = "left";
      }
      else if(field === "description") {
        //col[col.length-1]["responsive"] = ["xl"];
      }
      else if(field === "rulex_version") {
        col[col.length-1]["responsive"] = ["xxl"];
      }
      if(field === "scheduled" || field === "type" || field === "status" || field === "rulex_version" || field === "last_execution") {
        col[col.length-1]["align"] = "center";
      }
      // Add symbols to status column
      if(field === "status") {
        col[col.length-1]["render"] = (fieldV) => (
          <>
            < Tag icon={STATUS[fieldV]["icon"]} color={STATUS[fieldV]["color"]}>{STATUS[fieldV]["text"]}</Tag>
          </>
        );
      }
    });
  

    // Add menu columns
    col.push({

      "title":          '',
      "key":            'action',
      "width":          '60px',
      "render":         (text, record) => (
                          <UncontrolledDropdown disabled={record.owner !== user && !(record.collaborators.includes(user))}> 
                            <DropdownToggle
                              className="btn-icon-only text-black"
                              role="button"
                              size="sm"
                              color=""
                              onClick={(e) => e.preventDefault()}
                              hidden={loading && record.project === loadingData["project"] && record.process === loadingData["process"] && record.type === loadingData["type"]}
                            >
                              <i className="fas fa-ellipsis-v" />
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-arrow drop-menu" right>
                              <DropdownItem hidden={record.type === "not deployed"}
                                onClick={
                                  () => clickEditProcess(record)
                                }
                              >
                                Edit
                              </DropdownItem>
                              <DropdownItem hidden={record.type !== "not deployed"}
                                onClick={
                                  () => clickDeployProcess(record)
                                }
                              >
                                Deploy Process
                              </DropdownItem>
                              <DropdownItem hidden={record.type === "not deployed"} disabled={record.status !== "error" && record.status !== "finished" && record.status !== "idle"}
                                onClick={
                                  () => showModalConfirmRunTask(record)
                                }
                              >
                                Run Task
                              </DropdownItem>
                              <DropdownItem hidden={record.type !== "staging"}
                                onClick={
                                  () => clickCloneProcess(record)
                                }
                              >
                                Clone In Production
                              </DropdownItem>
                            </DropdownMenu>
                            <Spin spinning={loading && record.project === loadingData["project"] && record.process === loadingData["process"] && record.type === loadingData["type"]} indicator={antIcon} />
                          </UncontrolledDropdown>
                        )
    });

    setColumns(col); 
  }

  // Getting table data from db
  const getTableDataDB = (token:string) => {
    
    let authorization = 'Bearer ' + token;
    let api = RPMEnvManager.getApiLambda("getItems");
    
    $.ajax({
      url: api,
      type: "POST",
      headers: {
          'Authorization': authorization
      },
      contentType: 'text/plan',
      data: selectedTableView,
      success: function( response ) {
        if( response !== "" || response !== "[]") {
          let data = [];
          let key = 0;

          for (let i = 0; i < response.length; i++) {
            let iData = response[i];

            if(filterLocationActive === true) {
                if(location['query']['project'] !== iData.projectName) {
                  continue;
                }
            }

            for (var prc in iData.processList){

              if("tempData" in iData.processList[prc] && Object.keys(iData.processList[prc]).length === 1) {
                continue;
              }
              
              //remove '-prod' from process name
              let processName = prc;
              if(iData.processList[prc].taskType === "prod"){
                processName = prc.substring(0, prc.length-5);
              }

              if(filterLocationActive === true) {
                if(location['query']['process'] !== prc) {
                  continue;
                }
              }

              let scheduled = (undefined !== iData.processList[prc].taskSchedule) &&
                              ("cron()"  !== iData.processList[prc].taskSchedule ) ? "yes": "no";

              let deployed = iData.processList[prc].taskType === "not deployed" ? "no" : "yes";
              
              let lastExecution :  string = "--";
              if ("lastExecution" in iData.processList[prc]) {
                lastExecution = iData.processList[prc]["lastExecution"].replace(" ","T") + "Z"
              }
              
              let deployedTime : string = "--";
              if(iData.processList[prc].deployedTime !== undefined){
                let d = iData.processList[prc].deployedTime.replace(" ","T") + "Z" //new Date(iData.processList[prc].deployedTime + "Z");
                deployedTime = d //("0" + d.getDate()).slice(-2) + "-" + ("0"+(d.getMonth()+1)).slice(-2) + "-" + d.getFullYear() + " " + ("0" + d.getHours()).slice(-2) + ":" + ("0" + d.getMinutes()).slice(-2);
              }
              
              let rulexVersion = iData.projectRulexVersion;
              if(iData.processList[prc].rulexVersion !== undefined){
                rulexVersion = iData.processList[prc].rulexVersion;
              }
              data.push({
                "key":                    (key++).toString(),
                "process":                processName,
                "description":            iData.processList[prc].processDescription,
                "project":                iData.projectName,
                "owner":                  iData.projectOwner,
                "rulex_version":          rulexVersion,
                "last_execution":         lastExecution,
                "type":                   iData.processList[prc].taskType,
                "deployed":               deployed,
                "deployed_by":            iData.processList[prc].deployedBy,
                "deployed_time":          deployedTime,
                "scheduled":              scheduled,
                "status":                 iData.processList[prc].taskStatus,
                "file_name":              iData.processList[prc].processFile,
                "task_definition_name":   iData.processList[prc].taskDefinitionName,
                "schedule_expression":    iData.processList[prc].taskSchedule,
                "cpu":                    iData.processList[prc].taskCpu,
                "memory":                 iData.processList[prc].taskMemory,
                "stack_name":             iData.processList[prc].stackName,
                "schedule_ID":            iData.processList[prc].scheduleId,
                "collaborators":          iData.projectCollaborators,
                "num_process":            iData.processFileNames.length
              });

            }
          }

          if(filterLocationActive === true) {
            onRowSelected(data[0], false);
          }
          
          
          setDataSource(data);
          setDataBackup(data);
          setFilterLocationActive(false);
          forceUpdate();
          /*console.log(dataSource);
          dataBackup = data;
          //console.log(dataBackup[0].scheduled);
          dataBackup[0].scheduled = "yes";
          setTimeout(() => { console.log(dataBackup); setDataSource([...dataBackup]); }, 2000);*/
        }  
      },
      statusCode: {
          401: function() {
            openLocalNotification("error","db_data","401",0);
          },
          500: function() {
            openLocalNotification("error","db_data","500",0);
          }
      }
    });
  } 

  // Setup search bar for columns
  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
            <Input
                 ref={ searchInput }
                 placeholder={`Search ${dataIndex}`}
                 value={selectedKeys[0]}
                 onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                 onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                 style={{ marginBottom: 8, display: 'block' }}
            />
            <Space>
            <Button
                 type="primary"
                 onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                 icon={<SearchOutlined />}
                 size="small"
                 style={{ width: 90 }}
            >
                Search
            </Button>
            <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                Reset
            </Button>
            <Button
                 type="link"
                 size="small"
                 onClick={() => {
                     confirm({ closeDropdown: false });
                     setSearchText(selectedKeys[0]);
                     setSearchedColumns(dataIndex);
                 }}
            >
                Filter
            </Button>
            </Space>
        </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
            formatDateTime(record[dataIndex])
                ? (formatDateTime(record[dataIndex].toString()).toLowerCase().includes(value.toLowerCase()))
                : '',
    onFilterDropdownVisibleChange: visible => {
        if (visible) {    
            setTimeout(() => searchInput.current.select());   
        }
    },
    render: text =>
        searchedColumns === dataIndex ? (
            <Highlighter
            highlightStyle={{ backgroundColor: 'white', padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text ? text.toString() : ''}
            />
        ) : (
            text
        ),
  });

  // Handle search on the table
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
      confirm();
      setSearchText(selectedKeys[0]);
      setSearchedColumns(dataIndex);
  };

  // Handle reset on search filter of the table
  const handleReset = clearFilters => {
      clearFilters();
      setSearchText("");
  };

  // Update table data
  const updateTableData = (removeFilter=false) => {
    setTableLoading(true);
    if(removeFilter) {
      if (location.query !== undefined && location.query.active === true)
      location.query.active = false;
      setFilterLocationActive(false)
      setSelectedRow(['-1']);
    }
    getSession().then((session: { idToken: any; accessToken: any;}) => {
      getTableDataDB(session.accessToken.jwtToken);
    });
  }

  // Handle change on header radio buttons
  const onChangeHeaderRB = (e) => {
    //console.log(e.target.value)
    saveNewTableView(e.target.value)
    selectedTableView = e.target.value
    getColumns();
    updateTableData(true);
  };

  // Handle change on header refresh button
  const refreshTable = () => {
    getColumns();
    setLoadingButton(true);
    updateTableData(true);
  };

  // Return
  return (
    <>
    <Modal
      centered
      visible={visibleProcessWizard}
      onOk={() => setVisibleProcessWizard(false)}
      onCancel={() => closeProcessWizard()}
      width={"700px"}
      closable={closable}
      closeIcon={<CloseOutlined style={{color: "white"}}/>}
      destroyOnClose={true}
      maskClosable={false}
      footer={null}
      bodyStyle={{padding:0, overflow:""}}
    >
      <ProcessWizard key="wizard" parentCallback={handleCallback} processData={deployData}></ProcessWizard>
    </Modal>
    <Card className="shadow pl-2 pr-2 border">
      <CardHeader className="bg-transparent header-tables" align="middle">
        <Row align="middle">
          <Col flex="auto">
            <Radio.Group defaultValue={getSettings()['table_view']} onChange={onChangeHeaderRB} buttonStyle="outline">
              <Radio.Button className="ml-1 mr-1 mt-1 mb-1" value="ONLY_USER_PROJECTS">Show only user processes</Radio.Button>
              <Radio.Button className="ml-1 mr-1 mt-1 mb-1" value="USER_AND_COLLABORATORS_PROJECTS">Show user and collaborators processes</Radio.Button>
              <Radio.Button className="ml-1 mr-1 mt-1 mb-1" value="ALL_PROJECTS">Show all processes</Radio.Button>
            </Radio.Group>
          </Col>
          <Col flex="100px">
            <Button
              shape="round"
              className="btn-default-refresh"
              icon={<ReloadOutlined />}
              size={"middle"}
              loading={loadingButton}
              onClick={() => refreshTable()}
            ></Button>
          </Col>
        </Row>
      </CardHeader>
      <Table 
        className="" 
        scroll={{ x: '1000px' }}
        dataSource={dataSource} 
        columns={columns} 
        rowSelection={{
          type: "radio",
          selectedRowKeys: selectedRow,
          onSelect: (row) => onRowSelected(row, false),
        }} 
        onRow={(record) => {
          return {
            onClick: () => {onRowSelected(record, false)},       // click row
            onDoubleClick: () => {onRowSelected(record, true)},  // double click row
            onContextMenu: () => {},                             // right button click row
            onMouseEnter: () => {},                              // mouse enter row
            onMouseLeave: () => {},                              // mouse leave row
          }
        }}
        loading={tableLoading} 
        onChange={onChangeTable}
      />
      <CardFooter className="bg-transparent">
        <Row justify="center">
          <Col>
            <Button
              hidden={location.query === undefined || location.query.active !== true}
              shape="round"
              className="button-show-all-processes"
              icon={<ExpandAltOutlined />}
              size={"middle"}
              onClick={() => refreshTable()}
            >Reset filter
            </Button>
          </Col>
        </Row>
      </CardFooter>
    </Card>
    <Modal title="Run Task" visible={isModalRunTaskVisible} onOk={clickRunTask} onCancel={handleCancel1}>
      <p>Are you sure you want to run the task?</p>
    </Modal>
    <Modal title="Clone In Production" visible={isModalCloneInProductionVisible} onOk={clickCloneInProduction} onCancel={handleCancel1}>
      <p>Are you sure you want to clone the process in production?</p>
      <p style={{ fontSize:'14px' }} hidden={warningCloneInProd}><strong>WARNING</strong>: There is more than one process in this project, remember to keep consistency with files shared between processes in productions.</p>
    </Modal>
    <Modal className="modal-unlock-project" title="Deploy Process" visible={isModalUnlockProjectVisibleDeploy} onOk={clickUnlockProjectDeploy} onCancel={handleCancel2}>
      <p>The project you want to edit is undergoing another operation for more than 30 minutes.<br></br> Do you want to continue deploying this process anyway ? </p>
    </Modal>
    <Modal className="modal-unlock-project" title="Edit Process" visible={isModalUnlockProjectVisibleEdit} onOk={clickUnlockProjectEdit} onCancel={handleCancel2}>
      <p>The project you want to edit is undergoing another operation for more than 30 minutes.<br></br> Do you want to continue editing this process anyway ? </p>
    </Modal>
    <Modal className="modal-unlock-project" title="Clone In Production" visible={isModalUnlockProjectVisibleClone} onOk={clickUnlockProjectClone} onCancel={handleCancel2}>
      <p>The project you want to edit is undergoing another operation for more than 30 minutes.<br></br> Do you want to continue cloning this process anyway ? </p>
    </Modal>
    </>
  );
};

export default ProcessTable;