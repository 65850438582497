/**
 * Rulex Project Manager
 *
 * Loading Screen
 * 
 * @summary Contains the loading screen of the admin pages.
 * @edited_by Riccardo Poli, Lorenzo Biasotti
 *
 */

import { Spin, Col, Row } from "antd";

/**
 * Header of the Admin pages
 *
 * @return {*} 
 */
 const LoadingScreen = () => {
    return (
      <>
      <div style={{position: 'absolute', left: '50%', top: '50%',transform: 'translate(-50%, -50%)'}}>
        <Col>
            <Row justify="center" align="middle"><img
                alt="..."
                className="img-logo-auth"
                src={
                require("../../assets/img/brand/logo-ego-blu.svg").default
                }
            /></Row>
            <Row justify="center" align="middle"><p className="mt-2 t-big-auth-navbar">Rulex Project Manager</p></Row>
            <Row justify="center" align="middle"><Spin size="large"></Spin></Row>
        </Col>
      </div>
      </>
    );
  };
  
  export default LoadingScreen;