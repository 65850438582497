/**
 * Rulex Project Manager
 *
 * --Login
 * 
 * @summary Contains the component of the Login page
 * @author Creative Tim (www.creative-tim.com)
 * @edited_by Riccardo Poli, Lorenzo Biasotti
 *
 */

import { Button, Card, CardHeader, CardBody, FormGroup, Form, Input, InputGroupAddon, InputGroupText, InputGroup, Col, Alert } from "reactstrap";
import { useState, useContext } from "react";
import { AccountContext } from "../contexts/AccountContext";
import { Spin, Checkbox } from "antd";
import { ExclamationCircleOutlined, LoadingOutlined, MailOutlined, UnlockOutlined } from "@ant-design/icons";
import { sleep } from "scripts/functions";

/**
 * Login page
 *
 * @return {*} 
 */
const Login = () => {

  // State var
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [loginSpinning, setLoginSpinning] = useState(true);
  const [isValid, setIsValid] = useState(true);

  // Context
  const { authenticate } = useContext(AccountContext);

  const onSubmit = (event) => {
    event.preventDefault();

    setLoginSpinning(true);
    setLoading(true);

    // auth the user
    authenticate(email, password)
      .then((data) => {
        setLoginSpinning(true);
        setLoading(false);
        //console.log("Logged in!", data);
      })
      .catch(async (err) => {
        await sleep(500); // wait half second
        setIsValid(false);
        setLoginSpinning(false);
        setLoading(false);
        //console.error("Failed to login", err);
      });
  };
  
  // Setup email field
  const setupEmail = (email:any) => {
    setIsValid(true);
    setLoginSpinning(true);
    setLoading(false);
    setEmail(email);
  }
  
  // Setup password field
  const setupPassword = (password:any) => {
    setIsValid(true);
    setLoginSpinning(true);
    setLoading(false);
    setPassword(password);
  }

  // remember-me
  sessionStorage.setItem("singleSession","true");
  localStorage.removeItem("rememberMe");
  const checkRemember = (value) => {
    if(value){
      localStorage.setItem("rememberMe","true");
      sessionStorage.removeItem("singleSession");
    }
    else{
      localStorage.removeItem("rememberMe");
      sessionStorage.setItem("singleSession","true");
    }
  };

  // Icons
  let warningIcon = <ExclamationCircleOutlined className="warning-icon-spin"/>
  let loginIcon = <LoadingOutlined className="login-icon-spin" spin/>

  // Return
  return (
    <>
      <Col span={24}>
        <Card className="card-login">
          <CardHeader className="card-header-login">
            <div className="text-center">
              <p className="header-login">LOGIN:</p>
            </div>
          </CardHeader>
          <CardBody className="body-login-card">
            <div className="text-center mb-4">
              <p className="subtitle-login">Sign in with credentials:</p>
            </div>
            <Form role="form">
              <FormGroup className="mb-3">
                <InputGroup className="input-group-login">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <MailOutlined className="color-blue-ego-login" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    className="input1-login"
                    placeholder="Email"
                    type="email"
                    autoComplete="new-email"
                    value={email}
                    onChange={(event) => setupEmail(event.target.value)}
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <UnlockOutlined className="color-blue-ego-login"/>
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    className="input1-login"
                    placeholder="Password"
                    type="password"
                    autoComplete="new-password"
                    value={password}
                    onChange={(event) => setupPassword(event.target.value)}
                  />
                </InputGroup>
              </FormGroup>
              {!isValid  && <Alert className="warning-login mt-2" color=""><p className="warning-login-text">Incorrect username or password</p></Alert> }
              {isValid  && <Alert className="warning-login-fake mt-2" color=""><p className="warning-login-text">Incorrect username or password</p></Alert> }
              <div className="div-remember-login">
                <Checkbox
                  onChange={(event) => checkRemember(event.target.checked)}
                />
                <span className="remember-login">Remember me</span>
              </div>
              <div className="text-center">
                <Button className="mt-3 btn-login" color="primary" type="button" onClick={(event) => onSubmit(event)}>
                  Login
                </Button>
                <Spin className="spin-login" indicator={loginSpinning ? loginIcon : warningIcon} spinning={loading || !loginSpinning}/>
              </div>
            </Form>
          </CardBody>
        </Card>
      </Col>
    </>
  );
};

export default Login;

/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/